.networkPageWrap {
    background-color: #e6e7ee;
    padding-bottom: 0;

    .header {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 1;

        .container {
            display: flex;
            align-items: center;
        }

        .navigation {
            ul {
                align-items: center;
            }

            a {
                @include ubuntu(400);
                font-size: 19px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                padding: 5px;
            }

            .signin-btn {
                min-width: 120px;
                min-height: 40px;
                padding: 8px 10px 10px;
                border-radius: 10px;
                border: solid 1px #fff;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-left: 50px;
            }
        }
    }

    .network-banner {
        @include background("");
        background-size: 100% 110%;
        background-position: 100% 80%;
        min-height: 600px;
        position: relative;
        height: 100vh;

        @media (max-width: 992px) {
            background-position: center center;
        }

        .container {
            position: relative;
            height: 100%;
        }

        .banner-content {
            position: absolute;
            bottom: 150px;
            left: 2px;
            padding: 0 15px;
            max-width: 521px;
        }

        h1 {
            @include ubuntu(400);
            font-size: 50px;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 27px;
        }

        h2 {
            @include ubuntu(400);
            font-size: 25px;
            line-height: 1.4;
            letter-spacing: normal;
            color: #fff;
        }

        .scrollBtm {
            position: absolute;
            bottom: 23px;
            left: 0;
            right: 0;
            text-align: center;
            width: 100%;
            background: transparent;
            outline: none;
            border: none;
            z-index: 1;
            cursor: pointer;

            .mousey-wrap {
                height: 80px;
                width: 80px;
                border: 1px solid #ffffff;
                margin: 0 auto;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                svg {
                    max-width: 10px;
                    margin-top: 3px;

                    * {
                        fill: #00e0e5;
                        stroke-width: 2px;
                    }
                }
            }

            .mousey {
                width: 3px;
                padding: 8px 7px;
                height: 12px;
                border: 2px solid #00e0e5;
                border-radius: 25px;
                opacity: 0.75;
                margin: 0 auto;
                box-sizing: content-box;
            }

            .scroller {
                width: 3px;
                height: 6px;
                border-radius: 25%;
                background-color: #00e0e5;
                animation-name: scroll;
                animation-duration: 2.2s;
                animation-timing-function: cubic-bezier(.15, .41, .69, .94);
                animation-iteration-count: infinite;
            }

            @keyframes scroll {
                0% {
                    opacity: 0;
                }

                10% {
                    transform: translateY(0);
                    opacity: 1;
                }

                100% {
                    transform: translateY(15px);
                    opacity: 0;
                }
            }
        }
    }

    .tripello-difference-wrapper {
        text-align: center;
        padding: 74px 0 78px;
        @include background("");

        .content-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (max-width: 767px) {
                display: block;
            }
        }

        .col {
            max-width: 331px;
            width: 100%;

            &:first-child {
                max-width: 286px;
            }

            &:nth-child(2) {
                max-width: 353px;
            }

            @media (max-width: 767px) {
                margin: 0 auto 30px;
                &:first-child, &:nth-child(2), &:nth-child(3) {
                    max-width: 323px;
                }
            }

            i {
                background-image: url("../../../img/icons/transparent-circle.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-size: 100% 100%;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;

                img {
                    display: block;
                    max-width: 28px;
                }

                &:first-child {
                    background-image: url("../../../img/icons/circle-1.png");
                }

                &:nth-child(1) {
                    background-image: url("../../../img/icons/circle-2.png");
                }

                @media (max-width: 992px) {
                    width: 40px;
                    height: 40px;

                    img {
                        display: block;
                        max-width: 22px;
                    }
                }
            }

            .heading {
                font-size: 19px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.58;
                letter-spacing: normal;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;

                @media (max-width: 992px) {
                    font-size: 16px;
                }
                @media (max-width: 767px) {
                    justify-content: flex-start;
                    padding-left: 15%;
                }
            }

            .content {
                font-size: 17px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                @media (max-width: 992px) {
                    font-size: 14px;
                }
            }
        }

        h3 {
            color: #fff;
        }

        .content-wrapper {
            // max-width: 890px;
            margin: 0 auto;
        }

        p {
            @include ubuntu(300);
            font-size: 19px;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 22px;

            strong {
                font-weight: 500;
            }
        }
    }

    .section-title {
        text-align: center;
        padding: 20px 0;

        h2 {
            @include ubuntu(400);
            font-size: 30px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
        }
    }

    .tripello-inventory-wrapper {
        .section-title {
            padding: 70px 0 35px;
        }

        .col-layout {
            margin: 0 -9px;

            .col {
                padding: 0 9px;
            }

            .cardStyle {
                padding: 0px 0px 6px;
                border-radius: 10px;
                box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                height: 100%;
            }

            .cardImg {
                margin: 9px;
                border-radius: 8px;
                box-shadow: none;
                border: none;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-position: center;
                background-size: cover;
                height: 167px;
                img {
                    border-radius: 8px;
                    overflow: hidden;
                    object-fit: cover;
                }
            }

            .cardText {
                padding: 12px 8px 10px;

                h5,
                h5 a {
                    @include ubuntu(500);
                    font-size: 17px;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #435b75;
                    margin-bottom: 10px;
                }

                p {
                    @include ubuntu(300);
                    font-size: 14px;
                    line-height: 1.36;
                    letter-spacing: normal;
                    color: #435b75;
                }
            }
        }
    }

    .tripello-way-wrapper {
        padding-bottom: 50px;

        .section-title {
            padding: 74px 0 35px;

            h2 {
                margin-bottom: 18px;
            }

            h3 {
                margin: 0 0 36px;
                font-size: 17px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
                text-align: center;
                color: #435b75;

                strong {
                    font-weight: 500;
                }
            }
        }

        .cardStyle {
            border: none;
            box-shadow: none;
            border-radius: 0;

            .img-wrap {
                width: 60px;
                height: 60px;
                box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #e1e6ee;
                background-color: #edeef5;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -60px auto 20px;
            }

            h4 {
                font-size: 19px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.58;
                letter-spacing: normal;
                color: #1b71cf;
                margin-bottom: 13px;
            }

            p {
                line-height: 1.3;
                margin: 0;

                @media (max-width:991px) {
                    br {
                        display: none;
                    }
                }
            }

            a {
                color: #1d6ec9;
            }

            button {
                background-color: transparent;
                border: none;
                color: #1d6ec9;
                font-size: inherit;
                font-weight: inherit;
            }

            .card-col {
                max-width: 33.33%;
                flex: 0 0 33.33%;
            }
        }

        .cardBox {
            padding: 27px 20px 27px;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            text-align: center;
            height: 100%;
        }
    }

    .qr-code-section {
        @include background("../../../img/network/qr-code-bg.jpg");
        max-height: 500px;

        .qr-code-section-wrap {
            @include background("../../../img/network/bitmap@3x.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-size: auto 105%;
            background-position: 116% bottom;
            padding: 70px 0 48px;
            min-height: 580px;
        }

        .left-content {
            max-width: 48%;
        }

        .left-content-wrapper {
            h2 {
                @include ubuntu(400);
                font-size: 30px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-bottom: 36px;
            }

            p {
                @include ubuntu(300);
                font-size: 19px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                padding-bottom: 20px;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .personal-mgmt-dashboard {
        padding: 52px 0 0;

        .container {
            max-width: 1295px;
        }

        .content {
            max-width: 794px;
            margin: 0 auto;
            padding-bottom: 27px;

            p {
                @include ubuntu(300);
                font-size: 17px;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: #435b75;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .affiliate-compensation-section {
        padding-bottom: 10px;

        .box-layout-wrap {

            .tripello-magic-link {
                background-color: transparent;
                font-weight: normal;
                font-size: inherit;
                border: none;
                color: #1d6ec9;

                &:hover {
                    text-decoration: underline;
                }
            }

            p {
                @include ubuntu(300);
                font-size: 17px;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: #435b75;
                margin-bottom: 23px;

                strong {
                    font-weight: 500;
                }

                a {
                    font-weight: normal;
                    color: #1d6ec9;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }


        .section-title {
            padding: 20px 0 35px;

            h2 {
                margin-bottom: 24px;
            }

            h3 {
                margin: 0 0 36px;
                font-size: 17px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
                text-align: center;
                color: #435b75;

                strong {
                    font-weight: 500;
                }
            }
        }

        .box-layout-wrap {
            border: none;
            box-shadow: none;
            border-radius: 0;
            padding: 0;
            background: none;

            .img-wrap {
                width: 60px;
                height: 60px;
                box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #e1e6ee;
                background-color: #edeef5;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -60px auto 10px;
            }

            h4 {
                font-size: 19px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.58;
                letter-spacing: normal;
                color: #1b71cf;
                margin-bottom: 13px;
            }

            p {
                line-height: 1.3;
                margin: 0;

                @media (max-width:1200px) {
                    br {
                        display: none;
                    }
                }
            }

            a {
                color: #1d6ec9;
            }

            button {
                background-color: transparent;
                border: none;
                color: #1d6ec9;
                font-size: inherit;
                font-weight: inherit;
            }

            .card-col {
                max-width: 33.33%;
                flex: 0 0 33.33%;
                padding-bottom: 34px;
            }
        }

        .cardBox {
            padding: 28px 15px 30px;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            text-align: center;
            height: 100%;
        }
    }

    .full-video-wrapper {
        padding-bottom: 25px;

        .section-title {
            padding: 57px 0 42px;
        }

        .video-section-content-wrap {
            max-width: 70%;
            margin: 0 auto;
            padding-top: 24px;
            padding-bottom: 25px;
        }

        .video-play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer;
        }

        .video-box {
            border-radius: 10px;
            overflow: hidden;
            min-height: 520px;
            padding-bottom: 56.25%;
            position: relative;
            @include background('');
            background-image: url('../../../img/network/video-section-bg.png');

            .mobile-video {
                display: none;
            }

            video {
                width: 100%;
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                object-fit: cover;
            }
        }

        .video-box-main-wrap {
            max-width: 852px;
            margin: 0 auto;
            padding: 10px;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #e1e6ee;
            background-color: #edeef5;
        }

        h6 {
            @include ubuntu(300);
            font-size: 19px;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            color: #435b75;
            margin-bottom: 0;

            strong {
                font-weight: 500;
            }

            a {
                font-weight: normal;
                color: #435b75;
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: 400;
            }
        }

        .tripello-magic-link {
            background-color: transparent;
            border: none;
            color: #1d6ec9;
            text-decoration: none;
            font-weight: 500;
            font-size: inherit
        }
    }

    .plan-wrapper {
        @include background("../../../img/network/plan-bg.jpg");
        padding: 22px 0 50px;

        .section-title {
            padding: 51px 0 44px;

            h2 {
                color: #fff;
            }
        }

        .grey-section {
            padding: 8px 75px 9px;
            border-radius: 6px;
            background-color: rgba(11, 43, 79, 0.1);
            margin: 4px 0;

            h6 {
                @include ubuntu(400);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #1d6ec9;

                strong {
                    font-weight: 500;
                }
            }
        }

        .infobox {
            height: 100%;
            min-height: 600px;
            max-width: 530px;
            margin: 0 auto;
            position: relative;
            padding: 10px 10px 88px;
            border-radius: 10px;
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #d2dae6;
            background-color: #edeef5;
        }

        .row {
            margin: 0 -25px;

            .col {
                padding: 0 25px;
            }
        }

        .plan-image-box {
            text-align: center;
            // padding: 11px 10px 9px;
            border-radius: 6px;
            // box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #fff;
            // border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);

            .plan-image {
                @include background("../../../img/network/plan1-bg.png");
                min-height: 145px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                border-radius: 6px;

                .status-icon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }

            h6 {
                @include ubuntu(400);
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
            }

            h5 {
                @include ubuntu(400);
                font-size: 19px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-top: 13px;
                margin-bottom: 4px;
            }
        }

        .plan-item2 {
            .plan-image-box {
                .plan-image {
                    @include background("../../../img/network/plan2-bg.png");
                }
            }
        }

        .plan-info {
            padding: 15px 0;
            max-width: 417px;
        }

        ul {
            list-style: none;
            padding-left: 35px;

            li {
                @include ubuntu(400);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                position: relative;
                margin-bottom: 9px;

                &:before {
                    content: "";
                    // @include background("../../../img/membership-upgrade/right-tick.svg");
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    display: block;
                    position: absolute;
                    top: 7px;
                    left: -16px;
                    background-color: #435b75;
                }
            }
        }

        .plan-footer-wrap {
            padding: 14px 15px 21px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            .price-info {
                padding-left: 24px;
            }

            .plan-footer {
                display: flex;
                justify-content: space-between;
                position: relative;

                &:after {
                    content: "";
                    border-top: 1px solid rgba(67, 91, 117, 0.2);
                    display: block;
                    width: 100%;
                    position: absolute;
                    top: -15px;
                }
            }

            h5 {
                @include ubuntu(400);
                font-size: 30px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;

                span {
                    font-size: 14px;
                }
            }

            h6 {
                @include ubuntu(400);
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            .info {
                padding-top: 23px;
                max-width: 60%;

                p {
                    opacity: 0.6;
                    @include ubuntu(400);
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #435b75;
                }
            }

            .button-info {
                padding-top: 4px;
            }

            .btn {
                min-width: 190px;
                min-height: 45px;
                background-color: transparent;
                background-image: url("../../../img/signup/blue-btn-bg.png");
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                padding-right: 33px;
                @include ubuntu(400);
                box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2), -3px -3px 6px 2px #fff;
                -webkit-appearance: inherit;
                background-size: 100% 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    .faq-wrapper {
        margin-bottom: 80px;

        .container {
            max-width: 720px;
        }

        .section-title {
            padding: 67px 0 43px;
        }

        .accordion {
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2dae6;
            // background-color: rgba(255, 255, 255, 0.8);

            .card {
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                padding: 0 25px 0 20px;

                &:last-child {
                    border: none;
                }
            }

            button {
                @include ubuntu(500);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                border: none;
                outline: none;
                width: 100%;
                background-color: transparent;
                cursor: pointer;
                text-align: left;
                padding: 18px 0;
                display: flex;

                img {
                    margin-right: 10px;
                    max-width: 22px;
                }
            }

            p {
                @include ubuntu(400);
                font-size: 14px;
                line-height: 1.29;
                letter-spacing: normal;
                color: #435b75;
                padding-left: 32px;

                a {
                    color: #435b75;
                }
            }





            .card-content {
                padding: 0 0 0px;
                overflow: hidden;
                max-height: 0;
                transition: all 0.5s ease-in-out;

                // display: none;
                &.show {
                    display: block;
                }

                .tripello-magic-link {
                    display: inline-block;
                    padding: 0;
                    font-size: inherit;
                    width: auto;
                }
            }

            .open-accordion {
                max-height: 170px;
                padding-bottom: 12px;

            }

        }

        .moreAccordBody {
            .toggle-more-accordions {
                .card {
                    padding-left: 0;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .moboile-slider {
        display: none;
    }
}

.indexPageWrap,
.networkPageWrap,
.signupWrapper {
    .network-logo-sticky {
        display: none;

        span {
            color: #1d6ec9;
            margin-left: 5px;
            border: 1px solid #1d6ec9;
            border-radius: 10px;
            padding: 3px 8px;
            font-size: 11px;
        }
    }


}

.header-sticky {

    .networkPageWrap,
    .signupWrapper {
        .fixedHeader {
            .header {
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
                background-color: #eff0f5;
                padding: 9.71px 15px;
                min-height: 55px;

                .navigation {
                    a {
                        color: #1d6ec9;
                    }
                }

                .btnBorder3,
                .signin-btn {
                    color: #1d6ec9;
                    border-color: #1d6ec9;
                }

                .logo1 {
                    display: none;
                }

                .logo-sticky {
                    display: block;
                }

                .network-logo-sticky {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

// Responsive css start
@media (min-width: 1600px) {
    .networkPageWrap .qr-code-section .qr-code-section-wrap {
        background-size: auto 100%;
        background-position: 90% bottom;
    }
}

@media (min-width: 1500px) {
    .networkPageWrap .qr-code-section .qr-code-section-wrap {
        background-position: 100% bottom;
        background-size: auto 99%;
    }
}

@media (max-width: 1400px) {
    .networkPageWrap {
        .qr-code-section .qr-code-section-wrap {
            background-size: auto 90%;
            background-position: 115% bottom;
        }

        .tripello-inventory-wrapper .col-layout {
            margin: 0;
        }

        .plan-wrapper .row {
            margin: 0;
        }
    }
}

@media (max-width: 1199px) {
    .header-sticky .networkPageWrap .fixedHeader .header {
        padding-left: 0;
        padding-right: 0;
    }

    .networkPageWrap {
        overflow-x: hidden;

        .container {
            padding: 0 15px;
        }

        .qr-code-section {
            .left-content {
                max-width: 460px;
            }
        }

        .network-banner {
            background-size: 100% 100%;
            height: 630px;
        }

        .tripello-way-wrapper {
            .cardStyle {
                h4 {
                    font-size: 16px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
        .affiliate-compensation-section .box-layout-wrap h4 {
            max-width: 250px;
            margin:  0 auto 13px;
        }
        .plan-wrapper .grey-section {
            padding: 8px 40px 9px;
        }
    }
}

@media (max-width: 1120px) {
    .networkPageWrap .qr-code-section .qr-code-section-wrap {
        background-size: auto 80%;
    }
}

@media (max-width: 1024px) {
    .networkPageWrap {
        .qr-code-section {
            .qr-code-section-wrap {
                min-height: 560px;
                background-size: auto 75%;
            }
        }

        .network-banner {
            background-image: url("../../../img/network/network-banner-tablet.png") !important;
            background-size: cover;
            height: 90vh;

            h1 {
                font-size: 45px;
                margin-bottom: 20px;
            }

            h2 {
                font-size: 22px;
            }

            .banner-content {
                margin: 0 auto;
                left: 0;
                right: 0;
                bottom: 80px;
                text-align: center;
            }
        }
    }
}

// 1024 end
@media (max-width: 991px) {
    .networkPageWrap {
        header {
            padding: 9.71px 0;
        }

        .sctionTitle {
            font-size: 28px;
        }

        .section-title {
            h2 {
                font-size: 28px;
            }
        }

        .network-banner {
            height: 77vh;

            .container {
                position: static;
            }

            .banner-content {
                bottom: 120px;
                text-align: center;
                left: 0;
                right: 0;
                max-width: 100%;
            }

            .scrollBtm {

                svg,
                img {
                    max-width: 28px;
                }
            }

            h1 {
                font-size: 45px;
                margin-bottom: 12px;
            }

            h2 {
                font-size: 21px;
            }
        }

        .tripello-difference-wrapper {
            p {
                font-size: 17px;
            }
        }

        .plan-wrapper {
            .grey-section {
                padding: 8px 15px 9px;

                h6 {
                    font-size: 15px;
                }
            }

            ul {
                li {
                    font-size: 15px;
                }
            }

            .plan-footer-wrap {
                padding: 14px 12px 21px;

                .btn {
                    min-width: 160px;
                    min-height: 40px;
                    padding-right: 20px;
                }

                h5 {
                    font-size: 24px;
                }

                .price-info {
                    padding-left: 0px;
                }
            }

            .row {
                margin: 0px;

                .col {
                    padding: 0 15px;
                }
            }
        }

        .qr-code-section {
            max-height: 370px;

            .qr-code-section-wrap {
                min-height: 405px;
                padding: 40px 0 18px;
                background-position: 105% bottom;
            }

            .left-content {
                max-width: 360px;
            }

            .left-content-wrapper {
                h2 {
                    font-size: 23px;
                }

                p {
                    font-size: 15px;
                }
            }

            .right-content {
                .image {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        .full-video-wrapper {
            .video-box {
                min-height: 350px;
            }

            h6 {
                font-size: 16px;
            }
        }
    }
}

// 991 end
@media (min-width:768px) and (max-width:991px) {
    .networkPageWrap {
        .plan-wrapper {
            .plan-footer-wrap {
                h5 {
                    font-size: 20px;
                }

                h6 {
                    font-size: 12px;
                }
            }
        }
         .tripello-way-wrapper .cardStyle h4 {
            min-height: 60px;
        }
    }
}

@media (max-width: 768px) {
    .networkPageWrap {
        .network-banner {
            height: 90vh;

            .banner-content {
                bottom: 135px;
                text-align: center;
                left: 0;
                right: 0;
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .header-sticky .networkPageWrap .fixedHeader .header .network-logo-sticky {
        img {
            max-width: 100px;
        }

        span {
            font-size: 9px;
            padding: 2px 5px;
        }
    }

    .networkPageWrap {
        .header {
            .logo {
                max-width: 200px;
                flex: 0 0 200px;

                img {
                    max-width: 160px;
                    height: auto;
                }
            }

            .navigation {
                a {
                    font-size: 14px;
                }

                .getstarted-btn {
                    display: none;
                }


                .signin-btn {
                    min-width: 80px;
                    min-height: 32px;
                    padding: 3px 10px 4px;
                    margin-left: 15px;
                }
            }
        }

        .section-title {
            h2 {
                font-size: 23px;
            }
        }

        .sctionTitle {
            font-size: 23px;
            padding-bottom: 19px;
        }

        .network-banner {
            background-image: url("../../../img/network/network-mobile-banner.png") !important;
            min-height: 580px;
            height: 100%;
            background-size: cover;

            .container {
                position: static;
            }

            .banner-content {
                bottom: 92px;
                text-align: center;
                left: 0;
                right: 0;
                max-width: 100%;
            }

            .scrollBtm {
                bottom: 12px;

                .mousey-wrap {
                    height: 60px;
                    width: 60px;
                }

                .mousey {
                    width: 2px;
                    padding: 6px;
                    height: 9px;
                }

                svg,
                img {
                    max-width: 28px;
                }
            }

            h1 {
                font-size: 30px;
                margin-bottom: 12px;
            }

            h2 {
                font-size: 17px;
            }
        }

        .tripello-difference-wrapper {
            background-image: linear-gradient(138deg, #0072d1, #00dcdf 100%) !important;
            padding: 45px 0 23px;

            p {
                line-height: 21px;
            }

            .container {
                max-width: 630px;
            }
            .col {
                &:nth-child(2), &:nth-child(3) {
                    @media (max-width: 767px) {
                        .content strong {
                        display: block;
                        }
                        
                    }
                }
            }
            .heading {
                justify-content: flex-start;
                
            }
            p strong {
                display: block;
            }
            
        }

        .tripello-inventory-wrapper {
            .container {
                max-width: 375px;
            }

            .section-title {
                padding: 42px 0px 26px;
            }

            .col-layout {
                flex-direction: column;
                margin: 0;

                .cardText {
                    padding: 8px 9px 4px;

                    h5,
                    h5 a {
                        margin-bottom: 8px;
                    }
                }

                .grid-item {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                    margin-bottom: 15px;
                }

                .cardStyle {
                    display: flex;
                    padding: 0px;

                    .cardImg {
                        max-width: 140px;
                        height: 140px;
                        flex: 0 0 140px;
                        // align-self: center;

                        img {
                            border-radius: 8px;
                            height: 120px;
                            width: 120px;
                        }
                    }
                }
            }
        }

        .tripello-way-wrapper {
            padding-bottom: 11px;

            .section-title {
                padding: 30px 10px 27px;

                h2 {
                    margin-bottom: 12px;
                }

                h3 {
                    font-size: 14px;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: center;
                    color: #435b75;
                    margin-bottom: 10px;

                    strong {
                        font-weight: 500;
                    }
                }
            }

            .container {
                max-width: 630px;
            }

            .cardStyle {
                padding: 22px 0 0px;

                .cardBox {
                    padding: 27px 20px 15px;
                }

                p {
                    line-height: 19px;
                    font-size: 14px;

                    br {
                        display: none;
                    }
                }

                h4 {
                    font-size: 17px;
                    line-height: 1.76;
                    margin-bottom: 4px;
                }

                .img-wrap {
                    height: 50px;
                    width: 50px;
                    margin: -50px auto 5px;
                }

                .card-col {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-bottom: 40px;
                }

            }
        }

        .qr-code-section {
            max-height: 640px;
            @include background("../../../img/network/qr-code-bg-mobile.png");

            .qr-code-section-wrap {
                min-height: 705px;
                @include background("../../../img/network/qr-code-mobile-computer.png");
                background-position: center bottom;
                background-size: auto 43%;
            }

            .left-content {
                margin: 0 auto;
            }

            .left-content-wrapper {
                text-align: center;

                h2 {
                    max-width: 205px;
                    margin: 0 auto 22px;
                }

                p {
                    font-size: 17px;
                    line-height: 21px;
                }
            }
        }

        .plan-wrapper {
            background-image: linear-gradient(164deg, #0994ff, #00dfe9 100%);
            padding-bottom: 17px;

            .section-title {
                padding: 18px 0px 26px;
            }

            .container {
                max-width: 630px;
            }

            .row {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0 0 15px;
                }
            }

            .grey-section {
                padding: 7px 25px 6px;
                margin: 4px 10px;

                h6 {
                    font-size: 14px;
                }
            }

            ul {
                padding-left: 30px;

                li {
                    font-size: 14px;
                    margin-bottom: 6px;
                }
            }

            .infobox {
                padding: 0px 0px 19px;
                border-radius: 10px;
                box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                min-height: 100%;

                .plan-info {
                    padding-bottom: 11px;
                }

                .plan-image-box {
                    padding: 9px;

                    .plan-image {
                        border-radius: 4px;
                        min-height: 130px;
                    }
                }
            }

            .plan-footer-wrap {
                position: relative;
                text-align: center;
                padding: 14px 12px 0;

                h5 {
                    font-size: 25px;
                    margin-bottom: 4px;
                }

                .plan-footer {
                    flex-direction: column;

                    &:after {
                        top: -9px;
                    }
                }

                .price-info {
                    padding: 0 0 9px;
                }

                .btn {
                    padding: 10px;
                    min-width: 170px;
                    padding-right: 40px;
                    min-height: 40px;
                    font-size: 14px;
                }
            }
        }

        .personal-mgmt-dashboard {
            padding: 35px 0 32px;

            .container {
                padding: 0;
            }

            .section-title {
                max-width: 270px;
                padding-bottom: 0;
                margin: 0px auto 12px;
            }

            .content {
                padding: 0 15px 22px;

                p {
                    font-size: 14px;
                    line-height: 19px;
                }
            }

            .img-section {
                display: none;
            }

            .slick-slider {
                height: auto;
                max-height: 100%;
            }

            .moboile-slider {
                display: block;

                .slick-track {
                    overflow: hidden;
                    clear: both;

                    .slick-slide {

                        &:first-child {
                            .slider-images {
                                margin-right: 0;
                                margin-left: auto;
                            }
                        }
                    }
                }

                .slick-slide {
                    padding: 0 2px;

                    .slider-images {
                        max-width: 92%;
                        -webkit-transition: max-width 2s ease-out;
                        -moz-transition: max-width 2s ease-out;
                        -o-transition: max-width 2s ease-out;
                        transition: max-width 2s ease-out;
                        margin-top: 22px;
                        float: right;

                        img {
                            width: 100%;
                            -webkit-transition: width 2s ease-out;
                            -moz-transition: width 2s ease-out;
                            -o-transition: width 2s ease-out;
                            transition: width 2s ease-out;
                            border-radius: 12px;
                        }
                    }

                    &.slick-active {
                        padding: 0 2px;

                        +.slick-slide {
                            .slider-images {
                                float: left;
                            }
                        }

                        .slider-images {
                            max-width: 100%;
                            margin-top: 0;
                        }
                    }
                }

                .slider-content {
                    text-align: center;

                    h6 {
                        @include ubuntu(400);
                        font-size: 17px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        text-align: center;
                        color: rgba(29, 10, 201, 0.7);
                        padding: 3px 23px 5px;
                        border-radius: 15px;
                        background-color: rgba(29, 10, 201, 0.1);
                        display: inline-block;
                        margin-bottom: 5px;
                    }

                    .line {
                        width: 1px;
                        height: 19px;
                        background-color: rgba(29, 10, 201, 0.5);
                        margin: 0 auto;
                        display: block;
                    }
                }

                .slick-dots {
                    list-style: none;
                    display: flex !important;
                    justify-content: center;
                    // margin: 0 -7px;
                    padding: 30px 0 0;
                    position: relative;
                    bottom: 0;

                    li {
                        padding: 0 7px;

                        button {
                            width: 12px;
                            height: 12px;
                            box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                            background-color: #aebdcd;
                            border: none;
                            outline: none;
                            background-color: transparent;
                            border-radius: 100%;
                            text-indent: -99em;
                            overflow: hidden;

                            &::before {
                                display: none;
                            }
                        }

                        &.slick-active {
                            button {
                                box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                                background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 114%);
                            }
                        }
                    }
                }
            }
        }

        .full-video-wrapper {
            .section-title {
                padding: 45px 0 25px;
            }

            .container {
                max-width: 560px;
            }

            .video-box-wrap {
                height: 100%;
                border-radius: 10px;
            }

            .video-box-main-wrap {
                padding: 0;
                box-shadow: none;
                border: none;
                border-radius: 0;
            }

            .video-box {
                padding: 5px;
                border-radius: 10px;
                box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                height: 100%;
                min-height: 180px;

                video {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    width: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }

                .desktop-video {
                    display: none;
                }

                .mobile-video {
                    display: block;
                    padding: 0px;
                    border-radius: 8px;
                    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #fff;
                    border: solid 1px #d2dae6;
                    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                    min-height: 200px;
                    @include background("");
                }

                .mobile-video-wrap {
                    position: relative;
                    overflow: hidden;
                    min-height: 180px;
                    border-radius: 8px;
                    @include background('');
                    background-image: url('../../../img/network/video-section-bg.png');
                    padding-bottom: 56.25%;

                    button {
                        img {
                            max-width: 70px;
                        }
                    }
                }
            }

            h6 {
                font-size: 14px;
                line-height: 21px;
            }

            .video-section-content-wrap {
                max-width: 100%;
            }
        }

        .affiliate-compensation-section {
            .box-layout-wrap {
                padding: 0px;

                p {
                    line-height: 21px;
                }
            }

            .section-title {
                padding: 13px 10px 27px;

                h2 {
                    margin-bottom: 12px;
                }

                h3 {
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: center;
                    color: #435b75;
                    margin-bottom: 10px;

                    strong {
                        font-weight: 300;
                    }
                }
            }

            .container {
                max-width: 630px;
            }

            .box-layout-wrap {

                .cardBox {
                    padding: 27px 20px 15px;
                }

                p {
                    line-height: 19px;
                    font-size: 14px;

                    br {
                        display: none;
                    }
                }

                h4 {
                    font-size: 17px;
                    line-height: 1.76;
                    max-width: 100%;
                    margin-bottom: 4px;
                }

                .img-wrap {
                    height: 50px;
                    width: 50px;
                    margin: -50px auto 5px;
                }

                .card-col {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-bottom: 40px;

                    &:last-child {
                        padding-bottom: 23px;
                    }
                }

                .box-layout-footer {
                    p {
                        strong {
                            font-weight: 300;
                        }
                    }
                }

            }
        }

        .faq-wrapper {
            margin-bottom: 50px;

            .section-title {
                padding: 40px 0 26px;
                max-width: 262px;
                margin: 0 auto;
            }

            .container {
                max-width: 630px;
            }

            .accordion {
                border-radius: 10px;
                box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);

                .card {
                    padding: 0px 25px 0px 10px;

                    &.moreAccordBody {
                        .card {
                            padding-right: 0;
                        }
                    }

                    p {
                        line-height: normal;
                        padding-left: 0px;
                    }
                }

                button {
                    padding: 10px 0;
                    position: relative;
                    font-size: 14px;
                    min-height: 50px;
                    display: inline-flex;
                    align-items: center;

                    &::after {
                        content: "";
                        display: block;
                        @include background("../../../img/network/dropdown.svg");
                        background-size: contain;
                        height: 14px;
                        width: 14px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -17px;
                    }

                    &.active-btn {
                        font-size: 14px;

                        &:after {
                            @include background("../../../img/network/dropup.svg");
                            background-size: contain;
                        }
                    }

                    img {
                        display: none;
                    }
                }
            }
        }
    }
}

// 767 end