.Terms-wrapper {
    .container {
        .rightContent-bottom {
            padding-bottom: 30px;
        }
    }

    .leftContent {
        max-width: 24.2%;
        flex: 0 0 24.2%;

        .tabsWrapper {
            ul {
                li {
                    max-width: 240px;
                    padding-bottom: 4px;
                }

                li.active a {
                    li.active a {
                        color: #1d6ec9;
                        background-color: rgba(255, 255, 255, 0.6);
                        border-radius: 22.5px;
                    }
                }

                a {
                    font-size: 16px;
                    font-weight: normal;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    padding: 12px 15px;

                    img {
                        width: 32px;
                        height: 25px;
                    }
                }
            }
        }
    }

    .faqWrapper {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 5px;
    }
}


@media (max-width: 991px) {
    .Terms-wrapper {
        .leftContent {
            .tabsWrapper {

                ul {
                    li {
                        max-width: 230px;
                        flex: 0 0 230px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .Terms-wrapper {
        .leftContent {
            .tabsWrapper {
                ul {

                    li {
                        max-width: 190px;
                        flex: 0 0 190px;

                        a {
                            font-size: 14px;
                            padding: 10px 10px;

                            img {
                                width: 28px;
                                height: 21px;
                            }
                        }
                    }
                }
            }
        }

        .faqWrapper {
            padding: 15px;

        }
    }
}