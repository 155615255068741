@-webkit-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-o-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-moz-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-o-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes textColour {
  0% {
    color: #fff;
  }

  100% {
    color: #3BB2D0;
  }
}

@-moz-keyframes textColour {
  0% {
    color: #fff;
  }

  100% {
    color: #3BB2D0;
  }
}

@-o-keyframes textColour {
  0% {
    color: #fff;
  }

  100% {
    color: #3BB2D0;
  }
}

@keyframes textColour {
  0% {
    color: #fff;
  }

  100% {
    color: #3BB2D0;
  }
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}
.laoder-wrapper{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background-color: #ffffff;
  opacity: 1;
  z-index: 999999;
}
.e-loadholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 240px;
  height: 240px;
  border: 5px solid #00959d;
  border-radius: 120px;
  box-sizing: border-box;
}

.e-loadholder:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #ffffff;
  transform-origin: center;
  z-index: 0;
}

.e-loadholder:after {
  width: 100px;
  height: 200%;
  -webkit-animation: outerRotate2 30s infinite linear;
  -moz-animation: outerRotate2 30s infinite linear;
  -o-animation: outerRotate2 30s infinite linear;
  animation: outerRotate2 30s infinite linear;
}

.e-loadholder .m-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 200px;
  height: 200px;
  color: #888;
  text-align: center;
  border: 5px solid #2a93ae;
  border-radius: 100px;
  box-sizing: border-box;
  z-index: 20;
  text-transform: uppercase;
}

.e-loadholder .m-loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #ffffff;
  transform-origin: center;
  z-index: -1;
}

.e-loadholder .m-loader:after {
  width: 100px;
  height: 106%;
  -webkit-animation: outerRotate1 20s infinite linear;
  -moz-animation: outerRotate1 20s infinite linear;
  -o-animation: outerRotate1 20s infinite linear;
  animation: outerRotate1 20s infinite linear;
}

.e-loadholder .m-loader .e-text {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  -webkit-animation: textColour 2s alternate linear infinite;
  -moz-animation: textColour 2s alternate linear infinite;
  -o-animation: textColour 2s alternate linear infinite;
  animation: textColour 2s alternate linear infinite;
  display: block;
  width: 140px;
  height: 140px;
  text-align: center;
  border: 5px solid #3bb2d0;
  border-radius: 70px;
  box-sizing: border-box;
  z-index: 20;
}
.e-loadholder .m-loader .e-text:before, .e-loadholder .m-loader .e-text:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #ffffff;
  transform-origin: center;
  z-index: -1;
}

.e-loadholder .m-loader .e-text:before {
  width: 110%;
  height: 40px;
  -webkit-animation: outerRotate2 4s infinite linear;
  -moz-animation: outerRotate2 4s infinite linear;
  -o-animation: outerRotate2 4s infinite linear;
  animation: outerRotate2 4s infinite linear;
}
.e-loadholder .m-loader .e-text:after {
  width: 40px;
  height: 110%;
  -webkit-animation: outerRotate1 8s infinite linear;
  -moz-animation: outerRotate1 8s infinite linear;
  -o-animation: outerRotate1 8s infinite linear;
  animation: outerRotate1 8s infinite linear;
}

#particleCanvas-White {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 100%;
  height: 50%;
  opacity: 0.1;
}

#particleCanvas-Blue {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 0px;
  height: 0px;
  z-index: 1;
  background-color: #ffffff;
}


@media (max-width:767px) {
  .e-loadholder {
    width: 170px;
    height: 170px;
  }

  .e-loadholder .m-loader {
    width: 145px;
    height: 145px;
  }

  .e-loadholder .m-loader:after {
    width: 80px;
    height: 130%;
  }

  .e-loadholder .m-loader .e-text {
    font-size: 12px;
    line-height: 110px;
    width: 110px;
    height: 110px;
  }

  .e-loadholder .m-loader .e-text :after {
    height: 120%;
  }

  #particleCanvas-Blue {
    width: 0px;
    height: 0px;
  }
}