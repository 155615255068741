// .ReactModal__Overlay.ReactModal__Overlay--after-open {
//     z-index: inherit;
//     background-color: transparent !important;
//     position: static !important;

// }

.pay-deduction {
    margin-top: 10px;
}

.popup {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.popup-open {
        display: flex;
    }

    .close {
        background: transparent;
        box-shadow: none;
        border: none;
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 9;
        min-width: auto;
        padding: 5px;

        &::before {
            display: none;
        }
    }

    .popup-content {
        position: relative;
        // max-width: 480px;
        max-width: auto !important;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 30px 25px 0;
        overflow-y: auto;

        .formControl {
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 10px;
            border: 1px solid #dae2ee;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
            width: 100%;
            padding: 9px 15px 10px;
            height: 40px;
            display: block;
            overflow: visible;
            box-sizing: border-box;
            color: #435b75;
            @include ubuntu(400);
            font-size: 17px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @include placeholder-text {
                font-weight: 400;
                color: rgba(67, 91, 117, 0.6);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

// default popup end
.magic-section-popup {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.popup-open,
    &.ReactModal__Content--after-open {
        display: flex;
    }

    .popup-title {
        .close {
            background: transparent;
            box-shadow: none;
            border: none;
            position: absolute;
            top: 3px;
            right: 5px;
            z-index: 9;
            min-width: auto;
            padding: 5px;

            &::before {
                display: none;
            }
        }
    }

    .popup-content {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 30px 25px;
        overflow-y: auto;

        .colCard {
            margin: 0 -10px;
            display: flex;
            flex-wrap: nowrap;

            .cardStyle {
                margin-bottom: 30px;
                display: block;
                padding-bottom: 0;
                margin: 0 10px;
                position: relative;

                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                }

                .cardImg {

                    margin: 0;
                    padding: 0;

                    .cardImg-wrap {
                        // padding: 9px;
                        // padding-bottom: 60%;
                        position: relative;
                    }

                    img {
                        position: static;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        height: auto;
                        width: auto;
                    }
                }
            }

            .cardText {
                padding-bottom: 10px;

                a {
                    color: #435b75;
                }

                button {
                    border: none;
                    background: transparent;
                    display: block;
                    font-size: 21px;
                    color: #435b75;
                    text-align: left;

                }

                h5 {
                    font-size: 21px;

                    button {
                        font-size: inherit;
                        color: inherit;
                        font-weight: inherit;
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        display: block;
                        text-align: left;
                    }
                }
            }
        }
    }

    video {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.magic-home-section-popup {
    .popup-content {
        max-width: 700PX;
    }
}

// magic-section-popup end
.ssn-collection-popup {
    .popup-content {
        max-width: 650px;
        padding-bottom: 20px;
    }

    .icon-close:before {
        color: #1d6ec9;
    }

    .react-tabs__tab-list {
        margin-bottom: 16px;
    }

    .popup-title {
        margin-bottom: 35px;

        .close {
            font-size: 16px;
        }

        h2 {
            font-size: 15px;
            font-weight: 400;
        }
    }

    .submit-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 12px;
            font-weight: normal;
            color: rgba(67, 91, 117, 0.6);
        }

        .btn {
            min-width: 120px;

            &::before {
                display: none;
            }
        }
    }

    .radio-button-wrap {
        display: flex;
        margin: 0 -7px 10px;
    }

    .radio-btn {
        position: relative;
        margin: 0 7px;
        min-height: 30px;

        label {
            color: #1d6ec9;
            font-size: 14px;
            padding: 6px 25px;
            border-radius: 8px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            cursor: pointer;
        }

        input[type="radio"] {
            position: absolute;
            top: -5px;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
        }

        input[type="radio"]:checked+label {
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        }
    }

    .formGroup {
        margin-bottom: 25px;
    }

    @media (max-width: 767px) {
        #selectFlightOptions ul.react-tabs__tab-list li {
            flex-grow: 0;
            justify-content: center;
            display: block;
        }

        .react-tabs__tab-panel .BSrow .col {
            padding: 0 !important;
        }
    }

    @media (max-width: 440px) {
        #selectFlightOptions ul.react-tabs__tab-list li {
            margin-right: 10px;
        }

        #selectFlightOptions button.react-tabs__tab {
            width: 100%;
            margin-right: 0px;
        }
    }

    @media (max-width: 374px) {
        .submit-btn {
            flex-direction: column;

            p {
                margin-bottom: 12px;
                padding: 0;
            }
        }
    }
}

// ssn-collection-popup end
.magic-video-section-popup {
    .close {
        top: -22px;
        right: -22px;
        padding: 0;
        // img {
        //     filter: brightness(0);
        // }
    }

    .popup-content {
        padding: 0;
        max-width: 992px;
        overflow: inherit;
        border-radius: 10px;
        box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
        border: solid 1px #d2dae6;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
        padding: 10px;

        .content-video-box {
            padding-bottom: 56.25%;
            border-radius: 8px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            // border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            overflow: hidden;
            position: relative;
        }

        iframe,
        video,
        .video-js {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100% !important;
            width: 101% !important;
            // object-fit: cover;
            max-width: 101%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        .vjs-poster {
            background-size: cover;
        }

        .vjs-fullscreen-control,
        .vjs-picture-in-picture-control {
            display: none;
        }
    }

    .popup-magic-video {
        @include background('');
        background-image: url('../img/network/video-section-bg.png');
    }

    .video-background-controls {
        top: auto !important;
        right: auto !important;
        left: 10px;
        bottom: 10px;

        button {
            background-color: transparent;
            border: none;
        }
    }
}

// magic video section end
.save-traveller-popup {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;

    .popup-content {
        max-width: 700px;

    }

    .popup-footer {
        padding: 20px 0;
        border-top: 1px solid #dae2ee;
    }

    .information-content-wrap {
        max-height: 325px;
        overflow: hidden;
        overflow-y: auto;

        @media(max-width:374px) {
            padding: 0 10px;
        }
    }

    .delete-btn {
        background: none;
        min-width: auto;
        color: #eb2026;
        box-shadow: none;
        padding: 0;
        display: inline-flex;
        align-items: center;
        font-size: 14px;

        img {
            margin-right: 5px;
            max-width: 17px;
        }


        &:before {
            display: none;
        }
    }

    .popup-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }

    .toggle-links-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #dae2ee;
    }

    .toggle-links {

        ul {
            list-style: none;
            display: flex;
            margin: 0 -10px;

            li {
                padding: 0 10px;

                a {
                    color: #435b75;
                    position: relative;
                }

                .active {
                    color: #1d6ec9;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: -10px;
                        background-color: #1d6ec9;
                    }
                }
            }
        }
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;

        .btn {
            margin: 0 5px;
            font-size: 14px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 48px;
            background-position: left center;
            min-width: 130px;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url("../img/profile/blue-btn-bg.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;

            &:before {
                display: none;
            }
        }
    }

    .information-content-wrap-inner {
        padding-top: 20px;

        .info-card {
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .info-card {
        padding: 15px 0;
        // border-radius: 10px;
        // background-color: #c2e4f3;
        padding: 10px;
        padding-left: 0;
        margin-bottom: 15px;
        min-height: 325px;
        border-bottom: 1px solid #dae2ee;

        &:last-child {
            border-bottom: none;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        h5 {
            color: rgba(67, 91, 117, 0.6);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 6px;
        }

        .info-heading {
            padding-bottom: 25px;
        }

        .formGroup {
            margin-bottom: 13px;
        }

        select {
            min-width: 100%;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: rgba(255, 255, 255, 0.35);
            min-height: 40px;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);
            padding-right: 28px;
        }

        .selectWrap {
            position: relative;
            margin-right: 0;


            &:after {
                content: "";
                background-image: url(../img/dropdown-arrow.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-size: contain;
                height: 14px;
                width: 14px;
                position: absolute;
                top: 50%;
                z-index: 0;
                transform: translateY(-50%);
                right: 10px;

            }
        }


        .selectWrap {
            .css-26l3qy-menu {
                z-index: 999;
            }

            [class*=control] {
                min-width: 100%;
                border-radius: 10px;
                -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px rgba(210, 218, 230, 0.6);
                background-image: none;
                background-color: rgba(255, 255, 255, 0.35);
                min-height: 38px;
                font-size: 17px;
                color: rgba(67, 91, 117, 0.6);
                padding-right: 28px;
                cursor: pointer;

                [class*=IndicatorsContainer] {
                    display: none;
                }

                .css-319lph-ValueContainer {
                    padding: 0px 8px;
                    margin-top: -4px;
                    color: #7d91a9;
                }

                [class*="singleValue"],
                [class*="placeholder"] {
                    color: #7d91a9;
                }

            }
        }

        .col-content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;

            .formGroup {
                max-width: 50%;
                flex: 0 0 50%;
                padding: 0 8px;

                .react-tel-input {
                    .form-control {
                        min-height: 40px;
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    }
                }

                .SingleDatePicker {
                    width: 100%;

                    .SingleDatePickerInput {
                        background-color: rgba(255, 255, 255, 0.35);
                        border-radius: 10px;
                        border: 1px solid #dae2ee;
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                        width: 100%;
                        padding: 7px 15px 6px;
                        display: block;
                        overflow: visible;
                        box-sizing: border-box;
                        color: rgba(67, 91, 117, 0.6);
                        font-family: "Ubuntu", sans-serif;
                        font-weight: 400;
                        font-size: 17px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        min-height: 40px;

                        .DateInput_input {
                            background-color: transparent;
                            padding: 0px 0px 0px 10px;
                            border-bottom: none;
                            line-height: normal;
                            color: rgba(67, 91, 117, 0.6);
                        }

                        .DateInput_input__focused {
                            border-bottom: none;
                        }
                    }


                    .SingleDatePicker_picker {
                        top: 50px !important;
                        left: 0px !important;

                        .DayPicker_weekHeader {
                            margin-left: 17px;

                            .DayPicker_weekHeader_li {
                                width: 43px !important;
                            }
                        }
                    }
                }
            }

            .title-field {
                max-width: 130px;
                flex: 0 0 130px;
            }
        }

        .first-row-content {
            .col-content {
                .formGroup {
                    max-width: 37%;
                    flex: 0 0 37%;
                    padding: 0 10px;
                }

                .title-field {
                    max-width: 26%;
                    flex: 0 0 26%;
                }
            }
        }
    }
}

// save traveller popup profile end
.profile-info-popup {
    .attention-title {
        border-bottom: solid 1px #dae2ee;
        padding: 10px 0;
    }

    .text-icon {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .text-style {
            padding: 10px 15px 0px 0px;
        }

        .img-style {
            padding: 5px 5px;
            border: 2px solid #435b75;
            border-radius: 100px;
            line-height: 0;
            height: 40px;
            width: 40px;

            img {
                width: 25px;
            }
        }

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }
}


.add-save-traveller-popup {
    .popup-content {

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
    }

    .SingleDatePicker {
        select {
            box-shadow: none;
            -webkit-box-shadow: none;
            background-color: transparent;
            border: none;
            min-height: auto;
        }
    }

}

.attention-popup {
    .popup-content {
        max-width: 580px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
    }

    .popup-title {
        padding-bottom: 20px;

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        .text-icon {
            display: flex;
            justify-content: flex-start;

            .text-style {
                padding: 10px 15px 0px 0px;
            }

            .img-style {
                padding: 5px 5px;
                border: 2px solid #435b75;
                border-radius: 100px;
                line-height: 0;

                img {
                    width: 25px;
                }
            }
        }
    }

    .attention-info {
        padding: 20px 0px 0px 0px;
        color: #435b75;
        border-top: solid 1px #dae2ee;
        border-bottom: solid 1px #dae2ee;
        margin-bottom: 30px;

        h3 {
            font-size: 21px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 20px;
        }

        strong {
            font-weight: bold;
        }

    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .btn {
            margin: 0 5px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 7px 12px 7px 50px;
            background-position: left center;
            min-width: 130px;
        }

        .btn:before {
            background-image: none;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url('../img/profile/blue-btn-bg.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

// attention-popup end
.affiliate-profile-cancellation-popup {
    &.open-modal {
        display: flex;
    }

    .popup-title {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #dae2ee;

        h3 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 0;
            color: #435b75;
            display: flex;
            align-items: center;
        }
    }

    .popup-content {
        max-width: 580px;
    }

    .content-sub-title {
        padding: 25px 0;
        color: #435b75;
        border-bottom: solid 1px #dae2ee;

        h4 {
            font-size: 16px;
            color: #435b75;
            font-weight: 400;
        }
    }

    p {
        font-size: 16px;
        color: #435b75;
        font-weight: 400;
    }

    .attention-title {
        border-bottom: solid 1px #dae2ee;
        padding: 10px 0;
    }

    .text-icon {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .text-style {
            padding: 10px 15px 0px 0px;
        }

        .img-style {
            padding: 5px 5px;
            border: 2px solid #435b75;
            border-radius: 100px;
            line-height: 0;
            height: 40px;
            width: 40px;

            img {
                width: 25px;
            }
        }

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;

        .btn {
            margin: 0 5px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 7px 12px 7px 42px;
            background-position: left center;
            min-width: 130px;
        }

        .btn:before {
            background-image: none;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url('../img/profile/blue-btn-bg.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

.qrcode-popup {
    &.open-popup {
        display: flex;
    }

    .content-box {
        img {
            width: 100%;
            border-radius: 4px;
        }
    }

    .popup-content {
        padding: 30px 25px;

        @media (max-width: 600px) {
            max-width: 90%;
        }
    }
}

.room-details-popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: rgba(67, 91, 117, 0.5);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;

    .popup-content-wrap {
        max-width: 652px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:650px) {
            max-width: 95%;
        }
    }

    .popup-content {
        max-width: 100%
    }

    .popup-title {
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-left: 20px;

        h3 {
            @include ubuntu(500);
            font-size: 17px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
            margin-bottom: 18px;
        }
    }

    .popup-content {
        position: relative;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 13px 0px 13px 0px;
        max-height: 95vh;
        overflow: hidden;


        .box-content {
            margin-top: 0px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0px 20px 10px 20px;
        }
    }

    .close {
        margin-top: -4px;
        padding: 4px;
        font-weight: 400;
        font-size: 18px;
        position: absolute;
        top: 10px;
        right: 8px;
        line-height: 1;
        min-width: auto;
        box-shadow: none;
        background: none;
        border-radius: 0;

        &:before {
            display: none;
        }
    }

    .slider-image {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        text-align: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .room-details-content {
        h3 {
            @include ubuntu(400);
            font-size: 23px;
            line-height: normal;
            letter-spacing: normal;
            color: #1d6ec9;
            margin-bottom: 13px;
        }

        p {
            @include ubuntu(400);
            font-size: 14px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
        }

        .progress-wrap {
            max-width: 235px;
            margin-top: 20px;

            h4 {
                font-size: 14px;
                @include ubuntu(400);
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            h3 {
                font-size: 17px;
                @include ubuntu(400);
                line-height: normal;
                letter-spacing: normal;
                color: #00b7a6;
                margin-bottom: 0;
            }

            .progress {
                display: flex;
                height: 4px;
                overflow: hidden;
                line-height: 0;
                font-size: 0.75rem;
                background-color: rgba(126, 148, 168, 0.4);
                border-radius: 5px;
                box-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
                margin-top: 4px;

                .progress-bar {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -ms-flex-pack: center;
                    justify-content: center;
                    overflow: hidden;
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #00b7a6;
                    transition: width 0.6s ease;
                }
            }

        }

    }

    .room-facilities-wrap {
        margin-top: 23px;

        h3 {
            @include ubuntu(500);
            font-size: 17px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
            margin-bottom: 11px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 14px;
                @include ubuntu(400);
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #435b75;
            }
        }
    }
}

.saver-popup {
    display: none;

    .popup-content {
        position: relative;
        max-width: 530px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 15px;
        overflow-y: auto;
        background-color: rgb(239, 240, 245);

        .icon-close {
            position: absolute;
            top: 7px;
            right: 10px;
            border: none;
            background-color: transparent;

            :before {

                color: #1d6ec9;
            }
        }

        .heading {

            h1 {
                font-size: 25px;
                font-weight: 400;
                line-height: 30px;
                display: flex;
                align-items: center;
                padding-bottom: 7px;
                border-bottom: 1px solid gainsboro;


                &:before {
                    content: "";
                    background-image: url(../img/icon-super.png);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 66px;
                    width: 50px;
                    margin-right: 3px;

                }
            }
        }

        .popup-text {
            border-bottom: 1px solid gainsboro;
            padding-top: 15px;

            h3 {
                font-size: 20px;
                font-weight: 300;
                line-height: 23px;
                margin-bottom: 10px;
            }

            p {
                font-size: 18px;
                line-height: 21px;
                font-weight: 300;
                margin-bottom: 25px;
            }

        }

        .popup-footer {
            padding: 15px 0px;
            display: flex;
            justify-content: flex-end;

            button {
                min-width: 110px;
                padding: 8px;
                margin: 0px 10px;
                font-size: 13px;
                overflow: hidden;
            }

            .btn:before {
                height: 26px;
                width: 35px;
                background-size: 26px;
                position: absolute;
                left: -4px;
                top: 9px;
            }
        }

    }
}

.hotelDetailPopup {
    .popup-title {
        font-size: 17px;
        color: var(--text-color);
        font-weight: 500;
        margin-bottom: 5px;
        padding-left: 20px;
    }

    .popup-content .infoBox {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}

.ipad-portrait-popup {
    display: flex !important;

    &.ipad-portrait-popup-fadeout {
        display: none !important;
    }

    .content-box {
        text-align: center;
    }

    .popup-content {
        max-width: 550px;
        padding: 30px 20px 22px;
        margin-top: 12px;
    }

    h6 {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-size: 21px;
        line-height: normal;
        letter-spacing: normal;
        color: #435b75;
        margin-top: 12px;
    }
}

.hotel-confirmation-popup {
    .popup-content {
        position: relative;
        max-width: 580px;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 15px 21px;

        @media(max-width:600px) {
            max-width: 90%;
        }
    }

    .formGroup {
        h5 {
            font-size: 14px;
            color: #7d92a9;
            margin-bottom: 5px;
        }
    }

    .popup-content-title {
        padding: 20px 0;
        border-bottom: solid 1px #dae2ee;
        color: #435b75;
    }

    .popup-title {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #dae2ee;

        h3 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 0;
            color: #435b75;
            display: flex;
            align-items: center;
        }
    }

    h4 {
        font-size: 16px;
        color: #435b75;
        font-weight: 400;
        margin-bottom: 12px;
    }

    ul,
    ol {
        padding-left: 15px;

        li {
            margin-bottom: 10px;
        }
    }

    .popup-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 15px;
        margin: 0 -10px;

        .button-wrap {
            display: flex;
        }

        .btn {
            margin: 0 10px;
            min-width: 130px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 42px;
            background-position: left center;

            &:before {
                display: none;
            }

            @media(max-width:374px) {
                min-width: 110px;
                margin: 0 7px;
                font-size: 14px;
            }
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url(../img/profile/blue-btn-bg.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

.cancellation-policy-popup {
    color: #435b75;
    line-height: 1.3;

    .popup-content-wrap {
        .box-content {
            margin: 0;
            padding: 15px 0;
        }

        h5 {
            color: #435b75;
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 400;
        }

        p {
            color: #435b75;
            margin-bottom: 10px;
        }
    }

    @media(max-width:640px) {
        .popup-content {
            max-width: 90%;
        }
    }
}

.hotelDetailCheckoutPopup {
    .icon-close {
        &::before {
            color: #1d6ec9;
        }
    }

    .popUpBox {
        max-width: 90%;
        margin: 0px auto;
        border-radius: 10px 10px 0 0;
    }

    .contentGroup h2 {
        font-size: 18px;
    }

    .popup-title {
        font-size: 23px;
        padding-left: 5px;
    }

    .popup-content {
        padding: 25px 10px 15px;

        .box-content {
            margin-top: 10px;
        }
    }

    .roomsInfo {
        .sectionTitle {
            p {
                min-width: 80px;
            }
        }
    }

    .resortFeessection {
        background-color: #d4d6e2;
        border-radius: 8px;
        padding: 6px 10px;
        margin: 6px 0;

        h3 {
            font-size: 14px;
            margin-bottom: 2px;
            display: flex;
            align-items: center;

            strong {
                margin-left: 8px;
            }

            .circle {
                padding: 3px;
                border: 1px solid #435b75;
                border-radius: 100px;
                line-height: 0;
                height: 16px;
                width: 16px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 12px;
                }
            }
        }

        p {
            font-size: 12px;
            margin-bottom: 0;
            color: #435b75;
        }
    }

    .amountCalculation {
        padding: 12px 14px 5px 15px;
        border-radius: 8px;
        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
        border: solid 1px #dae2ee;
        background-color: rgba(255, 255, 255, 0.35);
        margin: 0;
    }

    @media(max-width:991px) {
        .resortFeessection {
            p {
                margin-left: 20px;
            }
        }
    }

    @media(max-width:680px) {
        .popup-content {
            max-width: 95%;
        }
    }

}

.modal-policy {
    z-index: 99;

    .icon-close {
        &::before {
            color: #1d6ec9;
        }
    }
}

// Responsive popup start

@media (max-width: 1024px) {

    .magic-section-popup,
    .magic-video-section-popup {
        .popup-content {
            max-width: 93%;
        }
    }
}

// 1024 end
@media (max-width: 991px) {
    .magic-video-section-popup {
        .popup-content {
            max-width: 600px;
        }
    }

    .magic-section-popup .popup-content .colCard .cardText h5 {
        font-size: 17px;
    }
}

@media (max-width: 767px) {
    .magic-section-popup {
        .popup-content {
            max-width: 420px;
            padding-bottom: 0;

            .colCard {
                flex-direction: column;
                margin: 0;

                .cardStyle {
                    margin: 0 0 30px;
                }

                .cardText {
                    h5 {
                        font-size: 19px;
                    }
                }
            }
        }
    }

    .ssn-collection-popup {
        .popup-content {
            max-width: 80%;
        }
    }

    .magic-video-section-popup {
        .popup-content {
            max-width: 500px;
        }
    }

    .attention-popup {
        .attention-info {
            h3 {
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }
}

// 767 end
@media (max-width: 640px) {
    .affiliate-profile-cancellation-popup {
        .popup-content {
            max-width: 90%;
            padding: 30px 15px 0;
        }

        .button-wrap .btn {
            padding: 7px 12px 7px 30px;
            min-width: 110px;
        }
    }
}

//640 end
@media (max-width: 580px) {
    .magic-section-popup {
        .popup-content {
            .colCard {
                .cardText {
                    padding: 5px 0;
                }
            }
        }
    }

    .ssn-collection-popup {
        .popup-content {
            max-width: 90%;
            padding: 30px 15px 20px;
        }

        .submit-btn {
            p {
                padding-right: 10px;
            }
        }
    }

    .magic-video-section-popup {
        .popup-content {
            max-width: 90%;
        }
    }

    .attention-popup,
    .save-traveller-popup {
        .popup-content {
            max-width: 90%;
        }
    }

    .save-traveller-popup .info-card .first-row-content .col-content {
        .title-field {
            select {
                padding-right: 12px;
            }
        }
    }
}

// 580 end
@media (max-width: 480px) {
    .magic-section-popup {
        .popup-content {

            .colCard {
                .cardText {
                    h5 {
                        font-size: 17px;
                    }
                }
            }

        }
    }

    .save-traveller-popup {
        .popup-content {
            padding: 30px 15px 0;
        }

        .information-content-wrap {

            form,
            .info-card {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;

                    .col {
                        padding: 0;
                    }
                }
            }
        }

        .button-wrap {
            .btn {
                min-width: 120px;

                &::before {
                    display: none;
                }
            }
        }

        .info-card {
            .first-row-content .col-content {

                .title-field,
                .formGroup {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .col-content {
                .formGroup {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

        }
    }
}

// 580 end