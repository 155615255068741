.slick-list {
    height: 100%;
    object-fit: cover;
    position: relative;
    // margin: 0 1px;
}

.slick-slider {
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    position: relative;
}

.slick-slide {
    padding: 0px;
}

.slick-slider {
    z-index: 0;

    .slick-next {
        right: -3px;
        z-index: 0;
        transform: rotate(90deg);
    }

    .slick-prev {
        left: 7px;
        z-index: 1;
    }

    .slick-next:before {
        padding: 9px 12px;
        border-radius: 100px;
        content: "\f107";
        display: inline-block;
        position: absolute;
        font: normal bold normal 18px/1 FontAwesome;
        -webkit-font-smoothing: antialiased;
        color: #1d6ec9;
        background-color: white;
        opacity: 0.5;
        right: -7%;
        transform: rotate(180deg);

    }

    .slick-prev:before {
        padding: 9px 12px;
        border-radius: 100px;
        content: "\f107";
        display: inline-block;
        font: normal bold normal 18px/1 FontAwesome;
        -webkit-font-smoothing: antialiased;
        color: #1d6ec9;
        background-color: white;
        opacity: 0.5;
        transform: rotate(90deg);
        top: 50%;
    }

    .w-100 {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
    }
}

.thumbnail {
    .slick-list {
        margin: 0 -3.5px;
    }
    .slick-slide {
        padding: 0 2.5px;
    }
}

@media(max-width:991px) {
    .slick-slider {
        height: 100%;
        max-height: 340px;
        object-fit: cover;
        position: relative;

    }

    .w-100 {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;

    }

    .thumbnail {
        .slick-arrow {
            top: 40%;
        }
    }
}

@media(max-width:767px) {
    .slick-slider {
        .slick-prev:before {
            padding: 2px 5px;
        }

        .slick-next:before {
            padding: 2px 5px;
            right: 50%;
        }
    }
}