.bookingPageWrap {
    background-image: url('../../../img/members-checkout-bg.jpg');
    background-repeat: repeat-y;
    background-size: cover;
    background-position: 50%;

    @media(max-width:767px) {
        background-image: linear-gradient(144deg, #006bbf 18%, #00ceda 91%);
    }

    .leftSideBar {
        margin: 0;
        width: 100%;
    }

    .leftsidebar-wrap {
        width: 220px;
        margin-right: 30px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 220px;

        @media (max-width:1024px) {
            width: 200px;
            max-width: 200px;
        }
    }

    .custom-tabs-wrap {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .sidebarTabs {
        a {
            &:hover {
                .icons-list {
                    &::before {
                        color: #1d6ec9;
                    }
                }
            }
        }

        .active {
            padding: 0;

            a {
                color: #1d6ec9;
            }

            .icons-list {
                &::before {
                    color: #1d6ec9;
                }
            }
        }

        .icons-list {
            margin-right: 13px;

            &::before {
                color: #ffffff;

                @media(max-width:991px) {
                    color: #1d6ec9;
                }
            }
        }

    }

    .tabs-content-wrap {
        .react-tabs {
            display: flex;
            justify-content: space-between;
        }
    }

    .mainWrap {
        padding-right: 0;
        width: 100%;
    }

    .cardContent {
        .dflex {
            .innerCnt {
                flex: 0 0 70%;
                width: 70%;

                @media(max-width:991px) {
                    flex: 0 0 65%;
                    width: 65%;
                }

                .smlText {
                    padding-right: 15px;
                }
            }



        }
    }

    .iconWrapMain {
        .booking-status {
            margin: 10px 0 0;
            justify-content: center;
        }
    }

    .infoBox {
        min-height: 158px;

        .btn {
            top: 0;
            margin-top: 0;
            border-radius: 22.5px;
        }
    }

    .tabs-heading-title {
        .sideBarTitie {
            display: none;
        }
    }

    .not-found-booking {
        flex-direction: column;

        h2 {
            margin-bottom: 12px;
            font-size: 22px;
        }

        p {
            margin-bottom: 12px;
            font-size: 15px;
        }

        .btn {
            margin-top: 15px;
            min-width: 220px;

            &:before {
                left: 0;
            }
        }
    }

    @media (min-width:992px) {
        .mainContent {
            .pageInternalScroll {
                height: calc(100vh - 120px) !important;
            }
        }
    }

    @media (max-width:992px) {
        .mainContent {
            min-height: calc(100vh - 55px);

            .pageInternalScroll {
                height: 100%;
                max-height: 100%;

                >div {
                    position: relative !important;
                }
            }
        }

        .tabs-content-wrap .react-tabs {
            flex-direction: column;

            .sideBarTitie {
                display: none;
            }
        }

        .tabsWrapper {
            width: 100%;
        }

        .tabs-heading-title {
            display: flex;
            justify-content: space-between;

            .sideBarTitie {
                display: block;
            }
        }
    }

    // 992 px end
    @media (max-width:579px) {
        .iconWrapMain {
            .booking-status {
                display: none;
            }
        }

        .cardContent {
            .copyText {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }


}

@media(max-width:767px) {
    .bookingPageWrap {

        .cardContent {
            .dflex {
                .innerCnt {
                    flex: 0 0 100%;
                    width: 100%;

                    .smlText {
                        padding-right: 0px;
                    }
                }

            }

        }
    }
}

@media(max-width:579px) {
    .bookingPageWrap {
        .mainWrap .hotelCopy {
            padding-right: 70px;
        }
    }
}

@media(max-width:374px) {
    .bookingPageWrap {
        .cardContent {
            .cardCol .col .dateInWrap {
                display: block;
            }
        }
    }
}