.flight-checkout {
    .mainWrap {
        padding-right: 0px;

        .card2 {
            padding: 17px 20px;

            .cardPaymentOption {
                .col {
                    margin-bottom: 13px;
                }

            }

        }

        .cardWalletInfo .customCheckbox input:checked+span:after {
            top: 1px;
            left: 5px;
        }

        .primaryInfo {
            .primaryInfoTitle {
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;

                @media (max-width:479px) {
                    display: block;
                    padding-bottom: 0;

                    .select-user-label {
                        display: block !important;
                    }
                }

                .selectWrap {
                    color: #1d6ec9;
                    font-size: 14px;
                    min-height: 30px;
                    min-width: 180px;
                }
            }
        }
    }

    .selectInWrap {
        margin-bottom: 14px;

        .col {
            padding: 0px 0px;
        }
    }

    .billingInfo {
        .sectionTitle {
            margin-bottom: 25px;
        }
    }

    .selectWrap {


        [class*=control] {
            min-width: 100%;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
            -webkit-box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: rgba(255, 255, 255, 0.35);
            height: 40px;
            z-index: 1;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);

            cursor: pointer;

            [class*=singleValue] {
                color: rgba(67, 91, 117, 0.6);
            }

            // .css-1hb7zxy-IndicatorsContainer {
            //     display: none;
            // }

            .css-319lph-ValueContainer {
                padding: 0px 8px;
                margin-top: -4px;
            }

            [class*=Input] {
                margin: 0px;
                padding: 0px;
            }

            [class*=option] {
                cursor: pointer;
            }
        }

    }

    .primaryInfo {
        input {
            background-image: none;
            padding: 9px 15px 10px;
            color: #435b75;
            min-width: auto;
            height: 40px;
        }

        .col3 {
            .col:first-child {
                padding-left: 0px;
            }
        }

        .col {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            padding-right: 7.5px !important;
            width: 100%;
        }
    }

    .roomsInfo {
        .profileTitle2 {
            width: 190px;
        }

        .inputStyle {
            input {
                background-image: none;
                padding: 9px 15px 10px;
                color: #435b75;
                min-width: auto;
            }
        }

        .new-lead-roomss-row {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .wrapper-padding {
                padding: 0px 7.5px;
                width: 33.33%;

                .col {
                    padding: 0px 0px;
                }
            }

            .col-room {
                padding: 0px;
                width: 33.33%;
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;

            }

            .single-date-picker-wrap {
                display: flex;

                .SingleDatePicker {
                    display: flex;
                    width: 100%;

                    >div {
                        width: 100%;
                    }
                }

                .SingleDatePickerInput {
                    padding: 9px 15px 7px;
                    font-size: 17px;
                    color: #1d6ec9;
                    background: rgba(255, 255, 255, 0.35);
                    border-radius: 10px;
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    background-image: none;
                    min-width: 100%;
                    border-color: transparent;
                }

                .DateInput_input {
                    background-color: transparent;
                    padding: 0px 7px;

                }
            }
        }
    }

    .sideBar {
        .flightInfo-1 {
            padding: 0px 10px;
        }

        .flightInfoRow {
            padding: 0px 10px;
        }

        .flightInfoRow {
            border-top: 1px solid #afd3e3;
            padding-top: 27px;
            margin-top: 28px;
        }

    }

    .GlobalPaymentIntegrationWrap {
        .billingDetailsToggle ul {
            margin: 0px;
            padding-bottom: 5px;

        }

        .walletOptionWrap {
            ul {

                margin-bottom: 14px;
            }

            .checkBoxWrap {
                .checkBoxContent {
                    .checkBoxContentWrap p {
                        padding-left: 29px;
                        margin-top: 3px;
                    }
                }
            }
        }

        .customCheckbox>span:before {
            height: 24px;
            width: 24px;
        }

        .boxTitle {
            margin-bottom: 10px;
        }

        .sectionTitle {
            margin-bottom: 20px;
        }

        .cardDetailsBox {
            .col {
                padding: 0 7.5px;
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;
                margin-bottom: 0 !important;

                @media(max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                @media(max-width:374px) {
                    padding-bottom: 12.5px;
                }
            }

            .cardHolder {
                max-width: 245px;

                @media(max-width:767px) {
                    max-width: 100%;
                    padding-bottom: 12.5px;
                }

                input {
                    min-width: 100%;
                }
            }
        }
    }

    .buttonWrap {
        .icon-lock {
            margin-right: 10px;
        }
    }

    .SingleDatePicker,
    .SingleDatePicker_picker {
        input {
            font-weight: 400;
            color: rgba(67, 91, 117, 0.6);
            font-size: 17px;

            @include placeholder-text {
                font-weight: 300;
            }
        }

        select {
            border: transparent;
            background-color: transparent;
        }
    }
}

@media(min-width:992px) {

    .flight-checkout {
        .mainContent {
            padding-top: 55px;

            .pageInternalScroll {
                padding-top: 0px;
                height: calc(100vh - 120px) !important;
                overflow-y: auto;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }


    }
}

@media(max-width:992px) {
    .flight-checkout {

        .mainWrap {
            padding-right: 0px;
        }

        .mainContent {
            padding-top: 55px;
        }

        .primaryInfo {
            .dropdown {
                margin-bottom: 14px;
            }
        }

        .col3 {
            .col {
                padding: 0px 0px;
            }
        }

        .cardPaymentOption {
            .formGroup {
                margin-bottom: 14px;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

@media(max-width:767px) {
    .flight-checkout {
        .mainWrap {

            padding-right: 0px;
        }

        .roomsInfo {

            .new-lead-roomss-row.mobile-view-room {
                display: flex;
                flex-direction: row;

                .wrapper-padding.profileTitle2 {
                    width: 190px;
                    margin-bottom: 14px;
                }

                .wrapper-padding {
                    padding: 0px 7.5px;
                    width: auto;
                    margin-bottom: 0px;

                    .selectInWrap {
                        max-width: 190px;
                    }
                }

                .mobile-view-width {
                    width: 100%;
                }
            }

            .profileTitle2 {
                margin-bottom: 14px;

            }

            .new-lead-roomss-row {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .wrapper-padding {
                    padding: 0px 7.5px;
                    width: 100%;
                    margin-bottom: 14px;
                }

                .col-room {
                    width: 100%;

                    .col {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        .primaryInfo {
            .fName {
                max-width: 100%;
                flex: 0 0 100%;
            }

            .col {
                flex: 0 0 100%;
                padding-bottom: 12.5px !important;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .flight-checkout {
        .container.dflex {
            flex-direction: column;
        }

        .mainWrap {
            order: 1;
            width: 100%;
        }

        .sideBar {
            order: 0;
            margin: 0 0 6px;
            max-width: 100%;
            width: 100%;
        }
    }
}

@media (max-width: 359px) {

    .flight-checkout {
        .card2 {
            .row {
                // margin: 0px;
            }

            .primaryInfo {
                .col {
                    padding-right: 0 !important;
                    padding-left: 0px;
                }

                .dropdown {
                    max-width: 100%;
                    flex: 0 0 100%;

                }
            }
        }

        .cardWalletInfo {
            .boxTitle {
                small {
                    font-size: 16px;
                }
            }
        }

    }
}