//   home-page accordion button style
.bannerSec {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .bannerImg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    padding-bottom: 10px;
    color: inherit;
  }

  p {
    font-size: 17px;
    padding-bottom: 45px;
    color: inherit;
  }

  .bannerCont,
  .header {
    position: relative;
    z-index: 1;

    .logo-sticky {
      display: none;
    }
  }

  .bannerCont {
    color: #fff;
  }

  .scrollBtm {
    background-image: linear-gradient(92deg, #0072d1, #00dcdf);
    border: 0;
    display: block;
    text-align: center;
    width: 100vw;
    color: #fff;
    padding: 9px;
    font-size: 28px;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(92deg, #00dcdf, #0072d1);
    }

    .mousey {
      width: 3px;
      padding: 10px 10px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }

    .scroller {
      width: 3px;
      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15, .41, .69, .94);
      animation-iteration-count: infinite;
    }

    @keyframes scroll {
      0% {
        opacity: 0;
      }

      10% {
        transform: translateY(0);
        opacity: 1;
      }

      100% {
        transform: translateY(15px);
        opacity: 0;
      }
    }
  }

  .header {
    font-size: 14px;
    padding: 18px 15px;

    .navigation {
      margin-right: 15px;

      ul a {
        padding: 8px 0;
        font-size: 19px;
      }
    }

    .btnBorder3 {
      font-size: 19px;
      padding: 7.5px 10px;

      &:hover {
        background-color: inherit;
        color: #fff;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.home-banner {
  position: relative;
  // background-image: url('../../img/home/Homepage-Banner-Video.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .home-iframe-wrapper {
    // pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  iframe,
  video,
  .video-js,
  video.vjs-tech {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
  }

  .video-js .vjs-big-play-button {
    display: none;
  }

  .scrollBtm {
    position: absolute;
    background: transparent;
    font-size: 44px;
    line-height: 0;
    bottom: 30px;
    padding: 5px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    img {
      max-width: 29px;
    }

    a {
      color: #ffffff;
    }

    &:hover {
      background: transparent;
    }

    .mousey {
      width: 3px;
      padding: 10px 10px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }

    .scroller {
      width: 3px;

      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15, .41, .69, .94);
      animation-iteration-count: infinite;
    }

    @keyframes scroll {
      0% {
        opacity: 0;
      }

      10% {
        transform: translateY(0);
        opacity: 1;
      }

      100% {
        transform: translateY(15px);
        opacity: 0;
      }
    }
  }

  .mobile-video {
    display: none;
  }
}

.vjs-big-play-button {
  display: none;
}

.indexPageWrap {
  padding-bottom: 0;

  .accordWrap {
    .accordBody button {
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: normal;
      letter-spacing: normal;
      color: #435b75;
      border: none;
      outline: none;
      width: 100%;
      background-color: transparent;
      cursor: pointer;
      text-align: left;
      padding: 17px 22px 17px;
      display: flex;
    }
  }

  .colCard {
    .col {
      max-width: 25%;
      flex: 0 0 25%;
      width: 100%;
      padding: 0 10px 15px;
    }
  }
}

.triptek-difference-wrapper {
  padding: 0 0 50px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;

  p {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 19px;

    strong {
      font-weight: 500;
    }
  }

  .sctionTitle {
    color: #fff;
  }
}

.tripello-magic-content {
  margin-top: 20px;
  max-width: 1110px;
  margin: 20px auto 0;

  strong {
    font-weight: 500;

    button {
      border: none;
      background: transparent;
      color: #435b75;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 19px;
      padding: 0px 5px;
      cursor: pointer;
    }
  }
}

.choosePlan {
  display: flex;
  justify-content: center;
  width: 100%;

  .col {
    flex-grow: 1;
    padding: 0 18px;
    max-width: 412px;
    width: 50%;
  }

  .cardStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    height: 100%;
    text-align: left;
  }

  .planImgBox {
    padding: 10px 10px 8px;
    border-radius: 6px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    background-color: rgba(255, 255, 255, 0.35);
  }

  .planImgInner {
    background: url("../../../img/planBg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: 6px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .planName {
      margin-top: 10px;
      color: $white;
      font-size: 17px;
    }

    p {
      color: $white;
      font-size: 14px;
      font-weight: normal;
      margin-top: 2px;
    }

    .plus-img {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }

  .planNote {
    color: rgba(67, 91, 117, 0.6);
    margin-bottom: 17px;
    font-size: 14px;
  }

  .planDetails {
    margin-bottom: auto;
    margin-left: 0;
    padding: 17px 20px 2px;
    list-style: none;
    color: #435b75;
    font-size: 14px;

    li {
      margin-bottom: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;

      .icon-tick {
        color: #00b7a6;
        margin-right: 9px;
        font-size: 11px;
      }
    }
  }

  .planBtmBox {
    padding: 6px 16px 12px;

    .planRateBox {
      border-top: 1px solid rgba(67, 91, 117, 0.2);
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #435b75;
      text-align: center;

      h4 {
        color: #435b75;
        font-size: 21px;
        font-weight: 500;
        padding-bottom: 5px;

        span {
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
        }
      }

      .btn {
        min-width: 155px;
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
        margin-top: 12px;

        &:before {
          display: none;
        }
      }
    }
  }

  .paidPlan {
    .planBtmBox .planRateBox {
      h4 {
        font-size: 25px;
        letter-spacing: normal;

        sub {
          vertical-align: baseline;
          font-size: 19px;
          font-weight: normal;
          letter-spacing: 0;
        }
      }
    }
  }


}

.video-js .vjs-big-play-button {
  display: none;
}

.tripello-magic-wrapper {
  .videoBox {
    .video-play-btn img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
  }
}

.accordWrap {
  max-width: 720px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;

  .accordBody+.accordBody {
    border-top: 1px solid rgba(67, 91, 117, 0.2);
  }

  .accordion-border {
    border-bottom: 1px solid rgb(67, 91, 117, 0.2);
  }

  .accordBody {
    &.opened {

      .accordCnt {
        max-height: 170px;

      }

    }
  }

  .accordHead {
    font-weight: normal;
    font-size: 14px;
    color: #435b75;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 11px;
    cursor: pointer;

    .fa {
      font-size: 22px;
    }

    .fa-question-circle-o {
      display: none;
      margin-right: 12px;
      text-shadow: 0 0 2px #ddd;
      font-size: 20px;
    }

    .fa-angle-down {
      color: #2571ca;
      margin-left: 10px;
    }
  }

  .accordCntSpace {
    padding: 0 10px 9px;
  }

  .accordCnt {
    font-size: 14px;
    color: #435b75;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }

  .active-btn {
    .fa-angle-down {
      transform: rotate(180deg);
    }
  }
}

// Home-page-faq

.home-faq {
  .accordWrap {
    .accordHead {
      justify-content: flex-start;
      align-items: flex-start;

      img {
        max-width: 26px;
        margin-right: 12px;
        margin-top: -1px;
      }
    }
  }
}

// Home-page-faq end

.faq-wrapper {
  .accordion {
    button img {
      margin-right: 10px;
      max-width: 22px;
      margin-top: -2px;
    }
  }
}

// Responsive start
@media (max-width: 1400px) {
  .indexPageWrap .triptek-inventory-wrapper .colCard {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .bannerSec {
    .header {
      .logoWrap img {
        height: inherit;
        max-width: 147px;
      }

      .navigation {
        margin-right: 50px;
      }
    }

    .btnBorder3 {
      min-width: 120px;
    }

    h3 {
      font-size: 50px;
      padding-bottom: 30px;
    }

    p {
      font-size: 25px;
      padding-bottom: 95px;
    }
  }
}

@media (max-width: 767px) {
  .tripello-magic-wrapper {
    background: none;
    padding-top: 0;
    margin-top: 43px;
    padding-bottom: 30px;

    .sctionTitle,
    p {
      color: #435b75;
      margin-bottom: 0;
    }

    p {
      padding-bottom: 25px;
    }

    .videoBox {
      padding: 10px;
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      // min-height: 210px;
      overflow: hidden;

      .videoInBox {
        min-height: 180px;
      }

      video,
      iframe {
        height: 100%;
        width: 100%;
      }

      .desktop-video {
        display: none;
      }

      .mobile-video {
        display: block;
      }

      .video-play-btn {
        img {
          max-width: 70px;
        }
      }

      .videoInBox {
        &:after {
          width: 84px;
          height: 84px;
          background-size: contain;
        }
      }
    }

    .cardStyle {
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      padding: 10px;
    }
  }

  .bannerSec .header {
    .navigation ul {
      a {
        font-size: 14px;
      }
    }

    .btnBorder3 {
      font-size: 14px;
    }
  }

  .home-banner {
    .mobile-video {
      display: block;
    }

    .desktop-video {
      display: none;
    }

    .scrollBtm {
      bottom: -40px;
    }

    .bannerCont {
      position: absolute;
      bottom: 130px;
      left: 0;
      right: 0;

      p {
        padding-bottom: 0;
      }
    }
  }

  .home-faq {
    .accordWrap {
      .accordBody {
        padding-right: 30px;
      }

      .moreAccordBody {
        padding-right: 0;
      }

      .accordHead {
        display: flex;
        align-items: center;
        position: relative;
        padding: 12px 0 12px 10px;

        &:after {
          content: "";
          display: block;
          background-image: url('../../../img/network/dropdown.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 14px;
          width: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
        }

        &.active-btn {
          font-size: 17px;

          &:after {
            background-image: url('../../../img/network/dropdown.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        img {
          display: none;
        }
      }
    }
  }

  .sctionTitle {
    max-width: 250px;
    margin: 0 auto;
  }

  .triptek-difference-wrapper p {
    font-size: 17px;
    margin-bottom: 16px;
  }

  .indexPageWrap {
    padding-bottom: 0;

    .accordWrap {
      .accordBody button {
        padding: 12px 0 12px 10px;
        font-size: 14px;
      }
    }

    .triptek-inventory-wrapper {
      max-width: 375px;
      padding: 45px 15px 0px;

      .colCard .col {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        padding: 0 0 15px;

        .cardStyle {
          flex-direction: row;
          height: 100%;
          box-sizing: border-box;
          padding: 4px;
        }
      }
    }

    .colCard .cardStyle .cardText {
      padding: 2px 9px 2px;
    }
  }
}

// 767 end