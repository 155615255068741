// widget-styling

.widget-page-wrap {
  .inputStyle {
    .widget-dropdown {
      max-width: 660px;
      width: 100%;
      position: absolute;
      top: 0;
      box-sizing: border-box;

      .icon-location-on {
        position: absolute;
        left: 15px;
        font-size: 18px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      border: solid 1px #d2dae6;
      height: 43px !important;
    }
  }

  button {
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
  }

  .removeFlight {
    border: none;
    background: none;
    color: white;
  }

  .fa-angle-down {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 15px;

  }

  .tabContent {
    padding: 20px 30px 31px;
  }

  .centerAlign {
    display: none;
  }

  .mainContent {
    .boxStyle {
      .tabsStyle {
        a {
          padding: 21px 11px 13px 12px;
          margin-left: 11px;
        }

        a:hover {
          color: #1d6ec9;
          border-color: #1d6ec9;
        }

        a:hover [class^=icon-] *:before {
          color: #1d6ec9;
          text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
        }
      }
    }
  }

}

// widget-tab-dropdown-style

.widget-page-wrap {
  .dropdown-menu {
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
    border: solid 1px #cfd1e1;
    background-color: #e6e7ee;
    position: absolute;
    top: 50px;
    left: 0;
    margin-top: 5px;
    box-sizing: border-box;
    display: none;
    padding: 6px;
    min-width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden !important;
    display: none;
    z-index: 2;

    .disabled {
      width: 100%;
      font-size: 17px;
      display: block;
      padding: 11px 8px;
      border-radius: 6px;
      color: var(--text-color);
      border: solid 1px transparent;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #ffffff;
      border-color: #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    }

    .dorpdown-content {
      position: relative;
      align-items: center;
      display: flex;
      color: var(--text-color);
      font-size: 17px;
      width: 100%;
      padding: 11px 30px;
      border-radius: 6px;
      border: solid 1px transparent;

      .icon-location-on {
        position: absolute;
        left: 10px !important;
        font-size: 16px;
        top: 20px;
        transform: translateY(-50%);

      }

      .icon-location-on:before {
        color: var(--text-color2);
      }

      .locationName {
        padding-left: 20px;
        position: relative;
      }

      .locationCode {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;

        .autocompleteSubText {
          margin-top: 5px;
        }
      }
    }
  }

  .dorpdown-content:hover {
    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
      inset -2px -2px 6px 0 #ffffff;
    border-color: #d2dae6;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
  }
}

.Date-picker-range {
  .kuRhLG {
    .DateRangePickerInput {
      .DateInput .DateInput_input {
        font-size: 17px;

      }
    }
  }
}

// Date-picker-styles

.Date-picker-range {
  .DateRangePickerInput {
    font-size: 17px;
    color: #1d6ec9;
    border-radius: 10px;
    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
      inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #d2dae6;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    padding: 0px 0px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;

    .DateInput {
      flex: 0 0 0px;
      margin: 9px;
      width: 85px;

      .DateInput_input {
        font-size: 17px;
        border-bottom: 0;
        padding: 5px 0px 0px;
      }

      #check_out {
        margin-right: 8px;
      }
    }

    .DateRangePickerInput_calendarIcon {
      margin-right: 0px;
      vertical-align: top;
      margin-top: -5px;
      margin-left: 5px;
      padding: 10px 8px;
    }

    .DateRangePickerInput_arrow {
      display: inline-block;
      vertical-align: middle;
      color: #484848;
      width: 53px;
      margin-left: 5px;
      margin-top: -6px;
    }
  }

}

.widget-page-wrap {
  .tabContentWrap {
    .Date-picker-range {
      .DateRangePicker_picker.DateRangePicker_picker {
        // top: 47px !important;
      }
    }

    .startEndDate {
      .SingleDatePicker_picker {
        // top: 40px !important;
      }
    }
  }

  .footCopy {
    padding: 20px 0 12px;
    margin-left: -15;
  }
}

// Flight-tab-style

#flightsTab {
  .flexStart .flightType {
    .btnSml {
      padding: 6px;
      margin-right: 10.9px;
      min-width: 120px;
    }
  }

  .btnGroup {
    .btnSml {
      font-family: "Ubuntu", sans-serif;
    }
  }

  .Date-picker-range {
    .DateRangePickerInput {
      margin-right: 11px;
    }
  }
}

// Hotel-tab-style

#apartmentsTab {
  .filterSecondRow {
    .inputStyle {
      margin-right: 0px;
    }

    .Date-picker-range {
      margin-left: 10px;

      .custom-date-range {
        margin-top: -25px;
        margin-bottom: 9px;
        color: #1d6ec9;
        text-align: center;
        margin-left: -10px;

        @media(max-width:991px) {
          margin-left: 0px;
          // margin-bottom: 10px;
        }

        p {
          color: inherit;
          font-size: 14px;
          font-weight: 400;

          @media(max-width:991px) {
            font-size: 14px;
          }
        }
      }

      .DateRangePicker .DateRangePickerInput {
        max-width: 300px;
        min-height: 43px;
        height: 43px !important;
        display: flex;
        justify-content: flex-start;

        .startDate {
          .icon-invitation {
            margin-right: 0px;
            vertical-align: top;
          }
        }

        .DateRangePickerInput_arrow {
          width: 100px;
          margin-left: 10px;
          margin-top: -5px;

          .endDate {
            padding-left: 10px;
          }
        }

        .DateInput {
          margin: 5px 0px 5px 0px;
          width: auto;
          margin-right: 0px;
          margin-left: 0px;
          background: transparent;
        }
      }
    }
  }

  .Date-picker-range .DateRangePickerInput .DateRangePickerInput_calendarIcon {
    margin-top: 0;
  }
}

// add-button style

.dropdownInWrap {
  .startEndDateRoom {
    button {
      padding: 6px 0 6px;
      border: none;
      color: #1d6ec9;
      font-size: 14px;
      display: inline-block;
      padding-left: 7px;
      background: transparent;
      cursor: pointer;

      .icon-add {
        margin-right: 5px;
      }
    }
  }
}

// add-button style end

// Close-btn style
.dropDownSubWrap {
  .roomHead {
    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}

.dropdownInWrap {
  .inWrap {
    padding: 15px 0 0;
    color: #435b75;

    .noteText {
      font-size: 12px;
      color: #7d92a9;
    }

    .add-child-age {
      width: 100%;

      .add-age {
        display: flex;
        flex: 0 0 100%;

        >div {
          display: flex;
          flex: 0 0 100%;
          width: 100%;

          .css-b62m3t-container {
            margin-left: auto;
            font-size: 14px;
            padding: 2px;
            width: 100px;
            border-radius: 8px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            margin-right: 8px;
            cursor: pointer;

            .custom-select__control {
              background-color: transparent;
              padding: 0px;
              min-height: auto;
            }

            .css-1w9j89e-menu {
              top: 100%;
            }

            .css-319lph-ValueContainer {
              padding: 2px 7px;
            }

            .custom-select__menu,
            .css-4ljt47-MenuList {
              background-color: #e6e7ee;
              border-radius: 6px;
              box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
            }
          }
        }
      }
    }

  }

  // .form-row {
  //   .add-room-scroll {
  //     >div {
  //       margin-right: -10px;
  //     }
  //   }
  // }
}

// widget-style-updated age-count

.child-age-flex {
  width: 100%;

  >div {
    display: flex;
    flex: 100%;
    flex-wrap: nowrap;

    label {
      flex: 0 0 50%;
      width: 50%;
    }

    .css-b62m3t-container {
      display: flex;
      flex: 0 0 50%;
      width: 50%;
      justify-content: right;
    }
  }
}

//  resposive view-popup- calender

.date-picker-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--bg-color);
  padding: 30px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  z-index: 999;

  .closePopup {
    position: absolute;
    padding: 15px;
    top: 0;
    right: 0;

    .icon-close:before {
      color: var(--text-color3);
    }
  }
}

span,
.css-1f43avz-a11yText-A11yText,
.css-1f43avz-a11yText-A11yText {
  outline: none;
  border: none;
}

#oneWay,
#rounttripBox {
  .filterSecondRow {
    padding-top: 50px;

    .searchBtnWrap {
      margin-bottom: 0px;

    }
  }

  .startNendWrap {
    margin-right: 10px;
    width: 100%;

    .inputStyle {
      max-width: auto;
      min-width: auto;
      width: 100%;
      box-sizing: inherit;
    }
  }

  .locationAndDateWrap .startEndDate {
    margin: 0px 12px 0px 0px;
  }
}

// cars tab style start

#carsTab {
  position: relative;

  &.tabContent {
    padding-top: 26px;
  }

  .startNendWrap {
    display: flex;
  }

  .startEndDate {
    margin-left: 0px;
    min-width: 100%;
    padding: 9px 15px 7px 15px;
    min-height: 43px;

    .icon-invitation {
      font-size: 16px;
    }

    .DateInput {
      input {
        width: 100%;
        padding-left: 4px;
      }
    }
  }

  .timeWrap {
    [class^=icon-] {
      font-size: 18px;
      left: 16px;
      margin-top: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
    }

    .fa {
      position: absolute;
      right: 13px;
      top: 14px;
      font-size: 21px;
      color: #1d6ec9;
    }

    .btnSml.css-b62m3t-container {
      padding: 0px;
      background: transparent;
      border: none;
      background-image: none;
      box-shadow: none;
      margin-right: 0px;

      .custom-select__control {
        color: #1d6ec9;
        border-radius: 10px;
        min-width: 100%;
        min-height: 43px;
        height: 43px;
        font-size: 17px;
        padding: 0px 36px;
        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
        border: solid 1px #d2dae6;

        ::after {
          display: none;
        }


        .custom-select__value-container {
          padding: 0px;
          margin: 0px;
          width: 100%;
        }

        .custom-select__input-container,
        .custom-select__input {
          width: 100%;
          display: block;
          margin: 0px;
          padding: 0px;
          box-shadow: none;
          background-image: none;
          background-color: transparent;
        }


      }


    }
  }

  .css-b62m3t-container {
    .custom-select__value-container {
      .custom-select__single-value {
        color: #1d6ec9;
      }
    }
  }

  .twoCol {
    padding-top: 28px;
  }

  .searchBtnWrap {
    text-align: center;
  }

  .pickUpPonint {
    padding-left: 0px;
  }

  .dropOfPoint {
    padding-right: 0px;
  }

  .dropOfPoint,
  .pickUpPonint {
    padding-bottom: 0px;

    >.inputStyle {
      margin-bottom: 5px;
      min-height: 45px;

      .icon-location-on {
        top: 50%;
      }
    }
  }

  .rightBtnSpace .pickup-drop-main {
    display: flex;

    @media (max-width:767px) {
      display: block;
      margin-bottom: 20px;
    }

    .pickup-drop-wrap {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0;

      @media (max-width:767px) {
        margin-bottom: 0;
      }
    }

    .pickUpPonintCol {
      padding: 0;
    }

    .widget-dropdown {
      max-width: 100%;
    }

    .return-car-col {
      padding: 0;
    }

    @media (min-width:768px) {
      .return-car-col {
        position: absolute;
        top: 25px;
        right: 0;
        max-width: 180px;
      }
    }
  }

  .autoCompleteInput {
    padding-left: 36px;
  }

  .advancedSettings {
    cursor: pointer;

    .fa {
      font-size: 21px;
      margin-left: 5px;
    }

    &.settingON {
      .fa {
        transform: rotate(0deg);
        position: relative;
        top: 2px;
        left: 4px;
      }
    }
  }

  .dropdownInWrap {
    white-space: nowrap;
    top: 47px;
  }

  .searchBtnWrap {
    padding-top: 0px;
  }

  .rightBtnSpace {

    .flex-wrapper {
      display: flex;
      margin: 0px -5px;
    }

    .flex-col {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 5px;
      padding-left: 5px;
    }

    .customFlex.flex-col {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 5px;
      padding-left: 5px;
    }

    .pickup-drop-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 23px;


      .col-12 {
        width: 100%;
        padding: 0px;

        .inputStyle {
          .widget-dropdown {
            max-width: 100%;
          }
        }
      }

      .customCheckbox>span {
        margin-top: 15px;
      }
    }

    .inputStyle {
      .widget-dropdown {
        padding: 12px 15px 10px 36px;
      }
    }
  }

  .SingleDatePickerInput {
    display: flex;
  }

  .advancedSettingsBox {

    .btnSml {
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-image: none;
      border-radius: 0;
      padding: 0px;
      margin-right: 0px;
    }

    .BSrow {
      margin-top: 26px;
    }

    .col-3 {
      margin-bottom: 0px;
      padding: 0px 5px;
    }

    .fa {
      font-size: 21px;
      font-weight: 400;
      margin-top: 0px;
      line-height: 15px;
      color: #1d6ec9;
    }

    .inputStyle.noIconInput {
      input {
        padding: 12px 15px 10px 15px;
        font-size: 14px;
      }

    }
  }

  .btnSml.css-b62m3t-container {

    .custom-select__control,
    .custom-select__input-container {
      min-width: 100%;
      width: 100%;
      padding: 0px;
      height: 43px;
      position: relative;
      background-color: transparent;

    }

    [class*=control] {
      border-radius: 10px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      position: relative;
      padding: 0px 2px 0px 16px;
      color: var(--text-color3);
      border-color: transparent;
      cursor: pointer;
      white-space: nowrap;
      min-height: 43px;
      border: solid 1px #d2dae6;

      .css-14el2xx-placeholder {
        color: #1d6ec9;
        font-size: 17px;
      }

    }

    .custom-select__value-container {
      padding: 0;
    }

    [class*=Input] {
      margin: 0px;
      height: 43px;

      input {
        box-shadow: none;
      }
    }

    .custom-select__input-container {
      margin: 0;

      &:after {
        display: none;
      }

      .custom-select__input {
        min-width: 0px !important;
      }
    }

    .custom-select__value-container {
      padding: 0px 0px;
      font-size: 17px;
      color: #1d6ec9;

      .custom-select__single-value {
        padding: 0px 0px;
        margin: 0;
        color: #1d6ec9;
      }

      .custom-select__indicator {
        padding: 0px;
      }
    }

    .css-26l3qy-menu,
    .custom-select__menu {
      left: 0px;
      width: 100%;
      max-height: 300px;
      z-index: 999;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
      border: solid 1px #d2d9e7;
      background-color: #e6e7ee;
      margin-bottom: 0;

      .custom-select__menu-list {
        color: #435b75;
        cursor: pointer;
        max-height: 290px;

        .css-1n7v3ny-option,
        .css-9gakcf-option {
          background-color: transparent;
          color: #435b75;
          padding: 10px 10px;
          cursor: pointer;

        }

        .css-yt9ioa-option {
          padding: 10px;
        }

        .css-1n7v3ny-option,
        .css-9gakcf-option :hover {
          box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
          border-color: #d2dae6;
          padding: 10px;
          border-radius: 6px;
          background-color: transparent;
        }

      }
    }
  }



  @media (max-width: 767px) {
    &.tabContent {
      padding: 21px 15px 21px 15px;
    }

    .rightBtnSpace {
      >.BSrow {
        flex-direction: column;

        [class*="col-"] {
          margin-bottom: 5px;
          max-width: 100%;
          flex: 0 0 100%;
        }

        >[class*="col-"] {
          margin-bottom: 5px;
        }



      }

      .pickUpPonint {
        padding-bottom: 29px;
      }

      .pickUpPonint,
      .dropOfPoint {
        width: 100%;
        padding-bottom: 0;

        >.inputStyle {
          margin-bottom: 0px;
        }
      }



      .showDropdown {
        .inputDropdown {
          box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
          -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
          background-image: linear-gradient(to right, #ececf3, #e6e7ee);
          border: solid 1px #d2dae6;
        }
      }


    }

    .timeWrap {
      [class^="icon-"] {
        left: 12px;
      }

      input {
        padding-left: 36px;
      }

      .fa {
        display: none;
      }
    }

    .dateWrap {
      [class^="icon-"] {
        left: 13px;
      }

      input {
        padding-left: 35px;
      }
    }

    @include showDropdown();
  }

  @media (max-width: 991px) {
    .advancedSettings {
      color: var(--text-color3);
      display: flex;
      padding-bottom: 0px;

      span {
        order: 1;
        padding-left: 6px;
      }

      .fa {
        left: -2px;
      }

      &.settingON {
        .fa {
          top: 1px;
          left: 0px;
        }
      }
    }

    .advancedSettingsBox {
      margin-top: 0px;

      >.BSrow {
        margin-top: 15px;

        // flex-direction: column;
        [class*="col-"] {
          margin-bottom: 21px;
          max-width: 50%;
          flex: 0 0 50%;
        }
      }
    }

    .searchBtnWrap {
      padding-top: 7px;
    }
  }

  @media (min-width: 768px) {

    .rightBtnSpace {
      padding-right: 171px;
    }

    .advancedSettings {
      position: absolute;
      bottom: -40px;
      font-size: 16px;
      left: 0;
      color: var(--white);

    }

    .advancedSettings.settingON {
      .fa {
        top: 2px;
        left: -2px;
      }
    }
  }


  @media (min-width: 991px) {
    .advancedSettings {
      font-size: 16px;
      position: absolute;
      bottom: -43px;
      left: 0;
      color: var(--white);
    }
  }
}

// cars tab style END
.rbt {
  .rbt-input {
    padding-right: 30px !important;
  }

  .rbt-aux {
    .btn-close {
      position: absolute;
      right: 8px;
      border: none;
      top: 20px;
      z-index: 2;
      background-color: transparent;
      font-size: 30px;
      line-height: 0;
    }
  }
}

//Responsive style start
@media (max-width:1260px) {
  .landingPage {
    .landing-mainContent {
      width: 99.5%;
      margin-right: 10px;

      .centerCnt {
        padding-right: 0px;
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
}


@media(min-width:1400px) {
  .dropdownInWrap {
    .dropDownSubWrap {
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      padding: 10px 15px 15px;
      margin-bottom: 5px;
      margin-right: 0px;
    }
  }
}

@media (min-width: 1200px) {
  #carsTab {
    .rightBtnSpace {
      padding-right: 171px;
    }

    .searchBtnWrap {
      position: absolute;
      right: 30px;
      bottom: 31px;
    }
  }

}

@media (max-width:1200px) {
  #carsTab {

    .startEndDate {
      .DateInput {
        width: auto;

        input {
          width: 100%;
          min-width: auto;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

    }
  }
}

@media (max-width: 1199px) {
  .widget-page-wrap {
    .tabContent {
      padding: 20px 20px 31px;
    }
  }
}

@media (max-width: 1024px) {
  .widget-page-wrap {
    .searchBtnWrap {

      .btn {
        min-width: 150px;
      }
    }
  }

  .Date-picker-range {
    .DateRangePickerInput {
      margin-right: 10px;
    }
  }

  .searchBtnWrap {
    margin-left: 0px;
  }
}

@media (min-width: 992px) {

  // pageInternal scroll
  .landingPage.landingPageWrap {
    .pageInternalScroll {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 103px) !important;
    }
  }
}

@media (min-width: 768px) {
  #flightsTab.flight-tab-wrap {
    .ant-select-dropdown {
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #cfd1e1;
      background-color: #e6e7ee;
      position: absolute;
      top: 50px;
      left: 0;
      box-sizing: border-box;
      padding: 6px;
      min-width: 420px;
      max-height: 30vh;
      overflow: auto;
      z-index: 3;
      top: auto !important;
      bottom: 0;

    }

    .multiCityTab {
      position: relative;

      .addFlight {
        color: var(--white);
        position: absolute;
        font-size: 17px;
        bottom: -76px;
        left: -25px;
      }
    }
  }

  .listingFlight #flightsTab.flight-tab-wrap {
    .ant-select-dropdown {
      top: 40px !important;
      bottom: auto;
    }
  }

  .widget-page-wrap {
    #carsTab {
      .startEndDate {
        .SingleDatePicker_picker {
          top: auto !important;
          bottom: 36px !important;
        }
      }
    }

    .timeWrap,
    .advancedSettingsBox {
      .btnSml.css-b62m3t-container .custom-select__menu {
        top: auto;
        bottom: 42px;
      }
    }
  }
}

// 768 end
@media(max-width:991px) {
  .dropdownInWrap {
    .form-row {
      .add-room-scroll {
        >div {
          margin-right: -5px !important;
        }
      }
    }
  }

  .dropdownInWrap {
    .dropDownSubWrap {
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      padding: 10px 15px 15px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }

  .landingPage {
    .landing-mainContent {
      width: 99.4%;
      margin-right: 0px;
      padding-bottom: 0px;

      .centerCnt {
        padding-right: 15px;
      }
    }
  }
}

@media (max-width: 991px) {
  .Date-picker-range {
    .DateRangePickerInput {
      margin: 18px 0px;
    }
  }

  .widget-page-wrap {
    padding: 0px 0px 0px 15px;

    .inputStyle .widget-dropdown {
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      box-sizing: border-box;
    }

    .centerAlign {
      display: block;

      img {
        margin-bottom: 48px;
        margin-top: 17px;
      }
    }

    #flightsTab {
      .startEndDate {
        .SingleDatePicker_picker {
          left: -125px !important;
          top: 50px !important;
        }
      }
    }

    #carsTab {
      .startEndDate {
        .SingleDatePicker_picker {
          left: 16px;
          top: 40px;
        }
      }
    }

  }

  .boxStyle #apartmentsTab {
    .showDropdown {
      .icon-location-on {
        top: 23px;
        left: 15px;
        font-size: 18px;
      }
    }

    .dorpdown-content {
      .icon-location-on {
        top: 20px;
        left: 5px;
        font-size: 16px;
      }
    }

    .filterSecondRow {
      flex-direction: row;
      flex: 100%;
      justify-content: space-between;
      padding-top: 10px;

      .inputStyle {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
        font-weight: 400;
        display: flex;
        text-align: left;
        display: block;

        .dropdown-content {
          width: 100%;
        }
      }

      .Date-picker-range {
        margin-right: 10px;

        .DateRangePickerInput {
          margin: 0px;
          width: 100%;
          justify-content: flex-start;

          .DateRangePickerInput_arrow {
            width: 70px;
            margin-right: 0px;
            margin-left: 0px;
            margin-top: -5px;

            .endDate {
              padding-left: 9px;
            }

            .icon-arrow {

              margin-left: 13px;
            }
          }
        }
      }

      .searchBtnWrap .btn {
        min-width: 150px;
      }
    }
  }
}

@media (max-width: 991px) {
  .centerCnt .boxStyle {
    min-height: 248px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #carsTab {
    .rightBtnSpace {
      padding-right: 185px;
    }
  }
}

@media (max-width: 991px) {
  #flightsTab {
    .btnGroup {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 0px;

      .btnSml {
        width: 30.5%;
        min-width: auto;
        margin-right: 0;
      }
    }

    .flatDropdown .custom-select__control {
      .custom-select__indicators {
        padding: 0px 0px !important;

        .css-tj5bde-Svg {
          width: 21px;
          color: #1d6ec9;
        }
      }
    }

    .roomNpersonWrap {
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
    }

    .flatDropdown {
      margin-left: 0px;

      .btnSml.width180 {
        margin-right: 0px;
        margin-left: 0px;
        min-width: 114px;
        width: 114px;
        white-space: nowrap;
      }
    }

    .flight-class {
      .btnSml.width180 {
        margin-right: 0px;
        margin-left: 13px;
      }
    }

    .flightCnt {
      .searchBtnWrap {
        margin-top: 15px;
        padding-top: 0px;
      }

      .filterSecondRow {
        padding-top: 10px !important;
      }

      .flex-input-wrapper {
        min-width: auto;
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;

        .roomNpersonWrap {
          margin: 0px;

          .btnSml.width180 {
            margin-right: 0 !important;
            width: 100px;
            padding: 7px;
            white-space: nowrap;
          }
        }

        .select-input-dropdown {
          margin-left: 5px;

          .btnSml.width180 {
            margin-right: 0 !important;
            max-width: 120px;
            white-space: nowrap;
          }
        }
      }

      .startNendWrap {
        display: flex;
        flex: 50%;
        align-items: center;
        margin-right: 10px;


      }

      .startEndDate {
        display: flex;
        font-size: 17px;
        max-width: 240px;
        width: 100%;
        margin: 0px 0px 0px 0px !important;
        align-items: center;

        .SingleDatePicker {
          .SingleDatePickerInput_calendarIcon {
            padding: 0px;
            margin: 0px;
            vertical-align: middle;
          }
        }
      }

      .Date-picker-range .DateRangePickerInput {
        margin: 0px 0px;
        justify-content: space-between;
      }
    }

    .flexStart {
      .flightType {
        .btnSml {
          margin-right: 9px;
        }
      }
    }
  }

  #oneWay,
  #rounttripBox {
    .inputStyle {
      max-width: 100% !important;
      width: 100%;
    }

    .startNendWrap {
      margin-left: 0px;
    }
  }

  .landingPage .navigation ul a,
  .landingPage .navigation ul a [class^=icon-]:before {
    color: var(--text-color3);
  }
}

@media (max-width: 767px) {

  .widget-page-wrap {
    .mainContent {
      padding-bottom: 70px;
    }
  }

  // Hotel-tab mobilepopup
  #apartmentsTab {
    .showDropdown {
      .mobilePopup {
        display: block;
        // visibility: hidden;
        overflow: hidden;
        z-index: 999;

        .inputPopTitle {
          font-size: 28px;
          font-weight: 300;
          color: var(--text-color);
          display: block;
          padding-right: 45px;
          margin-bottom: 32px;
        }

        .dropdown-content {
          position: relative;
          margin: 0 0px;
        }

        .widget-dropdown {
          width: 100%;
          padding-left: 35px;
          border: none;
          box-shadow: none;
          background: none;
          border-radius: 0px 0px;
          border-bottom: 2px solid var(--border-color);
        }

        .mobilePopup {
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background: var(--bg-color);
          padding: 50px 29px;
          z-index: 999;
          overflow: hidden;
        }
      }

      .date-picker-popup {
        padding: 40px 0px;
      }

      .date-picker-popup {
        .Date-picker-range {
          .DateRangePicker .DateRangePickerInput {
            .DateInput {
              margin: 9px 0px 0px 0px;
              margin-left: -0px;

              #check_out {
                margin-right: 0px;
              }
            }

            .DateRangePickerInput_calendarIcon {
              margin-top: 3px;
              margin-left: 7px;
            }

            .DateRangePickerInput_arrow {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .filterSecondRow .inputStyle {
      min-height: 45px;
    }

    .date-picker-type .date-picker-style {
      padding: 0 14px;
      height: 45px;

    }
  }

  // Hotel-tab mobilepopup end

  .boxStyle #apartmentsTab {
    .showDropdown {
      .icon-location-on {
        top: 22px;
        left: 4px;
        font-size: 20px;
      }



      .dorpdown-content {
        .icon-location-on {
          top: 21px;
          left: 10px;
          font-size: 16px;


        }

      }
    }

    .filterSecondRow {
      flex-direction: column;
      padding-top: 22px;
      margin-bottom: 0px;
    }
  }

  .Date-picker-range {
    margin-top: 40px;

    .DateRangePickerInput {
      margin: 18px 0px;

      .DateRangePickerInput_calendarIcon {
        margin-right: 0px;
        vertical-align: top;
        margin-top: 0px;
        padding: 5px;
      }

      .DateRangePickerInput_arrow {
        width: 0px;
        display: inline-block;
        vertical-align: middle;
        color: #484848;
        margin-top: -3px;
      }
    }
  }

  .widget-page-wrap {
    justify-content: flex-start;

    .tabContent {
      padding: 15px 15px 20px;
    }

    .dropdown-menu {
      position: absolute;
      border-radius: none;
      box-shadow: none;
      border: none;
      background-color: none;
      transform: translate3d(0px, 45px, 0px);
      width: 102% !important;
      display: block;
      max-height: 300px;
      padding: 5px 0px;
      overflow: auto;
      margin-left: -7px !important;

      .disabled {
        width: 100%;
        display: block;
        padding: 11px 8px;
        font-size: 17px;
        color: var(--text-color);
        border-radius: 6px;
        border: solid 1px transparent;
        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
          inset -2px -2px 6px 0 #ffffff;
        border-color: #d2dae6;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      }

      .dorpdown-content {
        align-items: center;
        color: var(--text-color);
        display: flex;
        font-size: 17px;
        width: 100%;
        padding: 11px 36px;
        border-radius: 6px;
        border: solid 1px transparent;
        margin-left: 0px;



      }


      .dorpdown-content .locationName {
        padding-left: 25px;
      }

    }

    .centerAlign img {
      margin-bottom: 48px;
      margin-top: 0px;
    }

    .tabContentWrap {
      .Date-picker-range {
        .DateRangePicker_picker.DateRangePicker_picker {
          // top: 47px !important;
        }
      }
    }

    .input-for-mobile .responsive-input .inputStyle {
      // border: none;
      min-height: 0px;
    }
  }


  // cars-mobile-view
  #carsTab {

    .rightBtnSpace {
      .flex-wrapper {
        display: flex;
        flex-direction: column;
      }

      .flex-col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .mobilePopup {
      .dropdown-content {
        position: relative;

        .inputDropdown {
          margin: 0px;
          border-radius: 0px;
          border-color: transparent;
          border-bottom: 2px solid #1d6ec9;
          box-shadow: none;
          background-image: none;
          background-color: transparent;
        }
      }

    }

    .btnSml.css-b62m3t-container {


      .css-1s2u09g-control,
      .css-1pahdxg-control {
        border-radius: 10px;
        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
        position: relative;
        padding: 0px 6px 0px 16px;
        color: var(--text-color3);
        border-color: transparent;
        cursor: pointer;
        white-space: nowrap;
        min-height: 45px;
        border: solid 1px #d2dae6;
      }




    }

    .timeWrap {
      .btnSml.css-b62m3t-container {
        .custom-select__control {
          min-width: 50%;
          width: 100%;
          padding: 0px 0px 0px 32px;

          .custom-select__input-container {
            width: 100%;
          }
        }
      }
    }

    .startEndDate {
      width: 100%;
      min-width: 100%;
      border-radius: 10px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      border-color: transparent;
      min-height: 45px;
      padding: 9px 5px;
      border: solid 1px #d2dae6;

      .SingleDatePicker {
        padding: 0px 8px;

        .DateInput {
          margin-left: 0px;
          width: auto;

          input {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 1px;
            margin-top: 3px;
          }
        }
      }
    }

    .rightBtnSpace {
      .BSrow {


        >[class*=col-] {
          margin-bottom: 19px;
          flex: 0 0 50%;
          width: 50%;
          max-width: 50%;

        }





        .date-picker-type {
          .date-picker-style {
            -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            background: transparent;
            margin-bottom: 19px;

            button {
              line-height: 20px;
            }
          }
        }

      }
    }



    .inputStyle {
      input {
        padding-right: 23px;

      }
    }


  }
}


@media (max-width: 767px) {
  .date-picker-type {
    display: block;
    margin-bottom: 0px;
    width: 100%;

    .custom-date-range {
      color: #1d6ec9;
      margin-bottom: 20px;
      text-align: center;

      p {
        color: inherit;
        font-size: 16px;
        font-weight: 400;

        @media(max-width:374px) {
          font-size: 13px;
        }
      }
    }

    .date-picker-style {
      border-radius: 10px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      width: 100%;
      padding: 12px 13px;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
      cursor: pointer;

      button {
        border: none;
        background: transparent;
        text-align: left;
        font-size: 17px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }

      .form-group {
        display: flex;
      }

      .icon-invitation {
        margin-right: 8px;
        margin-top: 0px;
        font-size: 17px;
        vertical-align: text-top;
      }
    }

    .SingleDatePickerInput__withBorder,
    .SingleDatePickerInput {
      border: none;
      background-color: #e8e8ef;
    }

    .DateInput {
      background-color: #e8e8ef;

      .DateInput_input {
        padding: 0px 0px 0px 0px;
        line-height: 0px;
      }
    }
  }

  .input-for-mobile {
    .responsive-input .inputStyle {
      border-radius: 10px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      width: 100%;
      padding: 12px 15px 10px 40px;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 400;
      cursor: pointer;
    }
  }


}


@media (max-width: 767px) {
  .widget-page-wrap .tabContentWrap {
    .dropdownWrap {
      .btnSml {
        width: 100%;
        margin-bottom: 10px;
      }

      .adultdropdown-wrapper {
        display: flex;
        flex: 0 0 100%;

        button {
          margin-right: 9px;
          flex: 0 0 49%;
          width: 49%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .Date-picker-range {
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    padding: 0px 0px;

    .DateRangePicker {
      min-width: 300px;
      margin: 0px 0px;
    }

    .DateRangePickerInput {
      font-size: 17px;
      color: #1d6ec9;
      border-radius: 0px;
      box-shadow: none;
      border: none;
      border-bottom: 2px solid #4385d0;
      background-image: none;
      padding: 0px 0px;
      margin: 10px 0px;
      justify-content: flex-start;
      align-items: center;

      .DateInput {
        height: 0px;
        display: flex;
        width: 100px;
        flex: 0 0 100px;
        padding: 0px 5px;
        margin: 0px;

        input {
          padding: 0px 0px 0px 10px;
          font-size: 17px;
          color: #1d6ec9;
          border-radius: 0px;
          box-shadow: none;
          -webkit-box-shadow: none;
          background-image: none;
          min-width: 0px;
          vertical-align: middle;
          min-height: 0px;
        }
      }
    }
  }

  .widget-page-wrap .mainContent {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  #flightsTab {
    position: relative;

    .btnGroup {
      margin-right: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 17px;

      .btnSml {
        width: 31%;
        text-align: center;
        margin-right: 0px !important;
        min-width: auto !important;
        box-sizing: border-box;
      }
    }

    .flightCnt {
      .startNendWrap {
        display: flex;
        flex: 50% 1;
        align-items: center;
        margin-left: 0px;
      }
    }

    .mobileShow .dropdownWrap {
      display: flex;
      margin-left: 0px;
      width: 100%;
      margin: 0px;
      justify-content: space-between;

      .btnSml {
        flex: 0 0 49%;
        min-width: auto;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
      }
    }

    .roomNpersonWrap .dropdownInWrap {
      width: 100%;
      padding: 0px 14px 25px 14px;
      overflow: hidden;

      .mobileFullWidthPopupContent {
        overflow-y: hidden;

        .popupHeader {
          width: 100%;
          text-align: center;
          margin: 21px 0px;

          h4 {
            font-size: 23px;
            font-weight: normal;
            color: var(--text-color);
            display: block;
          }

          .popupCloseBtn {
            position: absolute;
            right: 0px;
            top: 0px;
            padding: 15px;
            border: none;
            background: none;

            .icon-close:before {
              font-size: 17px;
              color: #1d6ec9;
            }
          }
        }

        .dropdownInMain {
          .dropdownWrap>div {
            width: 100%;
            padding: 0px 0px;

            .btnSml {
              width: 100%;
              min-height: 45px;
              font-size: 17px;
              padding: 0px;
              line-height: 1;
              border-radius: 10px;
              box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%),
                2px 2px 3px 0 rgb(0 0 0 / 15%);
              border: solid 1px #d2dae6;
              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              margin: 0px;

              .custom-select__control {
                min-width: 100%;
                flex: 0 0 100%;
                padding: 0px;
                border: none;
                cursor: pointer;
                box-shadow: none;
                background: transparent;

                &:hover {

                  .custom-select__single-value,
                  .custom-select__value-container .custom-select__input-container,
                  .custom-select__indicator .fa-angle-down * {
                    color: rgb(0, 0, 0);
                    fill: rgb(0, 0, 0);
                  }
                }

                .custom-select__indicators,
                .custom-select__indicator {
                  padding: 0px 6px;

                  .fa-angle-down {
                    width: 21px;
                    color: #1d6ec9;
                  }
                }

                .custom-select__single-value,
                .custom-select__input-container {
                  color: #1d6ec9;
                  height: 20px;
                  padding-left: 2px;
                }


                .css-tlfecz-indicatorContainer {
                  color: #1d6ec9;
                }
              }

              .custom-select__menu {
                left: 0px;
                width: 100%;
              }
            }
          }

          .dropDownSubWrap {
            padding: 0px;
            border-radius: 8px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
              inset -2px -2px 6px 0 #ffffff;
            border: solid 1px #d2dae6;
            padding: 4px 5px 10px;
            margin-bottom: 10px;


            .inWrap {
              padding: 10px;
            }
          }
        }
      }

      .dropdownBtnWrap {
        justify-content: center;
      }
    }
  }
}

// single date picker mobile view
@media (max-width:767px) {

  #flightsTab,
  #carsTab {

    // Date-picker style
    .locationAndDateWrap {
      .date-picker-popup {
        .Date-picker-range {
          justify-content: center;
          text-align: center;
          margin-top: 0px;
          padding: 0px 0px;

          .DateRangePicker {
            width: 300px;
            min-width: 300px;
            margin: 0px 0px;

            .DateRangePickerInput {
              justify-content: center;

              .DateRangePickerInput>div {
                width: auto;
                max-width: auto;
              }

              .DateInput {
                margin: 9px 0px 5px 0px;
                margin-left: -5px;

                .DateInput_input {
                  margin-left: 0px;
                  border: none;
                  box-shadow: none;
                  padding: 0px;
                  background: transparent;
                }
              }

              .DateRangePickerInput_arrow {
                margin-left: 5px !important;
                margin-right: 0px;
                margin-top: 3px;
                width: 60px;
              }

              .DateRangePickerInput_calendarIcon {
                margin-right: 5px !important;
                padding: 0px 0px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    // Date-picker style End

    // Single-datepicker style

    .date-picker-popup {
      padding: 40px 0px;

      .inputPopTitle {
        font-size: 28px;
        font-weight: 300;
        color: var(--text-color);
        display: block;
        padding-left: 29px;
        padding-right: 0px;
        margin-bottom: 20px;
      }

      .SingleDatePicker {
        display: flex;
        text-align: center;
        width: 100%;

        >div {
          width: 100%;
        }

        .SingleDatePickerInput {
          position: relative;
          max-width: 300px;
          margin: 0 auto;

          .SingleDatePickerInput_calendarIcon {
            position: absolute;
            z-index: 1;
            margin-right: 0px;
            left: 70px;
            top: 7px;
            padding: 10px;
            margin: 0px;
          }

          .DateInput {
            width: 100%;

            input {
              border: none;
              text-align: center;
              border-radius: 0px;
              background: none;
              box-shadow: none;
              padding: 12px 30px 0px 30px;
              border: none;
              border-bottom: 2px solid #4385d0;
            }
          }
        }
      }

      .SingleDatePicker_picker {
        left: 0px !important;
        width: 100%;
        border: none;
        box-shadow: none;

        .DayPicker_transitionContainer,
        .DayPicker__withBorder {
          box-shadow: none;
          border: none;
          min-height: 350px;
          width: 310px !important;
          background: transparent;
          border-radius: 0px;
          margin-left: -7px;

        }

        .DayPicker__withBorder>div>div {
          width: 300px !important;
          border-radius: 0px;
          margin: 0 3px;
        }

        .CalendarMonth_table {
          width: 300px !important;
          margin-left: 6px;

          .CalendarDay {
            font-size: 17px;
            border-width: 0;
            height: 42px !important;
            width: 45px !important;
          }
        }

        .DayPicker_weekHeaders__horizontal {
          margin-left: 0px;
        }
      }
    }
  }
}


// Flight-tab mobile popup

@media (max-width: 767px) {
  .closePopup {
    border: none;
    background: transparent;

    .icon-close {
      font-size: 15px;
    }
  }

  #flightsTab {

    // flight-tab-mobilePopup-styling
    .mobilePopup {
      overflow: hidden;

      .inputInWrap {
        .rbt-input-hint {
          min-width: 100%;
          background: none;
        }

        .icon-location-on {
          left: 4px !important;
        }
      }

      .dorpdown-content {
        .icon-location-on {
          left: 10px !important;
        }
      }

      .widget-dropdown {
        width: 100%;
        background-color: #4385d0;
        border: none;
        box-shadow: none;
        min-height: 40px;
        background: none;
        border-radius: 0px 0px;
        margin: 0px 0px;
        border-bottom: 2px solid var(--border-color);
      }
    }

    // flight-tab-mobilePopup-styling end

    // Flight-tab-style

    .flightTypeCnt {

      // flight-tab-Add-Remove-button-style
      .flightCnt {
        .searchBtnWrap {
          text-align: center;
          padding-top: 20px;
          margin-top: 0px;

          .btn {
            min-width: 150px;
          }
        }

        .addremoveBtn {
          display: flex;

          .addFlight,
          .removeFlight {
            position: static;
            flex: 0 0 50%;
            font-size: 17px !important;
            width: 100%;
            text-align: left;
            color: var(--text-color3);
            padding: 0px 0 22px 5px !important;

            .icon-minus:before {
              color: var(--text-color3);
              font-weight: bold;
              font-weight: 20px;
            }
          }
        }

        .locationAndDateWrap>div {
          width: 100%;
          max-width: 100%;
        }

        .locationAndDateWrap {
          .date-picker-type {
            width: 100%;

            .datepicker-types {
              display: flex;
              width: 100%;
              flex-wrap: nowrap;
              justify-content: space-between;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              .date-types-1 {
                flex: 0 0 44%;
              }

              .date-types-2 {
                text-align: center;

                .icon-arrow {
                  margin-left: 5px;
                }
              }

              .date-types-3 {
                flex: 0 0 44%;
                text-align: right;
              }
            }
          }
        }
      }

      // Single-datepicker style End
    }
  }

}


@media(max-width:767px) {

  // Single-datepicker style
  #flightsTab,
  .listingCars {
    .locationAndDateWrap {
      .date-picker-popup {
        padding: 40px 0px;

        .inputPopTitle {
          font-size: 28px;
          font-weight: 300;
          color: var(--text-color);
          display: block;
          padding-left: 29px;
          padding-right: 0px;
          margin-bottom: 20px;
        }

        .SingleDatePicker {
          display: flex;
          text-align: center;
          width: 100%;

          >div {
            width: 100%;
          }

          .SingleDatePickerInput {
            position: relative;
            max-width: 300px;

            .SingleDatePickerInput_calendarIcon {
              position: absolute;
              z-index: 1;
              margin-right: 0px;
              left: 70px;
              top: 7px;
              padding: 10px;
            }

            .DateInput {
              width: 100%;

              input {
                border: none;
                text-align: center;
                border-radius: 0px;
                background: none;
                box-shadow: none;
                padding: 12px 20px 0px 30px;
                border: none;
                border-bottom: 2px solid #4385d0;
              }
            }
          }
        }

        .SingleDatePicker_picker {
          left: 0px !important;
          width: 100%;
          border: none;
          box-shadow: none;

          .DayPicker_transitionContainer,
          .DayPicker__withBorder {
            box-shadow: none;
            border: none;
            min-height: 350px;
            width: 300px !important;
            background: transparent;
            border-radius: 0px;
            margin-left: -5px;

          }

          .DayPicker__withBorder>div>div {
            width: 300px !important;
            border-radius: 0px;
            margin: 0 auto;
          }

          .CalendarMonth_table {
            width: 300px !important;
            margin-left: 6px;

            .CalendarDay {
              font-size: 17px;
              border-width: 0;
              height: 42px !important;
              width: 45px !important;
            }
          }

          .DayPicker_weekHeaders__horizontal {
            margin-left: 0px;
          }
        }
      }
    }

    // Date-picker style
    .locationAndDateWrap {
      .date-picker-popup {
        .Date-picker-range {
          justify-content: center;
          text-align: center;
          margin-top: 0px;
          padding: 0px 0px;

          .DateRangePicker {
            width: 300px;
            min-width: 300px;
            margin: 0px 0px;

            .DateRangePickerInput {
              justify-content: center;

              .DateRangePickerInput>div {
                width: auto;
                max-width: auto;
              }

              .DateInput {
                margin: 9px 0px 5px 0px;
                margin-left: -5px;

                .DateInput_input {
                  margin-left: 0px;
                  border: none;
                  box-shadow: none;
                  padding: 0px;
                  background: transparent;
                }
              }

              .DateRangePickerInput_arrow {
                margin-left: 5px !important;
                margin-right: 0px;
                margin-top: 3px;
                width: 60px;
              }

              .DateRangePickerInput_calendarIcon {
                margin-right: 5px !important;
                padding: 0px 0px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }


  }

}

// Date-picker style End
@media (max-width: 767px) {
  .landingPage {
    .landing-mainContent {
      overflow-y: hidden;
      position: relative;
      top: 0px;
      width: 100%;
      padding-bottom: 0px;

      .centerCnt {
        min-height: calc(100%);
        padding-right: 15px !important;

      }
    }
  }

  .centerCnt {
    .boxStyle {

      margin-bottom: 70px;
    }
  }

  .widget-page-wrap {
    .footCopy {
      margin-left: -15px;
    }
  }

  .landingPage.landingPageWrap {
    .pageInternalScroll {
      overflow: scroll !important;

    }
  }

}

@media (max-width: 767px) {
  #carsTab {
    .input-for-mobile .responsive-input .inputStyle {
      // border: none;
      min-height: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-align: left;
      min-height: 45px;
    }

    .advancedSettings {
      padding-bottom: 23px;


      span {
        order: 1;
        padding-left: 6px;
      }
    }

    .advancedSettingsBox {
      div {
        .BSrow {
          flex-direction: column;
          margin-top: 0px;

          .col-3 {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            margin-bottom: 19px;
          }
        }
      }

      .btnSml.css-b62m3t-container .css-319lph-ValueContainer .custom-select__single-value {
        font-size: 17px;
        color: #1d6ec9;
      }
    }

    .SingleDatePickerInput {
      display: flex;

      .SingleDatePickerInput_calendarIcon {
        margin-top: 3px;
      }

    }

    .showDropdown {
      .mobilePopup {
        .icon-location-on {
          top: 46% !important;
          left: 4px;
          font-size: 20px;
        }

        .dropdown-menu {
          .icon-location-on {
            font-size: 16px;
          }
        }

      }
    }
  }
}

@media (min-width: 768px) {
  #carsTab {
    .searchBtnWrap {
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
  }
}

@media (max-width: 579px) {
  #flightsTab {
    .roomNpersonWrap .dropdownInWrap {
      left: 0;
      right: 0;
      width: 100%;
      min-width: 100%;
    }
  }
}

@media (max-width: 480px) {
  #apartmentsTab .showDropdown .mobilePopup {
    .widget-dropdown {
      padding-left: 30px;
    }
  }
}

@media (max-width: 480px) {
  .widget-page-wrap {
    .dropdown-menu {
      position: absolute;
      min-width: 105%;
      max-height: 300px;
      padding: 10px 0px;
      margin-left: -10px;

      .dorpdown-content .locationName {
        padding-left: 25px;
      }
    }
  }

  .boxStyle {

    #apartmentsTab,
    #carsTab {
      .showDropdown {
        .icon-location-on {
          top: 22px;

        }

        .icon-location-on:before {
          font-size: 18px;
        }

        .dorpdown-content {
          .icon-location-on {
            top: 20px;
            font-size: 15px;
            left: 11px;
          }

          .icon-location-on:before {
            font-size: 16px;
          }
        }
      }
    }
  }

  #flightsTab {
    .mobilePopup {
      overflow: hidden;

      .inputInWrap {
        .rbt-input-hint {
          min-width: 100%;
          background: none;
        }

        .icon-location-on {
          left: 9px !important;
        }

      }



      .dorpdown-content {
        .icon-location-on {
          left: 15px !important;
        }

        .locationCode {
          margin-left: 7px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  #flightsTab .flightTypeCnt {
    .flightCnt {
      .addremoveBtn {

        .addFlight,
        .removeFlight {
          font-size: 14px !important;
        }

        .icon-add {
          font-size: 12px !important;
        }

        .icon-minus:before {
          color: var(--text-color3);
          font-weight: bold;
          font-weight: 20px;
        }
      }
    }
  }
}



@media (max-width: 450px) {
  .boxStyle #apartmentsTab {
    .filterSecondRow {
      .inputStyle {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;

      }

    }
  }
}

@media (max-width: 374px) {
  .widget-page-wrap {
    .tabContentWrap {
      .dropdownWrap {
        .btnSml {
          width: 100%;
          margin-bottom: 10px;
        }

        .adultdropdown-wrapper {
          display: flex;
          flex: 0 0 100%;

          button {
            margin-right: 5px;
            flex: 0 0 49%;
            width: 49%;
          }
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      min-width: 105%;
      max-height: 300px;
      margin-left: -10px;

      .dorpdown-content .locationName {
        padding-left: 22px;
      }
    }
  }

  #flightsTab {
    .flexStart .flightType {
      .btnSml {
        padding: 6px;
        margin-right: 11px;
        min-width: auto;
      }
    }

    .mobileShow {
      .dropdownWrap .btnSml {
        flex: 0 0 47%;
        min-width: auto;
        margin-right: 0px;
      }
    }
  }

  .boxStyle {

    #apartmentsTab,
    .carsTab {
      .showDropdown {
        .icon-location-on {
          top: 22px;
          left: 3px;
        }

        .dorpdown-content {
          .icon-location-on {
            top: 20px;
            left: 10px;
          }
        }
      }

    }
  }

  #carsTab {
    .rightBtnSpace {
      .pickup-drop-wrap {
        flex-direction: column;

        .dropOfPoint {
          padding-left: 0px;
        }
      }

      .BSrow {
        flex-direction: column;

        .col-6,
        .col-3 {
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%;
          margin-bottom: 0px;

        }
      }

      .startEndDate {
        margin-bottom: 19px;

        .DateInput {
          input {
            padding-left: 0px;
          }
        }
      }

      .pickup-drop-wrap {
        .col-6 {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 0;

        }

        .input-pickup {
          margin-bottom: 19px;
        }
      }

    }

    .SingleDatePicker {
      .SingleDatePickerInput_calendarIcon {

        margin-top: 3px;

      }
    }

    .timeWrap {
      .btnSml.css-b62m3t-container {
        .custom-select__control {
          min-width: 50%;
          width: 100%;
          padding: 0px 0px 0px 32px;
          margin-bottom: 19px;
        }
      }
    }

    .advancedSettings {
      padding-bottom: 23px;
      padding-top: 5px;
    }

  }

}

@media (min-width: 375px) and (max-width: 767px) {
  .child-age-flex {
    width: 100%;

    >div {
      display: flex;
      flex: 100%;
      flex-wrap: nowrap;

      label {
        flex: 0 0 70%;
        width: 70%;
      }

      .css-b62m3t-container {
        flex: 0 0 30%;
        width: 30%;
        justify-content: right;
      }
    }
  }
}

@media (max-width: 375px) {
  .Date-picker-range {
    .DateRangePickerInput {
      font-size: 16px;
      padding: 0px 0px;
      margin: 16px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;

      .DateInput {
        flex: 0 0 90px;
        margin: 0;
        width: 70px;
        padding: 0px !important;

        .DateInput_input {
          font-size: 17px;
          margin-left: -5px !important;
          border-bottom: 0;
          padding: 5px 0px 0px;
        }
      }

      .DateRangePickerInput_calendarIcon {
        margin-right: 10px;
        vertical-align: top;
        padding: 0px;
        margin-top: -2px;
        margin-left: 10px !important;
      }

      .DateRangePickerInput_arrow {
        display: inline-block;
        vertical-align: middle;
        color: #484848;
        margin-right: 4px !important;
        margin-top: -3px;
      }
    }
  }

  #flightsTab {
    .mobilePopup {
      overflow: hidden;

      .dorpdown-content {
        .locationCode {
          margin-left: 7px;
        }
      }

    }

    .locationAndDateWrap {
      .date-picker-type {
        width: 100%;

        .datepicker-types {
          .date-types-2 {
            text-align: center;

            .icon-arrow {
              margin-left: 0px !important;
            }
          }
        }
      }
    }
  }

  #flightsTab .flightTypeCnt {

    .locationAndDateWrap {
      .date-picker-popup {
        .Date-picker-range {
          .DateRangePicker {
            .DateRangePickerInput {
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}