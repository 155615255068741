.marginZero {
    margin-bottom: 0 !important;
}

.errorMessage {
    color: red;
    margin-bottom: 0;
    padding-top: 3px;
}

#flightsTab.flight-tab-wrap {
    padding: 20px 30px 31px;

    .multiCityFlightTab+.multiCityFlightTab {
        padding-top: 20px;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: transparent;
    }

    .inputPopTitle,
    .closePopup,
    .mobilePopup {
        display: block;
    }

    .removeBtnWrap {
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .removeRowBtn {
        background-image: linear-gradient(to right, #1377c4, #00ceda);
        border: 0;
        box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 20%), -3px -3px 6px 2px #ffffff;
        border-radius: 50%;
        color: #fff;
        width: 35px;
        height: 35px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-image: linear-gradient(to right, #00ceda -12%, #1377c4);
        }
    }

    .inputStyle {
        position: relative;

        input {
            padding: 8px 20px 8px 35px;
        }

        .icon-location-on {
            top: 23px;
        }
    }

    .ant-select-auto-complete {
        display: flex;
        width: 100%;

        .ant-select-selector {
            width: 100%;
        }
    }

    .ant-select-dropdown {
        display: none;

        .ant-select-item-group {
            display: none;
        }

        .ant-select-item {
            padding: 11px 8px;
            border-radius: 6px;
            border: solid 1px transparent;

            &:hover {
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                border-color: #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            }

            .flight-icon {
                svg {
                    color: var(--text-color3) !important;
                    transform: rotate(45deg);
                }
            }

            .child-ele {
                svg {
                    transform: none;
                    margin-top: 5px;
                }
            }

            .flight-content {

                h5,
                h6 {
                    font-weight: normal;
                }

                h5 {
                    color: var(--text-color3)
                }
            }
        }

        .ant-select-item-empty {
            border-radius: 6px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            border-color: #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            color: var(--text-color);
            font-size: 17px;
            display: block;
            padding: 11px 8px;
            border-radius: 6px;
            border: solid 1px transparent
        }
    }

    .ant-select-open {
        .ant-select-dropdown {
            display: block;
            overflow-y: hidden;

        }
    }

    .searchBtnWrap {
        .btn {
            box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 20%), -3px -3px 6px 2px #ffffff;

            &:hover {
                color: #fff;
            }
        }
    }

    .startEndDate {
        position: relative;

        .ant-picker-clear {
            display: none
        }

        .icon-arrow {
            margin: 9.5px 0 0 0;
            position: relative;
            left: -1.5px;
            padding-right: 15.5px;
            font-size: 11px;
        }

        .ant-picker-dropdown {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            position: absolute;
            top: -334px !important;
            left: 25px !important;
            z-index: 1050;
            border-bottom: none;

            .ant-picker-panel {
                padding: 0;
                border-radius: 10px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                box-shadow: 0px 0px 15px rgb(0 0 0 / 15%) !important;
                border-bottom: 0px !important;
                border-bottom: none !important;
                border: solid 1px #d2d9e7;
                background-color: #e6e7ee;
            }

            .ant-picker-panel-container {
                border-radius: 10px;
            }

            .ant-picker-header {
                display: flex;
                justify-content: space-between;
                border-bottom: none !important;

                button {
                    background-color: transparent;
                    color: #435b75;
                    border: 0;
                }

                .ant-picker-header-view {
                    button {
                        font-size: 17px;
                        padding: 0 5px;
                    }
                }
            }

            .ant-picker-cell-in-view.ant-picker-cell-today {
                .ant-picker-cell-inner::before {

                    border: none !important;

                }
            }

            .ant-picker-cell-disabled::before {
                height: 40px;
            }

            .ant-picker-cell {

                color: #435b75;
                font-size: 14px;
                text-align: center;

                .ant-picker-cell-inner {
                    min-width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: transparent;
                }
            }

            .ant-picker-month-panel,
            .ant-picker-year-panel {
                .ant-picker-cell {
                    width: 80px;

                    .ant-picker-cell-inner {
                        width: 100%;
                    }
                }

            }

            .ant-picker-cell-today .ant-picker-cell-inner::before {
                display: none;
            }

            .ant-picker-cell-selected {
                .ant-picker-cell-inner {
                    background-image: linear-gradient(to right, #3763d0 -12%, #00c4b2);
                    border-radius: 50%;
                    color: #fff;

                    &::before {
                        display: none;
                    }
                }
            }

            .ant-picker-content {
                border-collapse: collapse;
                border-spacing: 0;

                th {
                    font-size: 14px;
                    font-weight: normal;
                    color: #8696bc;
                    height: 34px;
                    line-height: 34px;
                }
            }
        }
    }

    .returnDateWrap,
    .dateInWrap {
        display: flex;
    }

    .startEndDate {
        border: solid 1px #d2dae6;
        width: 300px;
        padding: 7px 14px;

        .ant-picker {
            padding: 0;
        }

        input {
            padding: 0;
            line-height: 27px;
        }

        .icon-invitation {
            margin-top: 6px;
            margin-right: 8px;
        }
    }

    .travelerNclass {
        display: flex;
        justify-content: end;

        .custom-dropdown {
            box-sizing: border-box;
            width: 320px;
            padding: 5px 15px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #d2d9e7;
            background-color: #e6e7ee;
            position: absolute;
            bottom: 35px;
            z-index: 3;
            right: 0;
            left: auto;

            h6,
            h5,
            h3 {
                font-weight: normal;
                color: #435b75;
            }

            h3 {
                font-size: 17px;
                padding-top: 10px;
            }

            h5 {
                font-size: 14px;
            }

            h6 {
                font-size: 11px;
            }

            .popupCloseBtn {
                display: none;
            }

            .radio-btn-group {
                padding-top: 15px;

                .radio-btns {
                    font-size: 14px;
                    white-space: nowrap;
                    color: #435b75;
                    padding-bottom: 10px;
                    display: inline-flex;
                    align-items: center;
                    width: 50%;

                    input {
                        width: auto;
                        margin-right: 5px;
                    }
                }
            }

            .counter-btn-group {
                display: flex;
                justify-content: end;
                align-items: center;

                h6 {
                    width: 40px;
                    text-align: center;
                    color: #333333;
                    font-size: 17px;
                    font-weight: normal;
                }

                button {
                    width: 30px;
                    height: 30px;
                    box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                    border: solid 1px #d2dae6;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 20px;
                        height: 20px;
                        color: var(--text-color3);
                    }

                    &.btn-plus {
                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .buttons-group-content {
                padding-bottom: 10px;
            }

            .dropdown-row-wrap {
                padding-top: 15px;
            }
        }

        .toggle-btn {
            color: var(--text-color3);
            font-size: 14px;
            padding: 6px 10px 6px 13px;
            border-radius: 8px;
            box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            cursor: pointer;
            min-width: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            &:hover {
                color: #000;
            }

        }
    }

    .filterSecondRow {
        justify-content: left;
    }

    .searchBtnWrap {
        margin-left: auto;

        .btn::before {
            border-radius: 0;
            opacity: 1;
        }
    }

    @media (min-width: 1251px) {
        .inputStyle {
            max-width: 300px;
        }

        .multiCityTab {
            .inputStyle {
                min-width: 331px;
            }

            .startEndDate {
                width: 240px;
                min-width: 240px;
            }
        }
    }
}

.tabletShow,
.modalContent .close,
.landingPage #flightDropdownBtn {
    display: none;
}

.landingPage {
    .flight-types {
        flex-basis: content;
    }

    .errorMessage {
        position: absolute;
        top: 43px;
    }
}

.dropwDownSVGarrow {
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.filterCnt {
    #flightsTab.flight-tab-wrap {
        padding: 0;

        @media (min-width: 1251px) {
            .inputStyle input {
                width: 315px;
            }

            .multiCityTab .startEndDate {
                width: 270px;
                min-width: 270px;
            }
        }

        .startNendWrap {
            flex-grow: 1;
            align-items: flex-start;
        }

        .filterSecondRow {
            padding-top: 5px;
            align-items: flex-start;
        }

        .flight-types {
            position: relative;

            li {
                display: block;
                width: 100%;
            }

            #selectFlightOptions {
                position: absolute;
                left: 0;
                width: 250px;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2d9e7;
                background-color: #e6e7ee;
                z-index: 3;

                button.react-tabs__tab {
                    margin: 0;
                    width: 100%;

                    +button.react-tabs__tab {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        #flightDropdownBtn {
            color: var(--text-color3);
            font-size: 14px;
            background-color: transparent;
            padding: 0;
            border: 0;
            display: flex;
            align-items: center;
            line-height: 14px;
            height: 21px;
        }

        #flightDropdownBtn:hover {
            color: #000;
        }

        .startEndLocation .changeBtn,
        .inputStyle input {
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.4);
            box-shadow: none;

            &:focus {
                border-color: var(--text-color3);
            }
        }

        // .ant-picker-content,
        // .ant-picker-date-panel,
        // .ant-picker-panel {
        //     width: 100%;
        // }
        .inputStyle {
            max-width: 316px;

            input {
                padding: 5.5px 20px 5.5px 35px;
            }

            .icon-location-on {
                top: 18px;
            }
        }

        .row {
            margin: 0;

            >.col {
                padding: 0;
            }
        }

        .travelerNclass {
            .dropdown-wrap {
                display: flex;
                justify-content: end;
            }

            .toggle-btn {
                background: transparent;
                border: 0;
                box-shadow: none;
                min-width: auto;
                padding: 0;

                .dropwDownSVGarrow {
                    margin-left: 10px;
                }
            }
        }

        .startEndDate {
            padding: 2px 11px;
            box-shadow: none;
            background-image: none;
            border: 1px solid rgba(255, 255, 255, 0.1);
            margin-left: 11px;
            position: relative;

            .ant-space-item {
                top: 0 !important
            }

            .ant-picker-dropdown {
                left: 0 !important;
                top: 34px !important;
                // right: 0;
                margin-top: 2px;
            }
        }

        .searchBtnWrap {
            margin-left: 11px;

            .btn {
                background-image: none;
                box-shadow: none;
                border-radius: 10px;
                border: solid 1px var(--text-color3);
                background-color: rgba(239, 240, 245, 0.4);
                color: var(--text-color3);
                min-width: 100px;
                padding: 9px 10px;
                cursor: pointer;
                font-size: 14px;
                line-height: 14px;
                height: 35.5px;

                &:hover {
                    background-color: #fff;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    .openModal .listModallWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        &,
        .listModalOverlay {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            min-height: 100vh;
            min-width: 100vw;
            z-index: 999;
        }

        .listModalOverlay {
            backdrop-filter: blur(2px);
            background-color: rgba(67, 91, 117, 0.6);

        }

        .inputStyle {
            min-width: auto;


            input {
                width: 100% !important;
            }
        }

        .multiCityFlightTab {
            position: relative;
            padding-right: 0px;

            .startEndDate {
                width: 315px;
            }
        }



        .removeBtnWrap {
            position: absolute;
            right: 0;
        }

        .startEndDate,
        .changeBtn,
        .inputStyle input {
            border-color: #1d6ec9 !important;
        }

        .modalContent {
            position: relative;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
            border: solid 1px #cfd1e1;
            background-color: #eff0f5;
            z-index: 1000;
            width: 1034px;
            display: flex;
            flex-direction: column;
            padding: 15px;

            .close {
                margin: -20px -20px 0 auto;
                background-color: transparent;
                border: 0;
                padding: 0;
                width: 32px;
                min-width: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    color: #1d6ec9;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .multiCityFlightTab {
            padding-bottom: 20px;
        }

        .addFlightBtnWrap {
            display: flex;

            button {
                background-color: rgba(239, 240, 245, 0.4);
                color: var(--text-color3);
                border: solid 1px var(--text-color3);
                border-radius: 10px;
                padding: 9px 10px;
                font-size: 14px;
                height: 35px;
                line-height: 14px;
                margin-right: 10px;
                width: 100px;

                svg {
                    vertical-align: bottom;
                }
            }

            .searchFlightBtn {
                background-color: var(--text-color3);
                color: #fff;
                margin-left: auto;
                margin-right: 0;
            }
        }

        .searchBtnWrap {
            display: none;
        }

        .closeMultiCityFlightModal {
            display: block;
        }
    }
}

#selectFlightOptions {
    ul {
        list-style: none;
        margin-bottom: 0;

        li {
            display: inline-block;
        }
    }

    button.react-tabs__tab {
        box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
        border-radius: 8px;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
        color: var(--text-color3);
        padding: 6px;
        border: solid 1px #d2dae6;
        margin-right: 10px;
        min-width: 120px;
        font-size: 14px;

        &.react-tabs__tab--selected {
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        }

        &:hover {
            color: #000;
        }
    }
}

//Responsive Start

@media (min-width: 768px) and (max-width: 991px) {
    .listingPage.listingPageWrap .mainContent .filterCnt .flight-tab-wrap .inputStyle input {
        padding-left: 35px !important;
    }

    .landingPage {
        #flightsTab {
            .filterSecondRow {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .startNendWrap {
                min-width: 100%;
            }

            .startEndDate {
                flex-grow: 1;

            }

            .searchBtnWrap {
                margin-top: 18px;
                padding-left: 11px;
            }

            .startEndDate .icon-arrow {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                padding: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    .tabletShow {
        display: block;
    }



    .filterCnt #flightsTab.flight-tab-wrap {
        margin: 0 -6px 0 -6px;

        .searchBtnWrap {
            .btn {
                min-width: 116px;
            }
        }

        .mobileSearchBlock {
            width: 100%;

            .selectSearchCnt {
                .arrow {
                    img {
                        width: 20px;
                    }
                }
            }
        }

        #flightDropdownBtn:hover {
            color: #fff;
        }

        #flightDropdownBtn,
        .travelerNclass .toggle-btn {
            color: #fff;

            svg {
                color: #fff;
            }
        }

        .closeSearchFilter {
            background-color: transparent;
            border: 0;
            color: $white;
        }

        >.row {
            flex-direction: row;
            display: flex;
            align-items: center;

            .dropdownWrap {
                margin-bottom: 0;
            }
        }

        .startNendWrap,
        .filterSecondRow {
            flex-direction: column;
            width: 100%;
        }

        .inputStyle {
            max-width: none;

            input {
                padding-left: 35px !important;
            }
        }

        .startLocation {
            margin-bottom: 0 !important;
        }

        .startEndLocation .changeBtn {
            margin-left: auto;
            margin-top: -5px;
            margin-bottom: -5px;
            background: #98c1e4;
            position: relative;
            z-index: 1;
            border-color: #4d99d2;
        }

        .searchBtnWrap {
            margin-left: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0;
        }

        .startEndDate {
            margin-left: 0;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: start;

            input {
                width: 88px;
            }

            .icon-arrow {
                font-size: 11px;

                &::before {
                    color: #6c9aba;
                }
            }
        }



        .mobilePopup {
            .icon-location-on {
                display: block;
            }

            .ant-picker-dropdown,
            .ant-picker-borderless+div {
                position: static !important;
            }

            .ant-picker-panel-container {
                background: transparent;
                box-shadow: none;
                display: flex;
                justify-content: center;
                box-shadow: none !important;
                padding-bottom: 10px;
            }

            .inputInWrap {
                input {
                    color: #1d6ec9 !important;
                    border-bottom: 2px solid #1d6ec9;
                    padding: 7px 0 7px 27px !important;
                    font-size: 17px;
                }

                .ant-picker-dropdown {
                    .ant-picker-panel {
                        box-shadow: none !important;
                        border-bottom: none !important;
                        border: none;

                    }
                }
            }

            .ant-picker-input {
                input {
                    color: #1d6ec9;
                    font-size: 17px;
                }
            }

        }

        #selectFlightOptions {
            ul.react-tabs__tab-list {
                flex-direction: column;
                margin-bottom: 0;
            }
        }

        .openModal {

            .startEndDate .ant-picker-dropdown,
            .ant-picker-focused+div {

                left: auto !important;
                right: 0;

            }

            .searchBtnWrap {
                width: auto;
            }

            .hideInModal {
                display: none;
            }

            .startNendWrap,
            .filterSecondRow {
                flex-direction: row;
            }

            .startEndDate,
            .inputStyle {
                input {
                    color: #1d6ec9;
                }
            }

            .startEndDate {
                margin: 0 0 0 11px;
                max-width: 130px;
                min-width: auto;
            }

            .changeBtn {
                margin: 0 11px;
                background-color: $white;
            }

            .icon-swap-horiz:before {
                color: #1d6ec9;
            }

            .ant-select-dropdown {
                box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
                background-color: var(--bg-color);
                border-radius: 5px;
                min-width: 50vw !important;
            }
        }
    }

    #flightsTab.flight-tab-wrap {

        .returnDateWrap,
        .dateInWrap {
            display: flex;
            width: 100%;
        }

        .returnDateWrap .dateInWrap {
            justify-content: flex-end;
        }
    }


}

@media (max-width: 767px) {
    #flightsTab.flight-tab-wrap {
        padding: 15px 15px 20px;

        .showDropdown {
            .mobilePopup {
                z-index: 9999;

                .inputInWrap {
                    input {
                        border: none;
                        border-bottom: 2px solid #1d6ec9;
                        background: transparent;
                    }

                    .ant-select-dropdown {
                        background-color: transparent;
                        box-shadow: none;
                    }
                }

                .ant-picker {

                    width: 100%;

                    .ant-picker-input {
                        width: 300px;
                        margin: 0 auto;

                    }

                    input {
                        width: 100%;
                        text-align: center;
                        border-bottom: 2px solid #1d6ec9;

                    }

                }

                .ant-picker-dropdown {
                    top: 146px !important;
                    left: 0px !important;
                    width: 100%;
                    text-align: center;
                    box-shadow: none;

                    .ant-picker-panel-container {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .ant-picker-panel {
                        box-shadow: none !important;
                        border: none !important;
                    }
                }

            }
        }

        >.row {
            margin: 0;
            display: block;

            >.col {
                padding: 0;
            }
        }

        .startLocation {
            margin-bottom: 20px !important;
        }

        .startNendWrap .changeBtn {
            margin-top: -9px;
        }

        .ant-select-dropdown {
            background-color: transparent;
            box-shadow: none;

        }

        .rc-virtual-list-holder {
            max-height: 65vh !important;
        }

        .dropdownWrap {
            width: 100%;

            .toggle-btn {
                min-width: 100%;
            }
        }

        .travelerNclass .custom-dropdown {
            left: 0;
            width: auto;
        }

        .startEndDate {
            display: flex;
            width: 100%;

            .icon-arrow {
                left: -11.5px;
            }

            .returnDateWrap {
                display: flex;
                justify-content: space-between;
            }

            >.dateInWrap,
            .returnDateWrap {
                width: 50%;
            }
        }

        .icon-add::before,
        .addFlight {
            color: var(--text-color3);
        }

        // .ant-picker-focused {
        //     + div {
        //         position: fixed !important;
        //         left: 0 !important;
        //         right: 0 !important;
        //         top: 0 !important;
        //         bottom: 0 !important;
        //         background-color: #e6e7ee;
        //         z-index: 9;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         .ant-picker-dropdown {
        //             position: static !important;
        //             .ant-picker-panel {
        //                 border: 0 !important
        //             }
        //         }
        //         .ant-picker-panel-container {
        //             border: 0;
        //             box-shadow: none;
        //         }
        //     }
        // }
    }

    .landingPage {
        #flightsTab {
            .searchBtnWrap {
                padding-top: 0;
            }
        }
    }


    #selectFlightOptions {
        ul.react-tabs__tab-list {
            display: flex;
            margin-bottom: 20px;

            li {
                flex-grow: 1;
                justify-content: space-between;
                display: flex;

                +li {
                    justify-content: flex-end;
                }
            }
        }


        button.react-tabs__tab {
            width: 31%;
            margin-right: 5px;
        }
    }

    .filterSecondRow {
        justify-content: left;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .filterCnt {
        .openModal {
            .listModallWrap {
                padding: 0;

                .modalContent {
                    padding: 10px;
                    width: 100vw;
                    height: 100vh;
                    border-radius: 0;


                    .close {
                        margin: -10px -10px 0 auto;
                    }

                    .multiCityFlightTab,
                    .startNendWrap {
                        flex-direction: column !important;

                    }

                    .startNendWrap {
                        .changeBtn {
                            right: 0 !important;
                            top: 0% !important;
                            margin-left: auto !important;
                        }

                    }

                    .startEndDate {
                        margin: 19px 0px 0px 0px !important;
                        max-width: 100% !important;
                        width: 100% !important;
                    }
                }

                .ant-select-dropdown {
                    min-width: 65vw !important;
                }
            }
        }
    }
}

@media(max-width:440px) {
    #selectFlightOptions {
        button.react-tabs__tab {
            width: 31%;
            min-width: 82px;
        }
    }
}

@media (max-width:380px) {
    #flightsTab.flight-tab-wrap {
        .startEndDate {
            display: flex;
            width: 100%;
            padding: 7px 8px;

            .icon-arrow {
                left: -7px;
                padding: 0px;
            }

            .icon-invitation {
                margin-top: 6px;
                margin-right: 2px;
            }
        }
    }
}