// freemium signup PageWrap end
.MembershipUpgradePageWrap {

    .upgrade-now-wrapper {
        @include background("../img/membership-upgrade/upgrade-bg.png");
        background-size: 100% 100%;
        padding: 72.5px 65px;
        border-radius: 10px;
        margin: 0px -15px;

        .row {
            align-items: center;
        }

        .page-logo {
            max-width: 171px;
            flex: 0 0 171px;
            margin-top: -2px;
        }

        .button-wrap {
            max-width: 190px;
            flex: 0 0 190px;
        }

        .title {
            padding-right: 10px;
        }

        h1 {
            @include ubuntu(300);
            font-size: 35px;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;

            strong {
                font-weight: 500;
            }
        }

        .orange-btn {
            min-width: 190px;
            @include ubuntu(400);
            text-align: right;
            padding-right: 38px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .section-title {
        padding: 32px 0 29px;
        text-align: center;

        h2 {
            @include ubuntu(400);
            font-size: 25px;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
        }

        h3 {
            font-size: 19px;
            @include ubuntu(300);
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-top: 12px;
        }
    }

    .grid-layout-wrap {
        .section-title {
            padding-top: 18px;
        }

        .row {
            margin: 0 -9px;

            .col {
                padding: 0 9px;
            }
        }

        .infobox {
            padding: 5px 5px 3px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            height: 100%;
        }

        .image-box {
            margin: 0 0 12px;
            padding: 9px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #fff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);

            img {
                border-radius: 10px;
                overflow: hidden;
                width: 100%;
                margin-bottom: 3px;
            }
        }

        .grid-content {
            padding: 0 10px 16px;

            h6,
            h6 a {
                font-size: 17px;
                @include ubuntu(500);
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 10px;
            }

            p {
                font-size: 14px;
                @include ubuntu(300);
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }
        }
    }

    .plan-wrapper {
        .infobox {
            padding: 5px 5px 21px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            height: 100%;
            padding-bottom: 134px;
            min-height: 600px;
            position: relative;
        }

        .row {
            margin: 0 -25px;

            .col {
                padding: 0 25px;
            }
        }

        .plan-image-box {
            text-align: center;
            padding: 11px 10px 9px;
            border-radius: 6px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #fff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);

            .plan-image {
                @include background("../img/membership-upgrade/plan-img1.png");
                min-height: 128px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                .status-icon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }

            h6 {
                @include ubuntu(400);
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
            }

            h5 {
                @include ubuntu(400);
                font-size: 19px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-top: 13px;
                margin-bottom: 4px;
            }
        }

        .plan-info {
            padding: 15px 0;
            max-width: 455px;
        }

        ul {
            list-style: none;
            padding-left: 35px;

            li {
                @include ubuntu(400);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                position: relative;
                margin-bottom: 9px;

                &:before {
                    content: "";
                    @include background("../img/membership-upgrade/right-tick.svg");
                    width: 14px;
                    height: 11px;
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: -26px;
                }
            }
        }

        .plan-footer-wrap {
            // border-top: 1px solid rgba(67, 91, 117, 0.2);
            padding: 14px 15px 21px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            .price-info {
                padding-right: 10px;
                padding-left: 25px;
            }

            .plan-footer {
                display: flex;
                justify-content: space-between;
                position: relative;

                &:after {
                    content: "";
                    border-top: 1px solid rgba(67, 91, 117, 0.2);
                    display: block;
                    width: 100%;
                    position: absolute;
                    top: -15px;
                }
            }

            h5 {
                @include ubuntu(400);
                font-size: 30px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;

                span {
                    font-size: 14px;
                }
            }

            h6 {
                @include ubuntu(400);
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            .info {
                padding-top: 23px;
                max-width: 60%;

                p {
                    opacity: 0.6;
                    @include ubuntu(400);
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #435b75;
                }
            }

            .button-info {
                padding-top: 4px;
            }

            .btn {
                min-width: 190px;
                min-height: 45px;
                background-color: transparent;
                @include background("../img/signup/blue-btn-bg.png");
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                padding-right: 33px;
                @include ubuntu(400);
                box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2), -3px -3px 6px 2px #fff;
                appearance: none;
                -webkit-appearance: none;

                &::before {
                    display: none;
                }
            }
        }
    }

    .full-video-wrapper {
        .section-title {
            padding-bottom: 35px;

        }

        .gradiantBg {
            margin-top: 0px;
        }

        .videoBox {
            border-radius: 10px;
            // background-image: linear-gradient(115deg, #0072d1, #00dcdf 100%);
            overflow: hidden;
            position: relative;
            margin: 0px -15px;

            .btn-play {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                border: none;
                outline: none;
            }
        }
    }

    .faq-wrapper {
        margin-bottom: 40px;

        .accordion {
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);

            .card {
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                padding: 0 25px 12px 20px;

                s &:last-child {
                    border: none;
                }
            }

            button {
                @include ubuntu(500);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                border: none;
                outline: none;
                width: 100%;
                background-color: transparent;
                cursor: pointer;
                text-align: left;
                padding: 18px 0 6px;
                display: flex;

                img {
                    margin-right: 10px;
                    max-width: 22px;
                }
            }

            p {
                @include ubuntu(400);
                font-size: 14px;
                padding-top: 12px;
                line-height: 1.29;
                letter-spacing: normal;
                color: #435b75;
                padding-left: 31px;
            }

            .card-content {
                padding: 0;
                overflow: hidden;
                max-height: 0;
                transition: all 0.5s ease-in-out;
            }

            .open-accordion {
                max-height: 300px;
            }

        }


    }

}

.MembershipCheckoutPageWrap {
    .card2 .promocodeWrap .customCheckbox input:checked+span:after {
        left: 5px;
        top: 4px;
    }
}

.affiliateUpgradeCheckoutPageWrap {
    .card-primary-contact {
        .sectionTitle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            @media(max-width:767px) {
                flex-direction: column;
                margin-bottom: 10px;
            }

            .selectStyle {
                min-width: 293px;

                @media(max-width:767px) {
                    min-width: 100%;
                }

                .member-number-box {
                    padding: 0;
                    max-width: 315px;
                    width: 315px;
                    .formControl {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media(max-width:1160px) {
    .MembershipUpgradePageWrap {
        .pageInternalScroll {
            >div {
                overflow-x: hidden !important;
            }
        }
    }
}

// Page internal scroll
@media (min-width:992px) {
    .MembershipUpgradePageWrap {
        .pageInternalScroll {
            padding-top: 20px;
            height: calc(100vh - 120px) !important;
            overflow-y: auto;
            padding-bottom: 0;
            overflow-x: hidden;

        }
    }
}


@media (max-width:992px) {
    .MembershipUpgradePageWrap {


        .mainContent {
            .pageInternalScrollTopPadding {
                padding-top: 0px;
                padding-bottom: 20px;
            }
        }

        .plan-wrapper {
            .row {
                .col {
                    padding: 0 15px;
                    margin: 5px 0px;
                }
            }
        }
    }
}

@media (max-width:767px) {

    .MembershipUpgradePageWrap {
        .mainContent {
            .upgrade-now-wrapper {
                padding: 40px 30px;
                background-size: auto 150%;
                margin: 0px;

                h1 {
                    margin: 7px 0 10px;
                }

                .row {
                    flex-direction: column;
                }

                .page-logo,
                .button-wrap {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }


            .section-title {
                padding: 25px 0;

                h2 {
                    font-size: 19px !important;
                }
            }

            .grid-layout-wrap {
                .row {
                    .col {
                        max-width: 50%;
                        flex: 0 0 50%;
                        padding-bottom: 20px;
                    }
                }
            }

            .plan-wrapper {
                .section-title {
                    padding-top: 5px;
                    max-width: 200px;
                    margin: 0px auto;
                    padding-top: 15px;

                }

                .choose-plan {
                    .row {
                        flex-direction: column;
                    }
                }

                .plan-image-box {
                    h5 {
                        font-size: 17px;
                    }
                }

                .infobox {
                    padding-bottom: 0;
                    min-height: inherit;
                    max-width: 630px;
                    margin: 0 auto 20px;
                }

                .plan-footer-wrap {
                    position: static;
                    padding: 14px 10px 21px;

                    .price-info {
                        padding: 0;

                        h5 {
                            font-size: 24px;
                        }
                    }

                    .btn {
                        min-width: 160px;
                        min-height: 40px;
                    }
                }
            }

            .full-video-wrapper {

                .cardStyle {
                    background: linear-gradient(to right, rgb(236, 236, 243), rgb(230, 231, 238));
                    box-shadow: none;
                    min-height: 250px;
                    max-width: 560px;
                    height: 100%;
                    padding: 9px;
                    margin: 0 auto;
                }

                .videoBox {
                    background: none;
                    box-shadow: none;
                    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                    height: 230px;
                    margin: 0px;
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #fff;
                    border: solid 1px #d2dae6;

                    .videoInBox {
                        min-height: 180px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                        border-radius: 8px;
                        padding: 0px;
                        height: 100%;

                    }
                }

                .section-title {
                    padding-bottom: 35px;
                }
            }

            .faq-wrapper {
                .accordion {
                    .card {
                        padding: 0px;

                        .card-content {
                            padding: 0 12px;
                            overflow: hidden;
                            max-height: 0;
                            transition: all 0.5s ease-in-out;

                        }

                        .open-accordion {
                            max-height: 300px;
                            padding-bottom: 12px;
                        }
                    }

                    p {
                        font-family: "Ubuntu", sans-serif;
                        font-weight: 400;
                        padding-top: 0px;
                        font-size: 14px;
                        line-height: 1.29;
                        letter-spacing: normal;
                        color: #435b75;

                    }

                    button {
                        font-size: 14px;
                        position: relative;
                        padding: 12px 10px 12px 10px;

                        &::after {
                            content: "";
                            display: block;
                            background-image: url(../../img/network/dropdown.svg);
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            height: 12px;
                            width: 12px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 10px;
                        }

                        img {

                            display: none;
                        }


                    }
                }
            }
        }
    }

    .read-more-btn-wrap {
        button {
            font-size: 14px;
        }
    }
}


@media (max-width: 480px) {
    .MembershipUpgradePageWrap {
        .mainContent {
            .grid-layout-wrap {
                .row {
                    .col {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }
                }
            }


            .full-video-wrapper {
                .gradiantBg {
                    margin-top: 0px;
                    padding: 0px;
                }

                .cardStyle {
                    min-height: 180px;
                    max-width: 100%;

                }

                .videoBox {
                    min-height: 180px;
                    height: auto;

                    .videoInBox {
                        min-height: 158px;
                        height: 100%;
                    }
                }



                .section-title {
                    padding-bottom: 35px;
                }
            }

        }
    }
}