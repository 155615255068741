// Cars tab filter style
.siteBg.listingCars {
    .rbt .rbt-aux .btn-close {
        top: 15px;
    }

    .mainContent {
        .filterCnt {
            margin-bottom: 0px;

            .timeWrap {
                width: 120px;
                margin-left: 10px;


                .fa {
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    font-size: 17px;
                    color: #1d6ec9;
                }


                .selectBtn {
                    width: 120px;
                    cursor: pointer;
                    padding: 0px 0px 0px 0px;

                    .toggleBtn {
                        .custom-select__control {
                            width: 100%;

                            .custom-select__value-container {
                                padding: 0;
                            }
                        }
                    }
                }
            }


            .toggleBtn {
                .custom-select__control {
                    background-color: transparent;
                    border: none;
                    border-color: transparent;
                    width: 100px;
                    padding-left: 25px;
                    padding-top: 2px;
                    min-height: 35px;
                    cursor: pointer;
                    box-shadow: none;

                    .custom-select__indicators {
                        .custom-select__indicator-separator {
                            display: none;
                        }

                    }

                    .css-6j8wv5-Input,
                    .css-qc6sy-singleValue {
                        color: #1d6ec9;
                    }
                }

                .custom-select__menu {
                    padding: 5px;
                    border-radius: 10px;
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                    border: solid 1px #d2d9e7;
                    background-color: #e6e7ee;

                    .css-1n7v3ny-option,
                    .css-yt9ioa-option,
                    .css-9gakcf-option {
                        background-color: transparent;
                        padding: 11px 8px;
                        border: solid 1px transparent;
                        border-radius: 6px;
                        color: #435b75;
                        cursor: pointer;
                        font-size: 15px;
                    }

                    .custom-select__option:hover {
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                        border-color: #d2dae6;
                        padding: 11px 8px;
                        cursor: pointer;

                    }
                }
            }

            .inputStyle {

                input {
                    padding: 8.5px 7px 8.5px 33px;
                    max-height: 35px;

                }

                .inputDropdown {
                    // max-width: 250px;
                    z-index: 1;
                }

                .rbt {
                    >div {
                        width: 100%;
                        max-width: 100%;

                        .rbt-input-hint {
                            min-width: 100%;
                        }
                    }
                }
            }

            .filterDateTimeWrap {
                .dateWrap {
                    [class^=icon-] {
                        z-index: 1;
                        top: 46%;
                    }
                }
            }

            .locationStartEndWrap {
                .drop-off {
                    margin-left: 10px;
                }
            }

            .dateTimeWrap {
                .SingleDatePicker_picker {
                    top: 38px !important;

                    .DayPicker_transitionContainer,
                    .DateRangePicker_picker .DayPicker_transitionContainer {

                        min-height: 300px;

                    }
                }
            }
        }

        .listing-margin-top {
            margin-top: 10px;
        }

    }

    .mobileSortFilter {

        .closePopup,
        .mobileShow {
            display: none;
        }

        .sortFilter {
            display: flex;
            width: 100%;
            flex: 0 0 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;


            .filterDropdownWrap.sortByOption {

                margin-left: auto;
            }

            .vehicle-filter {
                .filterDropdown {
                    max-width: 240px;

                    button {
                        border-radius: 15px;
                        background-color: #eff0f5;
                        font-size: 14px;
                        font-weight: normal;
                        color: #1d6ec9;
                        width: 100px;
                        border: 1px solid #1d6ec9;
                        margin-right: 28px;
                        padding: 6px;
                        cursor: pointer;

                    }

                    button:hover {
                        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1), -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
                        background-color: #1d6ec9;
                        color: white;

                    }
                }

                .filterDropdownWrap {
                    .flterOption {
                        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);

                        background-color: rgba(255, 255, 255, 0.6);
                    }
                }

                .openedOption {
                    .flterOption {
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                }
            }

            .sortby-mobile {
                .filterDropdown {
                    button {
                        border: none;
                        background-color: transparent;
                        width: 100%;
                        display: block;
                        color: var(--text-color3);
                        font-size: 12px;
                        padding: 10px 9px;
                        border: solid 1px transparent;
                        border-radius: 6px;
                        text-align: left;
                        white-space: nowrap;
                    }

                    button:hover {
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                        border-color: #d2dae6;
                        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                    }
                }
            }


        }

        .vehicle-filter {
            display: flex;
            width: auto;
            justify-content: flex-start;
        }

        .sortby-mobile {
            margin-left: auto;
        }

        .vehicle-filter {
            display: flex;
            width: 100%;

            .filterDropdownWrap {
                .cardBody {
                    border-radius: 10px;
                    box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                    background-color: #eff0f5;
                    padding: 5px 15px 20px;
                    box-sizing: border-box;
                    top: 50px;
                    position: absolute;
                    min-width: 200px;
                    display: none;
                    z-index: 2;

                    .checkBox-width {
                        padding-right: 10px;
                    }
                }

            }
        }

        .mobilePopTitle {
            display: none;
        }
    }

    .filter-display {
        padding: 0px 10px 0px 0px;

    }

    .dropDownTooltip {
        .dropdownInWrap h4 {
            font-size: 12px;
        }
    }

    .sortFilter {
        .btnBorder {
            background-image: linear-gradient(90deg, #1377c4, #00ceda);
            border: none;
            box-shadow: 2px 2px 4px 2px rgb(0 0 0 / 25%), -1px -1px 4px 2px hsl(0deg 0% 100% / 35%);
            background-color: #1d6ec9;
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
            border-radius: 15px;
            padding: 6.5px;

            &:hover {
                background-image: linear-gradient(90deg, #00ceda -12%, #1377c4);
            }
        }
    }
}



.listingPage.listingCars {
    background: url(../../../img/widget-bg.jpg) 0 0 no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: inherit;

    .cardImg {
        padding: 12px 10px 8px;
        border-radius: 6px;
        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
            inset -2px -2px 6px 0 #ffffff;
        border: solid 1px #dae2ee;
        background-color: rgba(255, 255, 255, 0.35);
        position: relative;

        @media(max-width:767px) {
            border-radius: 0;
        }

        .cars-logo {
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            text-align: center;

            @media(max-width:767px) {
                bottom: 0;
            }
        }

        img {
            border-radius: 4px;
            max-width: 100%;
            height: auto;
        }

        .cardImage {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 120px;
            padding-bottom: 87%;
            object-fit: cover;
            border-radius: 4px;
            background-color: #ffffff;

            @media(max-width:767px) {
                padding-bottom: 56.25%;
                border-radius: 4px 4px 0 0;
            }
        }
    }

    .carResult {
        position: relative;

        @media (max-width: 991px) {
            .BSrow {
                .col-3 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 33.333333%;
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }

                .col-9 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 66.666667%;
                    flex: 0 0 66.666667%;
                    max-width: 66.666667%;
                }
            }
        }

        .showToolTip {
            position: static;


            @media (min-width: 768px) {
                margin-bottom: 265px;
            }

            .cardInfoToolTip {
                display: block;
            }
        }

        .toolTipArro {
            position: relative;

            &:before {
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid rgba(243, 244, 248, 0.8);
                content: "";
                display: inline-block;
                position: absolute;
                left: 50%;
                margin-left: -20px;
                bottom: -40px;
            }
        }

        .cardInfoToolTip {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            margin-top: 20px;
            z-index: 1;

            .card2 {
                cursor: auto;
            }

            .lightGrayTag {
                display: inline-flex;
                height: 35px;
                align-items: center;
                justify-content: center;

                .icon-navigation,
                .icon-icon3,
                .icon-suitcase2,
                .icon-gear,
                .icon-door {
                    font-size: 19px;
                }
            }

            .cardFeatures {
                padding-top: 19px;
                padding-bottom: 7px;

                .lightGrayTag {
                    margin-bottom: 5px;
                    margin-right: 8px;
                    font-size: 14px;
                    padding: 5px 14px;
                    min-width: 80px;

                    @media (min-width: 768px) and (max-width: 991px) {
                        padding: 5px;

                        [class^="icon-"] {
                            font-size: 15px;
                        }

                        .icon-seater {
                            font-size: 15px;
                        }
                    }
                }
            }

            .tickNote {
                padding-bottom: 19px;

                .icon-creditCard {
                    margin-right: 7px;
                }
            }

            .card2 {
                width: 100%;
            }

            .cardTitle {
                margin-top: 6px;
            }

            .cardInSpace {
                padding: 0 0 0 20px;

                @media (max-width: 991px) {
                    padding-left: 0;
                }
            }

            .greenBdrTag {
                margin-bottom: 10px;
                padding: 2.5px 5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            .shadowBox {
                border-radius: 6px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);
                margin-left: 15px;
                padding: 16px 10px 20px;

                .btn {
                    display: inline-block;
                    font-size: 14px;
                    margin-top: 21px;
                    min-width: 140px;
                    padding: 9.5px;
                    z-index: 1;

                    &:before {
                        display: none;
                    }
                }

                .sideSpace {
                    padding: 0 20px;
                }

                .totalAmout {
                    color: var(--text-color2);
                    padding: 12px 0 7px 0;

                    .rightText {
                        color: var(--text-color);
                        font-weight: 500;
                        font-size: 21px;
                    }
                }

                .smlText {
                    font-size: 12px;
                    color: var(--text-color2);
                }

                .priceDetails {
                    color: var(--text-color3);
                    font-size: 12px;
                    cursor: pointer;

                    .fa {
                        margin-left: 4px;
                    }

                    .dropdownInWrap {
                        display: none;
                        z-index: 11;
                    }


                }

                .priceDetails:hover {
                    .dropdownInWrap {
                        display: block;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            .toolTipArro {
                &:before {
                    border-bottom-color: rgb(204 227 236);
                    z-index: 1;
                    bottom: -1px;
                    margin-left: -10px;
                    border-width: 10px;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: 50%;
                    margin-left: -11px;
                    bottom: -1px;
                    border-left: 11px solid transparent;
                    border-right: 11px solid transparent;
                    border-bottom: 11px solid #96b1bc;
                }
            }

            .cardInfoToolTip {
                position: static;
                border-top: 1px solid #96b1bc;
                margin-top: 0;

                .card2 {
                    background: transparent;
                    box-shadow: none;
                    margin-top: 9px;
                }

                .cardTitle {
                    font-size: 14px;
                    color: var(--text-color);
                }

                .col-9 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .cardInSpace {
                    padding-left: 13px;
                    padding-right: 10px;

                    .cardFeatures {
                        margin-left: -2px;
                        margin-right: -2px;
                        padding-top: 13px;

                        .lightGrayTag {
                            width: 31%;
                            height: 30px;
                            padding: 0;
                            margin-left: 4px;
                            margin-right: 2px;
                            box-sizing: border-box;
                            margin-bottom: 10px;
                        }
                    }
                }

                .startToEnd {
                    padding-left: 1px;
                    font-size: 12px;
                    padding-bottom: 12px;

                    &:before {
                        left: 5px;
                        bottom: 27px;
                    }

                    .startLocation {
                        padding-bottom: 11px;
                    }

                    .icon-location {
                        margin-right: 9px;
                    }
                }

                .boxTopSec {
                    .centerAlign {
                        text-align: left;
                    }
                }

                .shadowBox {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    margin-left: 11px;
                    margin-right: 9px;
                    padding-bottom: 8px;
                    margin-bottom: 10px;

                    &:before {
                        content: "";
                        width: 1px;
                        height: 100%;
                        left: 50%;
                        background: #96b1bc;
                        top: 0;
                        position: absolute;
                    }

                    .btn {
                        margin-top: 10px;
                        min-width: 118px;
                    }

                    .sideSpace,
                    .boxTopSec {
                        width: 50%;
                    }

                    .sideSpace {
                        padding: 0;
                        margin-top: -20px;
                        padding-left: 7px;
                    }

                    .taxNPrice {
                        flex-direction: column-reverse;
                    }

                    .totalAmout {
                        justify-content: center;
                    }

                    .dropDownTooltip .dropdownInWrap {
                        left: -84px;
                    }

                    .greenBdrTag {
                        font-size: 12px;
                        margin-bottom: 4px;
                    }

                    .priceDetails {
                        padding-top: 5px;
                    }

                    .tickNote {
                        font-size: 12px;
                        margin-top: 0;
                        padding-bottom: 9px;

                        .icon-creditCard {
                            font-size: 11px;
                            margin-right: 2px;
                        }
                    }

                    .totalAmout {
                        color: var(--text-color2);
                        padding-bottom: 5px;
                        font-size: 12px;
                        align-items: flex-end;

                        .rightText {
                            font-size: 17px;
                            font-weight: normal;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        .card2 {
            margin-top: 20px;
            cursor: pointer;
        }

        .cardTitle {
            color: var(--text-color3);
            font-size: 21px;
            font-weight: normal;
            margin-top: 13px;
        }

        .similarVehicle {
            color: var(--text-color);
            font-size: 14px;
            margin-top: 6px;

            .grayTag {
                background: rgba(67, 91, 117, 0.6);
                color: var(--white);
                font-size: 12px;
                border-radius: 6px;
                display: inline-block;
                padding: 3px 11px;
                margin-left: 12px;
            }
        }

        .tickNote {
            color: var(--text-green);
            font-size: 14px;
            margin-top: 6px;

            .icon-tick:before {
                font-size: 11px;
                margin-right: 8px;
            }
        }

        .totalAmt {
            color: var(--text-color);
            font-size: 17px;

            span {
                color: var(--text-color2);
                font-size: 14px;
            }
        }

        .perDay {
            color: var(--text-color);
            font-size: 23px;

            span {
                color: var(--text-color2);
                font-size: 14px;
            }
        }

        .cardBtmBox {
            margin-top: 24px;
            margin-bottom: 4px;
        }

        @media (max-width: 767px) {
            .BSrow {
                .col-3 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            .card2 {
                padding: 0;
                margin-top: 10px;
                margin-bottom: 0;
            }

            .cardImg {
                padding: 0;
                box-shadow: none;
                background: #fff;
                text-align: center;
                border-radius: 10px;

                img {
                    display: block;
                    // min-height: 160px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .cardCnt {
                display: flex;
                justify-content: space-between;
                padding: 0 11px 12px 11px;
            }

            .cardTitle {
                margin-top: 4px;
            }

            .cardBtmBox {
                flex-direction: column;
            }

            .cardBtmBox {
                margin-top: 28px;
            }

            .totalAmt span {
                font-size: 10px;
            }

            .perDay {
                font-size: 21px;
                margin-bottom: 5px;

                span {
                    font-size: 10px;
                }
            }
        }
    }

    .cardCnt {
        .cardTitle {
            min-height: 50px;

            @media(max-width:767px) {
                min-height: auto;
            }
        }
    }
}


@media(max-width:991px) {
    .siteBg.listingCars {
        .mainContent {
            .filterCnt {
                position: sticky;
                top: 45px;
                left: 0;
                right: 0;
                z-index: 2;
                box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                background: linear-gradient(to right, #7291de 24%, #5bbad1 65%);
                min-height: auto;
                padding: 4.5px 19px 4.5px 15px;

                .inputStyle {
                    font-size: 14px;

                    input {
                        padding-left: 30px !important;
                    }

                    .inputDropdown {
                        max-width: 100%;
                        width: 100%;
                    }


                    .rbt {
                        >div {
                            width: 100%;
                            max-width: 100%;
                        }

                        .rbt-menu {
                            z-index: 999;
                        }
                    }

                }

                .locationStartEndWrap {
                    .drop-off {
                        margin-left: 0px;
                        margin-top: 10px;

                    }
                }

                .dateAndBtnWrap {
                    margin-top: 10px;
                    flex-direction: column;
                    width: 100%;

                    .dateTimeWrap {
                        margin-left: 0px;
                        width: 100%;
                    }

                    .filterDateTimeWrap {
                        width: 100%;
                        flex: 0 0 100%;
                        display: flex;
                        justify-content: flex-start;
                        margin-bottom: 10px;

                        .inputStyle {
                            display: flex;
                            flex: 0 0 50%;
                            border: none;
                            background-color: transparent;

                            .date-picker-style {
                                background: rgba(255, 255, 255, 0.4);
                                font-size: 14px;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                color: #1d6ec9;
                                padding: 8.5px 15px 8.5px 25px;
                                border-radius: 10px;
                                background-image: none;
                                box-shadow: none;
                                margin: 0px 0px;

                            }
                        }

                        .SingleDatePicker {
                            display: block;
                            width: 100%;

                            >div {
                                width: 100%;
                            }

                            .SingleDatePickerInput,
                            .DateInput {
                                width: 100%;

                                .SingleDatePicker_picker {
                                    top: 40px !important;
                                    left: 0px;
                                    width: auto;
                                    padding: 0px 10px;

                                    .DayPicker_weekHeader {
                                        padding: 0 !important;
                                        margin-left: 0px;
                                        width: 100%;
                                        text-align: center;
                                    }

                                    .DayPicker__withBorder {

                                        .CalendarMonthGrid_month__horizontal,
                                        .CalendarMonth {

                                            width: auto !important;

                                        }
                                    }
                                }
                            }

                        }

                    }
                }

                .timeWrap {
                    width: 100%;

                    .selectBtn {
                        width: 100%;
                    }

                    .custom-select__menu {
                        z-index: 99;
                    }
                }

                .toggleBtn {
                    .custom-select__control {
                        width: 100%;

                        .css-319lph-ValueContainer {
                            padding: 0px;
                        }
                    }
                }

                .btnBorder {
                    min-width: 116px;
                }

                .mobileShow {
                    color: #fff;
                    display: flex;

                    .mobileSearchBlock {
                        display: flex;
                        flex: 0 0 100%;
                        width: 100%;
                        padding: 9px 0px;

                        .selectSearchCnt {
                            display: flex;
                            width: 100%;
                            padding: 0px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .fa-search {
                            margin-right: 16px;
                        }

                        .fa-angle-down {
                            margin-left: auto;
                            cursor: pointer;

                        }

                        h4 {
                            font-size: 14px;
                            font-weight: normal;

                            .arrow {
                                margin-left: 5px;
                                margin-right: 2px;
                            }
                        }

                        p {
                            font-size: 12px;
                            margin-left: 10px;
                            line-height: 18px;
                        }
                    }
                }

                .closeFilter {
                    .fa-angle-up {
                        margin-left: 5px;
                    }
                }

                .mobile-filter {
                    flex-direction: column;
                    padding: 10px 6px 0px 6px;

                    .locationStartEndWrap {
                        display: flex;
                        flex-direction: column;
                        flex: 100%;
                        width: 100%;
                    }

                    .inputStyle {
                        input {
                            padding-left: 22px !important;
                        }
                    }
                }

                .filterDateTimeWrap {
                    .dateWrap [class^=icon-] {

                        font-size: 17px;
                    }
                }

            }

            .hide-content {
                display: none;
            }
        }

        .mobileSortFilter.showPopup {
            padding: 20px 15px 80px;
            z-index: 999;
            display: none;


            .sortFilter {
                align-items: flex-start;
                flex-direction: column-reverse;
                padding-top: 0px;

                .mobilePopTitle {
                    display: block;
                    padding-bottom: 20px;
                }

                .sortby-mobile {
                    margin-bottom: 25px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .filterDropdownWrap.sortByOption {
                        width: 100%;
                        max-width: 100%;
                        margin-left: 0px;

                        .flterOption {
                            padding-top: 10px;
                            width: 100%;
                            padding-bottom: 14px;
                            box-shadow: -2px -2px 4px 0 #ffffff, 2px 2px 3px 0 rgb(0 0 0 / 15%);
                            border: solid 1px #cfd1e1;
                            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                        }

                        .whiteLabel {
                            color: #1d6ec9;
                            font-size: 17px;
                            margin-bottom: 0px;
                        }
                    }


                }

                .vehicle-filter {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    .mobilePopTitle {
                        padding-bottom: 13px;
                    }

                    .filterDropdownWrap {
                        max-width: 100%;
                        width: 100%;
                        margin: 0px 0px 0px 0px;

                        .flterOption {
                            border-radius: 10px;
                            font-size: 17px;
                            font-weight: 500;
                            color: var(--text-color);
                            box-shadow: none;
                            background-color: transparent;
                            padding: 0px 15px 16px 0px;

                            .fa-angle-down {
                                display: none;
                            }
                        }

                        .cardBody {
                            display: block;
                            position: inherit;
                            background-color: transparent;
                            box-shadow: none;
                            border: none;
                            top: 0px;
                            padding: 5px 0px 20px;



                        }

                        .btnsCnt {

                            display: none;
                        }

                        .showMore {
                            padding: 15px 0px 0px 0px;
                            border: none;
                            display: block;
                            font-size: 17px;
                            color: var(--text-color3);
                            background-color: transparent;

                            .fa {
                                font-size: 23px;
                                line-height: 23px;
                                margin-left: 5px;
                            }
                        }


                    }
                }

                .customCheckbox {

                    span {
                        font-size: 17px;
                        padding-left: 31px;
                    }

                    >span:before {
                        width: 21px;
                        height: 21px;
                    }

                }
            }

            .closePopup,
            .mobileShow {
                display: block;

                .clearFilter {
                    display: block;
                    border: none;
                    background-color: transparent;
                }
            }

            .checkBox-width {
                width: 33.33%;
                float: left;
            }

            .showMoreInWrap {
                max-height: 1000px;
                padding-bottom: 5px;

                .customCheckbox {
                    width: 33.33%;
                    float: left;
                    font-size: 17px;

                    >span {
                        margin-top: 0px;
                        padding-left: 32px;

                    }

                    span:before {
                        width: 21px;
                        height: 21px;
                    }
                }


            }
        }

        .mobileFilterBtn {
            width: 100%;
            position: static;
            transition: all 0.3s;
            top: 0px;
            left: 0;
            right: 0;
            z-index: 1;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            background: linear-gradient(to right, #7291de 24%, #5bbad1 65%);
            text-align: center;
            display: block;
            font-size: 14px;
            color: #fff;
            border-radius: 17.5px;
            padding: 9.5px;
            border: none;
            cursor: pointer;
        }


    }
}

@media (max-width:991px) {
    .siteBg.listingCars {
        .mainContent {
            .mobileSortFilter.showPopup {
                .clearFilter {
                    position: absolute;
                    right: 62px;
                    top: 14px;
                    color: var(--text-color3);
                }
            }

            .filterCnt {
                .locationStartEndWrap {
                    .inputStyle .icon-location-on {
                        top: 10px;
                        transform: none;
                    }
                }

                .mobile-filter {
                    padding: 10px 0px 0px;
                }

                .dateAndBtnWrap {
                    margin-top: 0px;

                    .filterDateTimeWrap {
                        margin-bottom: 0px;

                        .SingleDatePicker {
                            // max-width: 300px;
                            margin: 0 auto;

                            input {
                                // background-color: transparent;
                                // text-align: center;
                                // border-bottom: 2px solid #4385d0;
                                // border-radius: 0px;
                                // font-size: 17px;

                            }

                            .icon-invitation {
                                left: 10px;
                                top: 50%;
                                font-size: 17px;

                            }

                            .DayPicker_transitionContainer {
                                box-shadow: none;
                                border: none;
                                min-height: 300px;
                                width: 310px !important;
                                background: transparent;
                                // border-radius: 0px;
                                // margin-left: -7px;
                            }

                            .CalendarMonth_table {
                                width: 300px !important;
                                margin-left: 6px;
                            }

                            .SingleDatePicker_picker {
                                .DayPicker__withBorder {

                                    box-shadow: none;
                                    border: none;
                                    background-color: none;

                                }

                                .DayPicker_weekHeaders__horizontal :last-child {
                                    left: 9px !important;
                                    width: 100%;
                                    display: flex;
                                }

                                .DayPicker_weekHeader {

                                    .DayPicker_weekHeader_li {
                                        width: 43px !important;
                                    }
                                }

                                .CalendarDay__blocked_out_of_range {
                                    font-size: 17px;
                                }
                            }
                        }

                        .date-picker-type {
                            width: 100%;
                        }


                        .customRadio>span {
                            margin-top: 0px;
                        }
                    }

                    .date-picker-popup {
                        .closePopup {
                            top: 10px;
                            right: 10px;
                        }
                    }
                }

                .locationStartEndWrap {
                    .drop-off {

                        margin-top: 0px;

                    }
                }

                .mobileBtnWrap {
                    margin-top: 0px;
                }

                .mobile-filter {
                    .locationStartEndWrap {
                        .showDropdown {
                            .inputStyle {
                                background: rgba(255, 255, 255, 0.4);
                                background-image: none;
                                box-shadow: none;
                                border: none;
                                text-align: left;
                                border-radius: 10px;
                                margin-bottom: 10px;
                                padding: 9.5px 15px 9.5px 23px;


                            }

                            .inputDropdown {
                                margin: 0px 0px 10px 0px;
                            }
                        }
                    }

                    .inputStyle {
                        input {
                            padding-left: 30px !important;
                        }
                    }
                }

                .dateAndBtnWrap {
                    margin-top: 0px;

                    .dateTimeWrap {
                        margin-left: 0;
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                }

                .toggleBtn {
                    .custom-select__control {
                        width: 100%;
                        padding-left: 30px;
                    }
                }
            }
        }

        .mobileSortFilter.showPopup {
            .showMoreInWrap {
                .customCheckbox {
                    width: 100%;


                }
            }
        }
    }

}

@media (max-width:991px) {
    .listingPage.listingCars {
        .cardImg {
            img {
                // height: 100%;
                // min-height: 239px;
            }
        }
    }
}

@media (max-width: 768px) {
    .listingPage {
        .sortFilter {
            .filterDropdown {
                .cardBody {
                    .multiCol3 {
                        min-width: 662px;
                        padding-right: 0;

                        .customCheckbox {
                            width: 33.33%;
                            float: left;
                            margin-top: 10px;
                            line-height: 7px;
                        }
                    }

                }
            }
        }

        .filterCnt {
            .dateAndBtnWrap {
                .inputStyle input {
                    padding-left: 30px;
                }
            }
        }
    }

    .siteBg.listingCars {
        .mainContent {
            .filterCnt {


                .dateAndBtnWrap {
                    .filterDateTimeWrap {
                        .inputStyle {
                            .date-picker-style {

                                padding: 8.5px 15px 8.5px 30px;

                            }
                        }
                    }
                }

                .toggleBtn {
                    .custom-select__control {
                        width: 100%;
                        padding-left: 30px;
                    }
                }

                .date-picker-popup {
                    .SingleDatePicker {
                        .SingleDatePickerInput {

                            .SingleDatePicker_picker {
                                left: -15px !important;

                                .CalendarMonth_caption {
                                    margin-left: 0px;
                                }
                            }
                        }
                    }

                    .SingleDatePicker {
                        width: 300px !important;

                        input {
                            background: transparent;
                            width: 300px;
                            margin: 0 auto;
                            text-align: center;
                            border-radius: 0px;
                            border-bottom: 2px solid #1d6ec9;
                        }

                        .icon-invitation {
                            left: 100px !important;

                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .listingCars {
        .sortFilter {
            .filterDropdownWrap {
                max-width: 240px;
            }
        }
    }
}

@media (max-width: 360px) {
    .listingPage.listingCars .carResult .cardInfoToolTip .cardInSpace .cardFeatures .lightGrayTag {
        font-size: 12px;

        i {
            font-size: 16px;
        }
    }
}

.btnsCnt {
    .borderBtn {
        border-radius: 15px;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
            -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
        background-color: #1d6ec9;
        font-size: 14px;
        font-weight: normal;
        color: $white;
    }

    .btnBorder {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1.1;
    }

    .disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: rgba(239, 240, 245, 0.8);

        &:hover {
            background-color: #1d6ec9;
            color: #ffff;
        }
    }
}