.loginPageWrap,
.forgotPasswordWrap {
  background: none;
  position: relative;
  padding-bottom: 0;
  overflow-y: auto;

  .pageLoginWrap,
  .pageForgotPassword {
    background-image: url('../../../img/Login-Background-screen.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .min-height-100vh {
    min-height: 100vh;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
    transition: background-color 5000s ease-in-out 0s;
    font-size: inherit !important;
  }
}

/*Login Page*/

.bgVideo {
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
}

.pageCenterCnt {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 50px;
    margin-top: auto;
    padding-top: 20px;
    display: block;
  }

  .icon-help,
  .icon-account-circle {
    opacity: 0.5;

    :before {
      color: #7FC9E4;
    }

  }




  .footCopy {
    margin-top: auto;
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    padding: 20px 0 12px;
  }
}

.tabsStyle {
  list-style: none;
  font-size: 19px;
  background-color: #edeef6;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #cfd1e1;
  display: flex;

  [class^="icon-"] {
    font-size: 32px;
    vertical-align: middle;
  }

  .icon-fun,
  .icon-building,
  .icon-flight,
  .icon-apartments,
  .icon-shape {
    *:before {
      text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
      color: #cfd1e1;
    }
  }

  .icon-fun {
    width: 30px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }

  li {
    padding-left: 10px;
  }

  a {
    color: #8696bc;
    border-bottom: 3px solid transparent;
    padding: 14px;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }

  .active {
    a {
      color: #1d6ec9;
      border-color: #1d6ec9;

      [class^="icon-"] {
        *:before {
          color: #1d6ec9;
          text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
        }
      }
    }
  }

  list-style: none;
  font-size: 19px;
  background-color: #edeef6;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #cfd1e1;
  display: flex;

  [class^="icon-"] {
    font-size: 32px;
    vertical-align: middle;
  }

  .icon-fun,
  .icon-building,
  .icon-flight,
  .icon-apartments,
  .icon-shape {
    *:before {
      text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
      color: #e6e7ee;
    }
  }

  .icon-fun {
    width: 30px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }

  li {
    padding-left: 10px;
  }

  a {
    color: #8696bc;
    border-bottom: 3px solid transparent;
    padding: 14px;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }

  .active {
    a {
      color: #1d6ec9;
      border-color: #1d6ec9;

      [class^="icon-"] {
        *:before {
          color: #1d6ec9;
          text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
        }
      }
    }
  }
}

.tabContent {
  padding: 20px 30px 31px;
}

.tabContentWrap {
  .tabContent {
    display: none;
  }

  .activeTab {
    display: block;
  }
}

.boxStyle {
  background-color: #e6e7ee;
  border-radius: 10px;
}

.inputStyle {
  position: relative;
  color: #1d6ec9;

  .icon-location-on {
    position: absolute;
    left: 15px;
    font-size: 18px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    font-size: 18px;
  }

  input {
    padding: 12px 15px 10px 40px;
    font-size: 17px;
    color: #1d6ec9;
    border-radius: 10px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    min-width: 243px;
    border-color: transparent;
  }

  input[type="text"],
  input[type="email"] {
    -webkit-appearance: none;
  }
}

.customCheckbox,
.customRadio {
  color: #435b75;
  font-size: 14px;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
  }

  >span {
    position: relative;
    padding: 1px 0 1px 28px;
    margin-top: 10px;
    display: block;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    }
  }

  input:checked+span {
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #1d6ec9;
      position: absolute;
      left: 4px;
      top: 4px;
      border-radius: 50%;
    }
  }
}

.customCheckbox {
  >span {
    &:before {
      border-radius: 4px;
    }
  }

  input:checked+span {
    &:after {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f00c";
      background-color: transparent;
      left: 2px;
      top: 2px;
      color: #1d6ec9;
    }
  }
}



/*END: Common CSS*/

/*START: Login*/

.pageBoxWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  .logo {
    margin-bottom: 50px;

    img {
      max-height: 34px;
    }
  }
}

.boxBg {
  max-width: 480px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;

  .mb-20 {
    margin-bottom: 20px;
  }

  .textCnt {
    text-align: center;
    color: #435b75;

    h6 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    p {
      font-size: 17px;
      margin-bottom: 28px;
    }
  }
}

.boxBtmLink {
  margin-top: 36px;
  display: flex;

  a {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 17px;
    padding: 0 20px;

    &:hover {
      color: #000000;
    }

    .icomoon-icon {
      margin-top: 0px;
      margin-right: 8px;
      opacity: 0.5;
    }



  }

}

.input-text-wrapper {
  .inputWrap {
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    display: flex;
    margin-bottom: 0px;
    font-size: 17px;

    .iconWrap {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1d6ec9;
    }

    input {
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.25);
      border: 0;
      font-size: 17px;
      color: #1d6ec9;
      padding: 15px;
      flex-grow: 1;
      border-radius: 0 10px 10px 0;
    }
  }

  .error-messages {
    padding-left: 40px;
  }
}

.pageCenterCnt {
  .dflex {
    flex-direction: column;

    .linkStyle {
      color: #fff;
      margin-bottom: 37px;

      i {
        margin-right: 4px;

      }
      &:hover {
        color: #000000;
      }
    }
  }

  .boxBtmLink {
    flex-direction: column;
    align-items: center;
    margin-top: 46px;

    a:first-child {
      margin-bottom: 34px;
    }
  }

  .inputWrap .iconWrap {
    color: #fff;
  }

  .inputWrap input {
    color: #fff;

    &::-webkit-input-placeholder {
      /* Edge */
      color: #fff;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }

    &::placeholder {
      color: #fff;
    }
  }

  .boxBg .textCnt {
    h6 {
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .flexColReverse {
    padding-top: 5px;
    flex-direction: column-reverse;

    .linkStyle {
      margin-bottom: 0;
      margin-top: 36px;
    }
  }
}

/*END: Login*/


// Login-page, forgot-password-page, reset-passoward-page error message style
.forgotPasswordSuccess {
  margin-top: 20px;

  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
  }

}

.boxBg {
  .input-text-wrapper {
    margin-bottom: 25px;

    .inputMainWrap {
      .inputWrap {
        width: 100%;
        margin-bottom: 5px;
      }

    }

  }

}



@media (max-width:768px) {

  .boxBtmLink {
    margin-top: 36px;
    display: flex;

    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 17px;
      padding: 0 20px;

      &:hover {
        color: #000;
      }

      [class^="icon-"] {
        margin-right: 8px;
      }

      .iconWrap {
        margin-top: -2px;

        i {
          vertical-align: bottom;
        }
      }
    }
  }
}