$button-bg: #0d52e9;
$speed: 0.6s;
$delay: ($speed * 0.5);
$easing: cubic-bezier(0.55, 0, 0.1, 1);

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(#000, 0.6);
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  transition: opacity $speed $easing, visibility $speed $easing;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  width: 90%;
  max-width: 400px;
  height: 250px;
  padding: 1rem;
  border-radius: 3px;
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  box-shadow: 0 2px 10px rgba(#000, 0.1);
  backface-visibility: hidden;
  transform: scale(1.2);
  transition: all $speed $easing;
  z-index: 10;

  .close-modal {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 15px;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity $speed $easing, transform $speed $easing;
    transition-delay: $delay;

    svg {
      width: 1.75em;
      height: 1.75em;
    }
  }

  .modal-content {
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity $speed $easing;
    transition-delay: $delay;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    i::before {
      content: "\e919";
      color: violet;
      border: 2px solid violet;
      border-radius: 50%;
      padding: 15px;
      font-size: 30px;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      text-align: center;
      text-justify: inter-word;
      margin-top: 30px;
    }

  } // content

  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .modal-content {
      opacity: 1;
    }

    .close-modal {
      transform: translateY(10px);
      opacity: 1;
    }
  }
}
