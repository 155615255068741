.checkoutPageWrap {
    &.popupOpned .pageWrap {
        filter: none;
    }

    .mobile-popup-bar {
        margin: 0px 10px 15px;
    }

    .pageWrap {
        .mainContent {
            >.container {
                padding-top: 10px;
            }
        }
    }

    .sectionTitle {
        h2 {
            font-size: 17px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 10px;
        }
    }

    .leftContent {
        max-width: 69.3%;
        flex: 0 0 69.3%;

        .cardWalletInfo {

            .wallet-amount-with-button {
                .btn {
                    font-size: 14px;
                }

            }
        }
    }

    .rightContent,
    .popupStyle {
        .infoBox {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 5px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        }

        .imgWrap {
            width: auto;
            height: 160px;
            padding: 0;
        }

        .Infocontent {
            padding: 5px;
        }

        .contentGroup {
            border-bottom: 1px solid rgba(67, 91, 117, 0.2);
            padding-bottom: 12px;

            h2 {
                font-size: 21px;
                font-weight: 400;
                color: #1d6ec9;
                line-height: 1.2;
                margin-bottom: 12px;
            }

            h3 {
                font-size: 14px;
                font-weight: 500;
                color: #435b75;
                line-height: 1.4;
                margin-bottom: 13px;

                span {
                    display: block;
                }

                a,
                button {
                    padding: 2px 6.8px 0px;
                    border-radius: 6px;
                    border: solid 1px #00b7a6;
                    color: #00b7a6;
                    font-size: 12px;
                    margin-top: 2px;
                    display: inline-block;
                    margin-left: 0px;
                    background-color: transparent;
                }
            }

            .contactInfo {
                padding-bottom: 6px;

                .location,
                .phone {
                    font-size: 14px;
                    color: rgba(67, 91, 117, 0.6);

                    img {
                        margin-right: 4px;
                    }

                    .icon-phone {
                        width: 18px;

                        &:before {
                            color: rgba(67, 91, 117, 0.6);
                        }
                    }
                }

                .location {
                    margin-bottom: 7px;
                    display: flex;

                    img {
                        margin-bottom: auto;
                    }
                }
            }
        }

        .roomInfo {
            padding-top: 15px;
            padding-bottom: 6px;
            border-bottom: none;

            .btn-non-refundable {
                border: 1px solid red;
                color: red;
            }

            .btn-refundable {
                border: 1px solid green;
                color: green;
            }

            h5 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 6px;

                strong {
                    font-weight: 500;
                    color: #435b75;
                }
            }

            h3 button {
                margin: 10px 0;
            }
        }

        .timeWrapper {
            padding: 8px 0 9px;

            .d-flex {
                align-items: center;
                margin-bottom: 6px;
            }

            img {
                margin-bottom: auto;
                max-width: 16px;
                margin-bottom: 0;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                color: #435b75;
                padding: 0 7px;
            }

            .checkin-checkout-detail {
                .clock-icon {
                    margin-left: -2px;
                    cursor: pointer;
                }
            }

            .tooltip {
                position: relative;
                display: inline-block;

                &.icon-info {
                    margin-left: 5px;
                }
            }

            .tooltip .tooltiptext {
                visibility: hidden;
                width: 200px;
                padding: 7px;
                position: absolute;
                z-index: 1;
                bottom: 125%;
                left: 70%;
                margin-left: -15px;
                opacity: 0;
                transition: opacity 0.3s;
                font-family: "Ubuntu", sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            .tooltip .tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -93px;
                border-width: 5px;
                border-style: solid;
                border-color: #eff0f5 transparent transparent transparent;
            }

            .tooltip:hover .tooltiptext {
                visibility: visible;
                opacity: 1;
            }


        }


        .amountCalculation {
            padding: 12px 14px 5px 15px;
            border-radius: 8px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #ffffff;
            -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #ffffff;
            -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #ffffff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            margin: 0 -5px;

            p,
            h5 {
                font-size: 14px;
                color: rgba(67, 91, 117, 0.6);
                font-weight: 400;
                margin-bottom: 8px;
            }

            h4 {
                color: #435b75;
                font-size: 17px;
                font-weight: 400;
            }

            .amountInfo {
                padding-bottom: 6px;
            }

            .payWrapper {
                padding: 8px 0;
                border-top: 1px solid rgba(67, 91, 117, 0.2);

                .savings {
                    h4 {
                        font-size: 21px;
                        font-weight: 500;

                        span {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }

            .savings {
                align-items: center;

                h4 {
                    text-align: right;
                }

                h5 {
                    display: flex;
                    align-items: center;

                    img {
                        margin-left: 4px;
                    }
                }

                span {
                    display: block;
                }
            }
        }

        .amount-due {
            h5 {
                font-weight: 500;
                color: #435b75;
                font-size: 15px;
            }

            h4 {
                font-weight: 500;
                color: #435b75;
                font-size: 19px;
            }
        }
    }

    .card2 {
        padding: 17px 20px;

        .sectionTitle {
            margin-bottom: 10px;
        }

        .customCheckbox {
            >span {
                &:before {
                    height: 22px;
                    width: 22px;
                }
            }

            input:checked {
                +span {
                    &:after {
                        left: 7px;
                        top: 6px;
                    }
                }
            }
        }

        .btn {
            &:before {
                display: none;
            }
        }

        .row {
            margin: 0 -7.5px 13px;
        }

        .col {
            padding: 0 7.5px;
        }

        h5 {
            color: rgba(67, 91, 117, 0.6);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 6px;
        }

        .formControl {
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 10px;
            border: 1px solid #dae2ee;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #ffffff;
            width: 100%;
            padding: 8.5px 15px 10px;
            display: block;
            overflow: visible;
            box-sizing: border-box;
            color: #435b75;
            @include ubuntu(400);
            font-size: 17px;
            min-height: 40px;
            -webkit-appearance: none;

            @include placeholder-text {
                font-weight: 400;
                color: rgba(67, 91, 117, 0.6);
            }
        }

        .react-tel-input {
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 10px;
            border: 1px solid #dae2ee;

            .form-control {
                width: 100%;
                padding: 9px 15px 10px 48px;
                display: block;
                overflow: visible;
                box-sizing: border-box;
                min-height: 36px;
                height: 38px;
                color: #435b75;
                font-family: "Ubuntu", sans-serif;
                font-weight: 400;
                font-size: 17px;
                background-color: transparent;
                border-color: transparent;
            }

            .flag-dropdown {
                border: none;
                box-shadow: none;
                background-color: transparent;
                border-radius: 0;
                border-right: none;

            }

            .selected-flag {
                outline: none;
                position: relative;
                width: 40px;
                border-right: 1px solid rgba(67, 91, 117, 0.2);
                min-height: 30px;
                padding: 0 0 0 10px;
                background-color: transparent;
                border-radius: 10px 0px 0px 10px;
                cursor: pointer;
            }

            .selected-flag:focus {
                background-color: transparent;
            }
        }

        .primaryInfo {
            .dropdown {
                max-width: 125px;
                flex: 0 0 125px;
            }
        }

        .dropdown {
            .selectWrap {
                position: relative;
                margin: 0 auto;
                width: calc(100% - 4px);

                &:after {
                    content: "";
                    @include background("../img/dropdown-arrow.png");
                    background-size: contain;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    z-index: -1;
                    opacity: 0.5;
                }

                select {
                    min-width: 100%;
                    border-radius: 10px;
                    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    -webkit-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px rgba(210, 218, 230, 0.6);
                    background-image: none;
                    background-color: rgba(255, 255, 255, 0.35);
                    min-height: 40px;
                    font-size: 17px;
                    color: rgba(67, 91, 117, 0.6);
                    padding-right: 28px;
                }

                .css-b62m3t-container .css-14el2xx-placeholder, [class*="placeholder"] {
                    color: rgba(67, 91, 117, 0.6);
                    overflow: hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                }

                .css-26l3qy-menu {
                    z-index: 2 !important;
                }

                [class*="indicatorSeparator"] {
                    display: none;
                }

                [class*="indicatorContainer"] {
                    color: #1d6ec9;

                }

                [class*="indicatorContainer"]:hover {
                    color: #1d6ec9;

                }

                .css-tj5bde-Svg {
                    color: #1d6ec9;
                    opacity: 0.5;
                }


                .css-4ljt47-MenuList, [class*="MenuList"] {
                    .css-yt9ioa-option,
                    .css-1n7v3ny-option, [class*="option"] {
                        cursor: pointer;
                    }
                }
            }
        }

        .add-guest {
            padding-top: 15px;
            border-top: 1px solid #cfd1e1;

        }
    }

    .billingInfo {

        .sectionTitle {
            margin-bottom: 19px;
        }

        .cardHolder {
            max-width: 245px;
        }

        .inputStyle {
            input {
                display: block;
                align-items: center;
                background-color: rgba(255, 255, 255, 0.35);
                border-radius: 10px;
                background-image: none;
                border: 1px solid #dae2ee;
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                width: 100%;
                padding: 11px 30px 11px 10px !important;
                box-sizing: border-box;


            }
        }

        .checkBoxWrap {
            width: 100%;
            align-items: center;

            p {
                font-size: 14px;
                color: #435b75;
                font-weight: 400;
                padding: 0 33px 0 5px;

                a {
                    color: #1d6ec9;
                }
            }

            .btn {
                font-size: 14px;
                padding: 12px;
                display: inline-flex !important;
                align-items: center;
                justify-content: center;
                max-height: 40px;
                border-radius: 4px;
                box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                background-image: linear-gradient(to right, #326ed1, #06c4d4);
                @media (max-width:767px) {
                    display: flex !important;
                }
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 7px;
                    vertical-align: text-top;
                }
            }
        }

        .checkBoxContent {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .customCheckbox {
            >span {
                margin-top: -5px;
            }
        }

        .walletWrapper {
            min-height: 114px;
            display: flex;
            width: 100%;
            flex: 0 0 100%;
            align-items: center;

            @media(max-width:991px) {
                min-height: 102px;
            }

            >div {
                width: 100%;
            }
        }
    }


    .cardWalletInfo {
        .boxTitle {
            max-width: 100%;
            margin-bottom: 0;
            line-height: 1;
        }

        .customCheckbox {
            >span {
                margin-top: 0;
                padding: 0;
            }

            span:before {
                top: -3.5px;
            }
        }
    }

    .cardDetails {
        .cardWrapper {
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 10px;
            border: 1px solid #dae2ee;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                inset -2px -2px 6px 0 #ffffff;
            width: 100%;
            padding: 0px 0px 0px 0px;
            box-sizing: border-box;
            height: 40px;


            .formControl {
                background-color: transparent;
                border: none;
                width: 100%;
                padding: 8.5px 0px 8.5px 5px !important;
                text-overflow: ellipsis;
                box-sizing: border-box;
                min-height: 30px;


                &.mm-yy {
                    max-width: 70px;
                    padding-right: 0px;
                    padding-left: 0px;
                }

                &.cvv {
                    max-width: 70px;
                    padding-right: 0;
                    padding-left: 0px;

                }
            }



            .formControl.error-messages {
                padding: 8.5px 0px 8.5px 10px !important;
                margin: 0px !important;

            }

        }

        .cardImage {
            max-width: 41px;
            flex: 0 0 41px;
            min-height: 38px;
            padding: 10px 4px 8.5px 10px;
            // border-right: solid 1px #d2dae6;
            background-image: none;
            border-radius: 10px 0px 0px 10px;
            // border: solid 1px rgba(210, 218, 230, 0.6);
            // box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            // background-color: rgba(255, 255, 255, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;


        }
    }

    .add-wallet-balance {
        .add-wallet-balance-row {
            display: none;

            .first-col,
            .second-col {
                max-width: 30%;
                flex: 0 0 30%;
            }
        }

        .AddwalletWrap {
            max-width: 55%;
            flex: 0 0 55%;
            justify-content: flex-end;
        }

        .sectionTitle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            h2 {
                margin-bottom: 0;
            }

            .checkbox-label small {
                font-size: 14px;
                color: #435b75;
                font-weight: 400;
                padding-left: 35px;
                margin-top: 2px;
                display: block;
                cursor: pointer;
            }
        }
    }

    .header {

        .container {
            justify-content: left;

            .logo1 {
                display: block;
            }

            .logo-sticky {
                display: none;
            }
        }

    }

    .resortFeessection {
        background-color: #d4d6e2;
        border-radius: 8px;
        padding: 6px 10px;
        margin: 6px -4px 0;

        h3 {
            strong {
                margin-left: 8px;
            }

            .circle {
                padding: 3px;
                border: 1px solid #435b75;
                border-radius: 100px;
                line-height: 0;
                height: 16px;
                width: 16px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 12px;
                }
            }
        }
    }

    .card-primary-contact {
        .email-section {
            .formGroup {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            .col {
                .col {
                    padding: 0;
                }
            }
        }
    }

    .promoInputCol2 {
        .btn {
            font-size: 14px;
        }
    }

}

.checkOutPage {
    .col {
        padding: inherit;
        flex-basis: unset;
        flex-grow: unset;
        max-width: 100%;
        padding-right: 15px;
    }

    .card2 {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .primaryInfo,
    .travelerBox {

        .halfCol,
        .col3 {
            margin: 0;

            .col {
                &:last-child {
                    padding-right: 0 !important;
                }
            }
        }
    }

    .billingDetails {
        .cardDetails {
            padding-left: 0;
        }
    }
}

.promocodeWrap {
    .checkbox-label {
        font-size: 14px;
        color: #435b75;
        font-weight: 400;
        padding: 0 0px 0 32px;
        margin-top: 3px;
        margin-bottom: 10px;
        display: block;
        cursor: pointer;
    }

    .checkBoxContent {
        .customCheckbox>span {
            margin-top: 0;
            padding: 0;
        }
    }
}

.hotel-checkout {
    .resortFeessection {
        h3 {
            display: flex;

            .circle {
                max-width: 16px;
                flex: 0 0 16px;
            }
        }

        p {
            text-align: center;

            @media(max-width:991px) {
                text-align: left;
                margin-left: 20px;
            }
        }
    }
}

/*Checkout page end*/

.modal-wrap {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    padding: 0px 5px;
    transform: translate(-50%, -50%);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;


    .modal-content {
        position: relative;
        max-width: 600px;
        margin: 0 auto;
        z-index: 999;
        width: 100%;
        height: 100%;
        max-height: 500px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 15px 25px 0px;
        overflow-y: auto;

        .modal-header {
            display: flex;
            width: 100%;
            margin-bottom: 20px;

            h5 {
                font-family: "Ubuntu", sans-serif;
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            .close {
                background-color: transparent;
                border: none;
                margin-left: auto;

                .icon-close {
                    font-size: 14px;
                    color: #1d6ec9;
                }
            }
        }


        h4 {
            font-size: 17px;
            font-family: "Ubuntu", sans-serif;
            font-weight: 400;
            color: var(--text-color);
            padding-bottom: 10px;
        }

        .model-text {
            font-size: 14px;
            font-family: "Ubuntu", sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            margin-bottom: 15px;
            padding: 0px;

            br+br {
                display: none;
            }
        }
    }

}

.MembershipCheckoutPageWrap {
    .pageInternalScroll {
        >div {
            max-height: 100% !important;
            margin-bottom: 0 !important;
            height: 100%;
            overflow-x: hidden !important;
        }
    }
}

.checkoutPageWrap {
    .selectWrap [class*="placeholder"] {
        color: inherit;
        margin: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .checkoutPageWrap {
        .checkoutWrapper {
            padding-top: 0px;
            margin-bottom: 20px;
        }

        .rightContent {
            margin-bottom: 16px;
        }

        .billingInfo {
            .sectionTitle {
                margin-bottom: 0px;
            }
        }
    }

}

@media (min-width: 992px) {

    .freemiumsignupPageWrap,
    .MembershipCheckoutPageWrap {
        .mainContent {
            .pageInternalScroll {
                height: calc(100vh - 68px) !important;
            }
        }
    }

    .checkoutPageWrap {
        .GlobalPaymentIntegrationWrap {
            .tabContent {

                .row,
                ul {
                    min-height: 63px;
                }
            }
        }
    }
}



@media (max-width: 991px) {
    .checkoutPageWrap {
        .rightContent {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .pageInternalScrollTopBottomPadding {
            padding: 0px 0 10px 0 !important;
            margin: 5px;
        }
    }


}

@media(max-width:992px) {

    .checkoutPageWrap {
        .header {
            .container {
                .logo1 {

                    margin: 0 auto;
                }
            }
        }
    }

    .card2 {
        .promoInputCol2 {
            .btn {
                min-width: 150px;
            }
        }
    }
}

@media(max-width :768px) {
    .modal-wrap {

        .modal-content {
            max-width: 450px;
            width: 90%;
            max-height: 400px;
            padding: 15px 15px 0px;
            overflow-y: auto;

            .modal-header {
                margin-bottom: 15px;

                h5 {
                    font-size: 18px;
                }

            }

            h4 {
                font-size: 16px;
            }

            .model-text {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 12px;


            }
        }

    }

}