.membersRewardsPageWrap {
    .walletWrapper {
        padding-top: 0px;


        .transaction-wrapper {

            //  select input-field Date-picker  style
            .filter-wrapper .dateWrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .dateBox {
                    position: relative;
                    border-radius: 10px;
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    border: solid 1px #dae2ee;
                    min-height: 40px;
                    margin-right: 0px;
                    padding: 0 0px;
                    width: 270px;

                    &:after {
                        content: "";
                        @include background("../img/right-arrow.png");
                        width: 15px;
                        height: 10px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 49%;
                        transform: translateY(-50%) translateX(-40%);
                    }


                    .Date-picker-range .DateRangePickerInput {
                        background-color: transparent;
                        background-image: none;
                        border: none;
                        box-shadow: none;
                        justify-content: flex-start;
                        min-height: 40px;
                        padding: 0px 0px;
                        margin-right: 0px;
                        min-width: 270px;
                        margin-left: 0px;

                        .DateRangePickerInput_calendarIcon {
                            padding: 0px 5px;
                            margin-left: 10px;
                            margin-top: -5px;
                            margin-right: 0px;
                            font-size: 16px;
                        }

                        .DateInput {
                            margin-right: 0px;
                            margin: 0px;
                            font-size: 14px;
                            margin-left: -2px;
                            background: transparent;

                            .DateInput_input {
                                font-size: 14px !important;
                                line-height: normal;
                                width: 75px;
                            }

                        }

                        .DateRangePickerInput_arrow {
                            margin-left: 5px;
                            font-size: 16px;
                            padding-left: 40px;
                            margin-top: -5px;
                            margin-right: 9px;

                            .icon-arrow {
                                display: none;
                            }

                            .endDate {
                                margin-right: 9px;
                            }
                        }
                    }
                }

                .Date-picker-range {
                    .DateRangePicker_picker {
                        .DayPicker_transitionContainer {
                            height: 100% !important;
                            min-height: 300px;
                            width: 660px;
                        }
                    }
                }
            }
        }
    }


}

.popup.wallet-info-popup {
    .popup-content {
        padding-bottom: 30px;
    }
}


@media (max-width: 991px) {
    .membersCheckoutPageWrap {
        .walletWrapper {
            padding: 0px 5px;

            //  dashbaord-menu-style
            .tab-menu-wrapper {
                padding: 0px 10px;
                padding-bottom: 27px;
            }

            //  select input-field Date-picker  style
            .dateWrapper {
                .dateBox {
                    min-width: 280px;
                    padding: 0px 0px !important;

                    .Date-picker-range .DateRangePickerInput {
                        background-color: none;
                        background: transparent;
                        border: none;
                        box-shadow: none;
                        justify-content: flex-start;
                        min-width: 280px;
                        margin: 0px;
                        padding: 4px 0px;

                        .DateRangePickerInput_calendarIcon {
                            margin-right: 0px;
                            vertical-align: top;
                            margin-top: -3px;
                            margin-left: 15px;
                            padding: 0px 0px;
                            font-size: 16px;
                        }

                        .DateInput {
                            margin: 0px;
                            width: 85px;
                            background: transparent;

                            .DateInput_input {
                                font-size: 14px !important;
                            }
                        }

                        .DateRangePickerInput_arrow {
                            margin-top: -3px;
                            padding-left: 12px;
                            font-size: 16px;

                            .icon-arrow {
                                display: none;
                            }

                            .endDate {
                                margin-right: 10px;
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }

            .card2 {
                table {
                    padding: 0px 0px;

                    tr {
                        td:first-child {
                            flex: 0 0 auto;
                            width: auto;
                            border-radius: 0px 0px 0px 0px;
                        }

                        td col {
                            padding: 0px 0px;
                        }
                    }
                }

            }
        }

        .tabletViewDisplayBlock {
            display: block;
        }
    }

    .membersCheckoutPageWrap.walletPage {

        .mainContent {
            padding-bottom: 0px;
        }
    }
}



@media (max-width: 767px) {
    .membersCheckoutPageWrap {
        .walletWrapper {
            .amount-info {
                .btn img {
                    margin-left: 10px;
                    max-width: 16px;
                }

            }

            //  dashbaord-menu-style
            .tab-menu-wrapper {
                padding: 0px 10px;
                padding-bottom: 20px;
            }


            //  select input-field Date-picker  style
            .dateWrapper {
                .dateBox {
                    min-width: 280px;
                    padding: 0px 0px;

                    .Date-picker-range {
                        margin-top: 0px;
                        padding: 0px 0px;

                        .DateRangePicker {
                            min-width: 280px;

                            .DateRangePickerInput {
                                min-width: 280px;

                                .DateRangePickerInput_calendarIcon {
                                    margin-right: 0px;
                                    vertical-align: top;
                                    margin-top: -1px;
                                    margin-left: 15px;
                                    padding: 0px 0px;
                                    font-size: 16px;

                                }

                                .DateInput {

                                    .DateInput_input {
                                        font-size: 14px;
                                    }
                                }

                                .DateRangePickerInput_arrow {
                                    margin-left: 33px;
                                    margin-top: -1px;

                                    .endDate {
                                        margin-left: -20px;
                                        margin-right: 10px;
                                        font-size: 16px;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .membersCheckoutPageWrap {
        .walletWrapper {
            .card2 {
                .contentWrapper {

                    //  select input-field Date-picker  style
                    .dateWrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        h6 {
                            margin: 6px 0px;
                        }

                        .dateBox {
                            padding: 0px 0px;

                            .Date-picker-range {
                                .DateRangePickerInput {
                                    .DateRangePickerInput_calendarIcon {
                                        margin-right: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width:375px) {
    .membersCheckoutPageWrap {
        .walletWrapper {
            .card2 {
                .contentWrapper {
                    .dateWrapper {

                        .dateBox {
                            padding: 0px 0px;

                            &:after {
                                content: "";
                                @include background("../img/right-arrow.png");
                                width: 15px;
                                height: 10px;
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 47%;
                                transform: translateY(-50%) translateX(-40%);
                                margin-right: 10px;
                            }

                            .Date-picker-range {
                                .DateRangePickerInput {
                                    .DateRangePickerInput_calendarIcon {
                                        margin-right: 10px;
                                        margin-left: 15px !important;
                                    }

                                    .DateRangePickerInput_arrow {
                                        margin-left: 38px;

                                        .endDate {
                                            margin-left: -27px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}