.membersCheckoutPageWrap {
    @include background("../img/members-reward-bg.jpg");
    background-repeat: repeat-y;

    .container {
        max-width: 1120px;
    }

    .sectionTitle {
        padding: 25px 0;

        h2 {
            font-size: 21px;
            font-weight: normal;
            color: $white;
        }
    }

    .rewardsSection {
        @include background("../img/triptek-rewards-bg.jpg");
        padding: 25px 53px 23px 30px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgb(11 170 225);
        color: $white;

        h1 {
            font-size: 25px;
            font-weight: normal;
            margin-bottom: 11px;
        }

        .leftContent {
            max-width: 68%;
            flex: 0 0 68%;

            p {
                font-size: 17px;
                font-weight: normal;
                line-height: 1.24;
                margin-bottom: 6px;
            }

            .container {
                max-width: 621px;
                margin: 0;
                padding: 0;
            }
        }

        .imgContent {
            display: flex;
            align-items: center;
            padding-left: 87px;
            padding-top: 8px;

            .image {
                max-width: 100px;
                flex: 0 0 100px;
                margin-top: -2px;
            }

            .content {
                padding-left: 30px;
            }

            p {
                font-size: 14px;
                line-height: 1.2;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 45px;
                font-weight: normal;
            }
        }

        .rightContent {
            .right-content {
                display: block;
                text-align: left;
            }
        }
    }


    .sharingWrapper {

        .row {
            margin: 0px 0px;
        }

        .card-wrapper {
            margin: 0 -5px;

            .col {
                padding: 0 5px;
            }
        }

        .card {
            padding: 12px 18px 17px 15px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            height: 100%;

            p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 10px;
            }
        }

        .linkIcon {
            display: flex;
            align-items: center;
            margin-bottom: 11px;

            h3 {
                margin-left: 6px;
                font-size: 17px;
                font-weight: 500;
                color: #435b75;
            }
        }

        .copyLinkBox {
            padding: 12px 30px;
            border-radius: 10px;
            border: dashed 1px #1d6ec9;
            max-width: 240px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            margin: 27px auto 36px;

            h4 {
                font-size: 17px;
                font-weight: normal;
                line-height: 1.24;
                color: #1d6ec9;
                margin-left: 12px;
            }
        }

        .sharingIconsList {
            ul {
                padding: 14px 15px 0;
                margin-bottom: 0px;

                li {
                    max-width: 25%;
                    padding: 0 5px 5px;
                    flex: 0 0 25%;
                    margin-bottom: 3px;
                }
            }
        }

        .formWrapper {
            padding-top: 15px;

            .formGroup {
                margin-bottom: 12px;
            }

            h5 {
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 6px;
            }

            .formControl {
                border-radius: 10px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);
                width: 100%;
                font-size: 17px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                padding: 10px 15px 9px;
                @include ubuntu(400);

                @include placeholder-text {
                    color: rgba(67, 91, 117, 0.6);
                    font-weight: normal;
                    @include ubuntu(400);
                }
            }

            .buttonWrap {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
                margin-bottom: 0;

                p {
                    font-size: 12px;
                    font-weight: normal;
                    color: rgba(67, 91, 117, 0.6);
                    padding-right: 25px;
                }

                .boxTitle {
                    font-size: 17px;
                    font-weight: 500;
                    color: #435b75;
                    margin-bottom: 19px;
                }

                .checkbox-label small {
                    font-size: 14px;
                    color: #435b75;
                    font-weight: 400;
                    padding-left: 35px;
                    margin-top: 2px;
                    display: block;
                    cursor: pointer;


                    .btn {
                        font-size: 14px;
                        font-weight: normal;
                        color: $white;
                        border-radius: 20px;
                        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2),
                            -2px -2px 5px 2px #ffffff;
                        background-image: linear-gradient(to right,
                                #3763d0 -12%,
                                #00d0d4 100%);
                        min-width: 140px;
                        margin-bottom: auto;

                        &::before {
                            display: none;
                        }
                    }
                }

                .btn {
                    font-size: 14px;
                    font-weight: normal;
                    color: #ffffff;
                    border-radius: 20px;
                    box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%), -2px -2px 5px 2px #ffffff;
                    background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 100%);
                    min-width: 140px;
                    margin-bottom: auto;


                }

                .btn:before {
                    display: none;
                }
            }

            .qrCodeWrapper {
                max-width: 210px;
                max-height: 210px;
                width: 100%;
                margin: 36px auto 15px;
                padding: 9px;
                border-radius: 10px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);
            }

            .fileDownload {
                display: flex;
                justify-content: center;
                padding-bottom: 15px;

                h5 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #1d6ec9;
                    margin-left: 4px;
                }
            }
        }

        .searchWrapper {
            display: flex;
            justify-content: space-between;

            form {
                max-width: 23.5%;
                flex: 0 0 23.5%;
            }

            .formControl {
                padding: 6px;
                outline: none;
                box-shadow: none;
                border: none;
                border-bottom: solid 1px #ffffff;
                color: $white;
                background: transparent;
                font-size: 14px;
                font-weight: normal;
                width: 100%;
                padding-left: 25px;
                @include ubuntu(400);

                @include placeholder-text {
                    color: $white;
                    @include ubuntu(400);
                }
            }

            .formGroup {
                position: relative;
                display: flex;
                align-items: center;

                .btnImage {
                    background: none;
                    @include background("../img/white-search.png");
                    width: 20px;
                    height: 20px;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }


    }

    .walletWrapper {
        .row {
            margin: 0 -5px;

            .col {
                padding: 0px;
            }

            .rightontent {
                padding: 0px;
            }
        }

        .leftContent {
            max-width: 33.28%;
            flex: 0 0 33.28%;

            .card {
                padding: 25px 31px 31px 29px;
                border-radius: 10px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                background-color: rgba(255, 255, 255, 0.8);
            }

            h3 {
                font-size: 17px;
                font-weight: 500;
                color: #435b75;
                margin-bottom: 6px;
            }

            .price {
                display: flex;
                align-items: center;
                padding-bottom: 28px;

                h2 {
                    font-size: 45px;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #435b75;
                    margin-left: 9px;
                }
            }

            h5 {
                margin-bottom: 9px;
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
            }

            .dateWrapper {
                padding-bottom: 27px;

                .dateBox {
                    position: relative;
                    border-radius: 10px;
                    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #ffffff;
                    border: solid 1px #dae2ee;
                    min-height: 40px;
                    background-color: rgba(255, 255, 255, 0.35);
                    padding: 0 5px;
                }

                .toDate,
                .fromDate {
                    padding: 0 10px;
                    position: relative;

                    &:before {
                        content: "";
                        @include background("../img/date.png");
                        background-size: contain;
                        width: 14px;
                        height: 16px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                    }

                    p {
                        padding-left: 25px;
                        color: #1d6ec9;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }

                .toDate {
                    position: relative;

                    &:after {
                        content: "";
                        @include background("../img/right-arrow.png");
                        width: 15px;
                        height: 10px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 115%;
                        transform: translateY(-50%) translateX(-115%);
                    }
                }

                .fromDate {
                    margin-left: 20px;
                }

                img {
                    height: auto;
                    margin-left: 0;
                }
            }

            .dropdownWrapper {
                padding-bottom: 40px;

                .dropdownBox {
                    max-width: 306px;
                    padding-right: 8px;
                    position: relative;

                    &:after {
                        content: "\f107";
                        display: inline-block;
                        font: normal bold normal 18px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        color: #1d6ec9;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                    }

                    select {
                        appearance: none;
                        -webkit-appearance: none;
                        -ms-appearance: none;
                        padding: 11px 12px 11px;
                        border-radius: 10px;
                        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                            inset -2px -2px 6px 0 #ffffff;
                        border: solid 1px #dae2ee;
                        background-color: rgba(255, 255, 255, 0.35);
                        font-size: 14px;
                        color: #1d6ec9;
                        font-weight: normal;
                        width: 100%;
                    }
                }
            }

            .signUpWrapper {
                .content {
                    padding: 10px 5px 11px;
                    border-radius: 10px;
                    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px #e0e5ec;
                    background-color: #f3f3f3;
                    word-break: break-all;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h4 {
                        font-size: 14px;
                        font-weight: normal;
                        text-align: center;
                        color: rgba(67, 91, 117, 0.6);
                        margin-bottom: 10px;

                        span {
                            display: block;
                        }
                    }

                    h3 {
                        color: #435b75;
                        font-size: 25px;
                        font-weight: normal;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }

                .row {
                    margin: 0 -6px;

                    .col {
                        padding: 0 6px;
                        max-width: 33.33%;
                        flex: 0 0 33.33%;
                    }
                }
            }
        }

        .card2 {
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            padding: 10px 20px;
            margin-bottom: 10px;

            .tabsWrapper {
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                padding: 0 12px;
                margin-bottom: 15px;

                a {
                    display: flex;
                    align-items: center;
                    color: rgba(67, 91, 117, 0.6);
                    font-size: 17px;
                    padding: 0px 18px 9px;
                    font-weight: normal;
                    position: relative;

                    &.active {
                        color: #1d6ec9;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            height: 2px;
                            width: 100%;
                            background-color: #1d6ec9;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            max-width: 97%;
                        }
                    }

                    img {
                        margin-right: 10px;
                    }
                }
            }

            table {
                width: 100%;
                border-spacing: 0px !important;
                padding: 0 16px;

                td {
                    vertical-align: middle;
                    padding: 12.5px;
                    border: none;
                }

                .iconTd {
                    text-align: center;
                }

                h3 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    color: #435b75;
                }

                h4 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;
                    color: #435b75;
                }

                h5 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #435b75;

                    &.greenText {
                        color: #00b7a6;
                        font-weight: 500;
                    }
                }

                tr {
                    &:nth-child(odd) {
                        border-radius: 10px;
                        background-color: rgba(255, 255, 255, 0.5);
                    }

                    td {
                        &:first-child {
                            border-radius: 10px 0 0 10px;
                        }

                        &:last-child {
                            border-radius: 0 10px 10px 0;
                        }
                    }
                }
            }

            .DateRangePickerInput {
                .DateRangePicker_picker {
                    top: 45px !important;
                    left: -260px !important;

                    .DayPicker {
                        table {
                            tr {
                                &:nth-child(odd) {
                                    border-radius: none;
                                    background-color: transparent;
                                    box-shadow: none;
                                }

                                td {
                                    padding: 0px;


                                    &:first-child {
                                        border-radius: 0px;
                                    }

                                    &:last-child {
                                        border-radius: 0px;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .not-found-content {
                width: 95%;
                display: flex;
                background: transparent;
                box-shadow: none;
                margin: 0 auto;
                margin-bottom: 30px;
            }
        }
    }
}


.membersRewardsPageWrap {
    padding: 0;


    .tab-menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 27px;
        padding-top: 20px;

        ul {
            margin: 0 -24px;
            padding: 0;
            list-style: none;
            display: flex;

            li {
                padding: 0 24px;

                a {
                    color: $white;
                    font-size: 21px;
                    font-weight: normal;
                    position: relative;
                    transition: 0.3s;

                    &:hover {
                        &:after {
                            content: "";
                            display: block;
                            background-color: $white;
                            height: 1px;
                            width: 100%;
                            position: absolute;
                            bottom: -3px;
                        }
                    }
                }

                .active {
                    &:after {
                        content: "";
                        display: block;
                        background-color: $white;
                        height: 1px;
                        width: 100%;
                        position: absolute;
                        bottom: -3px;
                    }
                }
            }
        }

        .details-btn {
            font-size: 14px;
            font-weight: normal;
            min-width: auto;
            background: none;
            box-shadow: none;
            padding: 0;
            display: flex;
            align-items: center;
            margin-right: 24px;

            img {
                margin-right: 8px;
            }

            &::before {
                display: none;
            }
        }

        .mobile-details-btn {
            display: none;
        }
    }

    .border-bottom {
        border-bottom: 1px solid rgba(67, 91, 117, 0.2);
    }

    .border-left {
        border-left: 1px solid rgba(67, 91, 117, 0.2);
    }

    .section-title {
        padding: 21px 0;

        h2 {
            font-size: 21px;
            font-weight: normal;
            color: $white;
        }
    }

    .commission-content-wrapper {
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);

        .content {
            padding: 20px 16px;

            h2 {
                font-size: 45px;
                font-weight: 300;
                color: #1d6ec9;
            }

            h6 {
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 4px;
            }

            h5 {
                font-size: 25px;
                font-weight: normal;
                color: #435b75;
            }
        }

        .col {
            max-width: 25%;
            flex: 0 0 25%;
        }
    }

    .commission-content-tabs {
        padding-bottom: 30px;

        // overflow-x: auto;
        // overflow-y: hidden;
        .active-tab {
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 23px solid transparent;
                border-right: 23px solid transparent;
                border-bottom: 23px solid rgba(255, 255, 255, 0.8);
                position: absolute;
                bottom: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .row {
            margin: 0 -7.5px 10px;
            flex-wrap: initial;

            .col {
                padding: 0 7.5px;
                max-width: 20%;
                flex: 0 0 20%;
                // min-width: 220px;
            }
        }

        .content {
            padding: 10px 13px 10px 10px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            position: relative;

            .anchor-tab {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
            }

            h5 {
                text-align: right;
                font-size: 19px;
                font-weight: normal;
                color: #00b7a6;
            }
        }

        .image {
            width: 45px;
            height: 45px;
            flex: 0 0 45px;
            max-width: 45px;
            border-radius: 25px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #fff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .heading {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            h6 {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.21;
                color: #435b75;
                margin-left: 10px;
                min-height: 34px;
            }
        }
    }

    .commission-content-info {
        padding: 20px 15px 15px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        display: none;
        margin-bottom: 20px;

        &.active-content {
            display: block;
        }

        .light-green-text {
            color: #00b7a6;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(67, 91, 117, 0.2);
            padding-bottom: 15px;

            h3 {
                font-size: 21px;
                font-weight: normal;
                color: #435b75;
            }

            .right-content {
                display: flex;

                .tottal-box {
                    padding: 3px 12px 4px;
                    border-radius: 6px;
                    border: solid 1px #00b7a6;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    align-self: center;

                    span {
                        font-weight: 500;
                    }
                }

                .dropdownWrap {
                    min-width: 195px;

                    .toggleBtn {
                        width: 100%;
                    }

                    i {
                        color: #1d6ec9;
                        font-size: 21px;
                    }
                }

                .dropdownInWrap {
                    li {
                        font-size: 14px;
                    }
                }

                .btnSml {
                    border-radius: 10px;
                    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px rgba(210, 218, 230, 0.6);
                    background-color: rgba(255, 255, 255, 0.35);
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(67, 91, 117, 0.6);
                }
            }
        }

        .content-wrapper {
            padding: 0 15px;

            h4 {
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                color: rgba(67, 91, 117, 0.6);
            }

            .row {
                min-height: 50px;
                align-items: center;
                border-radius: 10px;

                &:nth-child(even) {
                    background-color: rgba(255, 255, 255, 0.5);
                }

                .col {
                    text-align: center;

                    &:last-child {
                        text-align: right;
                    }

                    &:first-child {
                        text-align: left;
                    }
                }
            }

            h5 {
                font-size: 14px;
                font-weight: normal;
                color: #435b75;
            }

            .light-green-text {
                color: #00b7a6;
                font-weight: 500;
            }
        }
    }

    .membersCheckoutPageWrap {
        .container {
            padding: 0px 0px;
        }

        .mainContent {
            padding-top: 0px;
        }

        .pageInternalScrollTopPadding {
            padding-top: 0px;
        }
    }
}

.membersRewardsPageWrap {
    .affiliate-invites-wrapper {
        .container {
            margin-left: 0;
        }


    }

    .marketing-page-wrapper {
        padding-bottom: 20px;

        .premium-members-invites {
            padding: 22px 40px 10px;
        }

        .overview-content-wrapper {
            .overview-content {
                li {
                    padding: 9px 10px;

                    // add css for Marketing Page (Tripello Overview Icon)

                    a {
                        display: inline-flex;
                        align-items: center;
                        cursor: pointer;
                        color: inherit;
                    }
                }
            }
        }

        .commission-content-info {
            .heading {
                padding-bottom: 21px;
            }
        }
    }
}

.premium-plus-member-rewards-pageWrap {
    padding-bottom: 10px;

    .rewardsSection {
        padding-bottom: 25px;
    }

    .sectionTitle {
        padding: 27px 0 28px;
    }

    .searchWrapper {
        padding: 25px 0 26px;
    }

    .sharingWrapper {
        .linkIcon {
            margin-bottom: 6px;
        }
    }

    .walletWrapper {
        .leftContent {
            .dropdownWrapper {
                .dropdownBox {
                    padding-right: 0;
                }
            }
        }
    }
}



.marketingDashboard {
    .container {
        max-width: 1110px;
    }

    .premium-wrap {

        padding-bottom: 30px;
    }

    .afilliate-wrap {
        padding-bottom: 30px;
    }

    .premium-members-invites {
        padding: 22px 40px 10px;

        .leftContent {
            .container {
                max-width: 710px;
                padding: 0;
                margin: 0;
            }
        }

        h1 {
            font-size: 25px;
            font-weight: normal;
            color: #ffffff;
            margin-bottom: 15px;
        }
    }

    .rewardsSection {

        .leftContent {
            p {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.21;
                margin-bottom: 18px
            }
        }

        .rightContent {
            .right-content {
                display: none;
            }
        }

        .imgContent {
            padding-left: 136px;
            padding-top: 2px;
        }
    }

    .sharingWrapperSpace {
        padding-bottom: 0px;
    }

    .sharingWrapper {
        .card {
            padding: 12px 16px 10px 15px;

        }

        .row {
            margin: 0px -10px;
        }
    }
}


@media (max-width:991px) {
    .rewards-page {
        .mobileSharingWrapper {
            display: block;
        }
    }

}

@media(max-width:991px) {

    .marketingDashboard {
        .mainContent {
            padding-bottom: 0px;
        }

        .premium-wrap,
        .afilliate-wrap {
            padding-bottom: 0px;
        }

        .premium-members-invites {
            padding: 22px 40px 10px;

            .leftContent {
                max-width: 72%;
                flex: 0 0 72%;

                br {
                    display: none;
                }

            }


            h1 {
                font-size: 25px;
                font-weight: normal;
                color: #ffffff;
                margin-bottom: 15px;
            }
        }



        .mobileSharingWrapper.sharingWrapper {
            .card {
                .tabsWrapper {
                    ul {
                        li.active {
                            padding: 0 17px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .membersCheckoutPageWrap {
        .sharingWrapper {
            .sharingIconsList {
                ul {
                    justify-content: center;
                }
            }
        }
    }
}


@media (max-width:767px) {
    .rewards-page {
        .pageWrap .mainContent {
            padding-bottom: 0;

            .premium-plus-member-rewards-pageWrap {
                padding-bottom: 40px;
            }
        }
    }

    .membersRewardsPageWrap {
        .mainContent {
            .rewardsSection {
                padding: 17px 10px 31px 20px;

            }
        }
    }

    .marketingDashboard {
        .mainContent {
            .premium-members-invites {
                padding: 26px 10px 9px;

                .content-title {
                    align-items: flex-start;

                    h1 {
                        font-size: 21px;
                        margin-bottom: 18px;
                    }
                }
            }
        }

        .rewardsSection {

            .rightContent {
                .imgContent {
                    display: none;
                }
            }
        }

        .sharingWrapper {
            .card {
                padding: 7px 0 14px;
            }

        }
    }

    .membersCheckoutPageWrap {
        .walletWrapper {
            .card2 {
                .DateRangePickerInput {
                    .DateRangePickerInput_calendarIcon {
                        .startDate {
                            .icon-invitation {
                                margin-right: 0px;

                            }
                        }
                    }

                    .DateRangePicker_picker {
                        top: 50px !important;
                        left: 0px !important;

                    }

                    .DateRangePickerInput_arrow {
                        width: 60px;
                        margin-top: -3px;

                        .endDate {
                            margin-left: 12px;

                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 480px) {
    .marketingDashboard {
        .sharingWrapper {
            .card {
                .sharingIconsList {
                    ul {
                        li {
                            max-width: 25%;
                            flex: 0 0 25%;
                        }
                    }
                }
            }
        }
    }
}