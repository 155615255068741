.confirmationPageWrap {
    @include background("../img/members-checkout-bg.jpg");

    .confirmationWrapper {
        padding-top: 0px;

        @media(max-width:991px) {
            padding-top: 20px;
        }
    }

    .card2 {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .leftContent {
        max-width: 69.3%;
        flex: 0 0 69.3%;
        padding-bottom: 50px;

        .card2 {
            margin-bottom: 10px;

            h4 {
                font-size: 19px;
                color: #1d6ec9;
                font-weight: 400;
                margin-bottom: 14px;

            }

            .guestTableWrap {
                display: block;
                overflow: hidden;
                overflow-y: auto;
            }

            .guestTable {
                background-color: transparent;
                color: #000;
                width: 100%;
                border: none;

                @media(max-width:1024px) {
                    white-space: nowrap;
                    display: block;
                    overflow-y: auto;
                }

                tbody {
                    td {
                        padding: 0px 3px;
                        width: 20%;

                        .table-value {
                            display: block;
                            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                            -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                            -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
                            border: solid 1px #dae2ee;
                            background-color: rgba(255, 255, 255, 0.35);
                            color: #435b75;
                            font-size: 14px;
                            padding: 6px;
                            border-radius: 6px;
                            width: 100%;
                            margin-right: 5px;

                        }


                    }

                    td:first-child {
                        padding-left: 0px;
                    }

                }


                td {
                    padding: 5px;
                    color: #435b75;
                    font-size: 12px;


                }

            }







            .buttonWrap {
                display: flex;
                justify-content: center;
                padding-top: 28px;
            }

            .btn,
            .btnBorder {
                border-radius: 20px;
                font-size: 17px;
                font-weight: 400;
                padding: 9.5px;
                min-width: 180px;
                height: 40px;
                margin: 0 5px 10px;

                &::before {
                    display: none;
                }

                img {
                    margin-right: 7px;
                }
            }

            .messageWrapper {
                border-radius: 8px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);
                padding: 29px 0 24px;

                .container {
                    max-width: 530px;
                    padding: 0;
                    text-align: center;
                }

                h1 {
                    font-size: 25px;
                    color: #1d6ec9;
                    font-weight: 400;
                    margin-bottom: 5px;
                }

                p {
                    color: #435b75;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 1.3;
                    margin: 15px 0px;
                }
            }

            .imgContentWrapper {
                padding: 10px 0 0;

                .contentWrap {
                    flex: 0 0 54.1%;
                    max-width: 54.1%;
                    padding-left: 5px;
                    padding-right: 10px;

                    h3 {
                        font-size: 21px;
                        color: #1d6ec9;
                        font-weight: 400;
                        margin-bottom: 8px;
                    }

                    .topSection {
                        margin-bottom: 15px;
                    }

                    .content {
                        max-width: 316px;
                    }

                    .contactInfo {

                        .location,
                        .phone {
                            font-size: 14px;
                            color: #435b75;
                            display: flex;
                            align-items: center;
                            margin-bottom: 4px;

                            img,
                            .icon-phone {
                                margin-right: 6px;
                                margin-bottom: auto;
                            }

                            .icon-phone {
                                opacity: 0.6;
                            }
                        }
                    }

                    .timeWrapper {
                        max-width: 240px;
                        margin: 14px 0 20px;

                        h5 {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(67, 91, 117, 0.6);

                            img {
                                margin-right: 6px;
                            }
                        }

                        h4 {
                            font-size: 17px;
                            font-weight: 500;
                            color: #435b75;
                        }

                        .dateWrapper {
                            margin-top: 3px;

                            .col {
                                position: relative;

                                &:first-child {
                                    &:after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        top: 28%;
                                        left: 95%;
                                        transform: translate(-95%, -50%);
                                        @include background("../img/right-arrow.png");
                                        background-size: contain;
                                        height: 16px;
                                        width: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                .infoDetail {
                    max-width: 340px;
                    border-top: 1px solid rgba(67, 91, 117, 0.2);
                    padding: 21px 0;

                    .d-flex {
                        margin-bottom: 10px;
                    }

                    h4 {
                        font-size: 14px;
                        color: rgba(67, 91, 117, 0.6);
                        font-weight: 400;
                    }

                    h5 {
                        font-size: 14px;
                        color: #435b75;
                        font-weight: 500;
                    }
                }

                .imgWrap {
                    width: 100%;
                    padding: 0;
                    border: none;
                    box-shadow: none;
                    background-color: transparent;

                    img {
                        border-radius: 10px;
                        width: 100%;
                    }
                }

                .mapWrap {
                    margin-top: 10px;
                    border-radius: 8px;
                    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #ffffff;
                    -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #ffffff;
                    -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #ffffff;
                    border: solid 1px #dae2ee;
                    background-color: rgba(255, 255, 255, 0.35);
                    text-align: center;
                    padding: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    iframe {
                        height: 100%;
                        width: 100%;
                        max-height: 142px;
                    }
                }
            }
        }

        .cancellation-policy {
            padding: 16px 30px 30px 10px;

            p {
                font-size: 14px;
                line-height: 17px;
                color: #435b75;
                line-height: normal;
            }

            .mr-2 {
                margin-right: 7px;
            }

            ul,
            ol {
                padding-left: 17px;

                li {
                    padding-bottom: 7px;
                }
            }
        }
    }

    .rightContent {
        .card2 {
            margin-bottom: 10px;

            &.cardSummary {
                .sectionTitle {
                    padding: 5px 0 0 5px;
                }
            }

            .sectionTitle {
                h2 {
                    font-size: 17px;
                    font-weight: 500;
                    color: #435b75;
                    margin-bottom: 6px;
                }
            }

            h3 {
                font-size: 14px;
                color: rgba(67, 91, 117, 0.6);
                font-weight: 400;

                strong {
                    color: #435b75;
                    font-weight: 500;
                    margin-left: 5px;
                }
            }

            .contentInfo {
                margin-top: 28px;
                padding: 18px 12px 12px 13px;

                .d-flex {
                    margin-bottom: 6px;
                }

                h4 {
                    font-size: 14px;
                    color: rgba(67, 91, 117, 0.6);
                    font-weight: 400;
                }

                h5 {
                    font-size: 17px;
                    color: #435b75;
                    font-weight: 400;
                }

                .paidInfo {
                    border-top: 1px solid rgba(67, 91, 117, 0.2);
                    padding-top: 13px;

                    h5 {
                        font-size: 21px;
                        font-weight: 500;
                    }
                }
            }
        }

        .cardHelp {
            .links {
                a {
                    display: flex;
                    align-items: center;

                    span {
                        color: #1d6ec9;
                        padding: 0px 5px;
                        font-size: 13px;
                    }

                    .arrow-img {
                        height: 11px;
                    }
                }
            }
        }

        .card2 {
            // padding: 3px 20px;


            .cardHelp {
                padding: 17px 11px;

                .sectionTitle {
                    padding-bottom: 12px;
                }

                .links {
                    a {
                        font-size: 14px;
                        color: #1d6ec9;
                        display: inline-flex;
                        align-items: center;

                        span {
                            margin: 0 6px;
                        }
                    }
                }
            }
        }
    }
}

.ReactModalPortal {

    .popup-wrapper {
        display: block;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-color: rgba(67, 91, 117, 0.6);
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .popup-content {
            top: 50%;
            margin: 0px;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .model-header {
            justify-content: center;
            border-bottom: 1px solid #e4dff3;

            h4 {
                font-size: 17px;
                color: #1d6ec9;
                font-weight: 400;
                margin-bottom: 20px;
            }
        }

        .formGroup {
            padding: 20px 0px;
            border-bottom: 1px solid #e4dff3;

            h5 {
                font-size: 17px;
                font-weight: 500;
                color: #435b75;
                line-height: 16px;
                margin-bottom: 10px;

            }
        }

        .buttonWrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn:before {

                left: 0px;
                top: 15px;
            }

            .cancel-btn {
                border: none;
                font-size: 14px;
                margin-left: 10px;
                color: #1d6ec9;
                background: transparent;
            }
        }
    }
}

/*Confirmation page end*/

@media(min-width :992px) {
    .confirmationPageWrap {
        .mainContent {
            .pageInternalScroll {
                padding-top: 20px;
                height: calc(100vh - 120px) !important;
                overflow-y: auto;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }
    }
}