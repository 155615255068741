.siteBg.MembershipCheckoutPageWrap {
    @include background("../img/members-checkout-bg.jpg");
    background-repeat: repeat-y;

    .card2 {
        &:last-child {
            margin-bottom: 10px;

        }

        .member-no-wrap {
            margin-bottom: 16px;

            .col {
                padding: 0px 0px
            }
        }
    }

    .membership-content {
        @include background("../img/membership-premium-plan.png");
        border-radius: 6px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .logo1 {
            margin-top: 1px;
        }

        .planName {
            margin-top: 10px;
            color: rgba(255, 255, 255, 0.9);
            font-size: 19px;
        }

        p {
            color: rgba(255, 255, 255, 0.9);
            font-size: 14px;
            font-weight: normal;
            margin-top: 2px;
        }

        .plus-img {
            position: absolute;
            top: 6px;
            right: 8px;
        }
    }

    .row {
        margin: 0 0px;
        flex-wrap: nowrap;
    }

    .leftContent {
        max-width: 71.6%;
        flex: 0 0 71.6%;
        padding-right: 30px;
        padding-left: 0px;

        .card2 {
            .email-section {
                .formGroup {
                    width: 33.33%;
                    flex: 33.33%;

                    .col.formGroup {
                        width: 100%;

                    }
                }
            }

            .row {
                margin: 0 -7.5px 13px;
            }

            .checkBoxContent {
                justify-content: flex-start;
            }

            .checkBoxWrap .customCheckbox input:checked+span:after {
                left: 5px;
                top: 5px;
            }

            ul .customCheckbox input:checked+span:after {
                left: 5px;
            }

            .dropdown .selectWrap:after {
                background-image: none;
            }
        }
    }

    .email-section {
        .formGroup {
            .dropdown {
                padding: 0;
            }
        }
    }

    .card2 {
        .email-section {
            .formGroup {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

        }
    }

    .rightContent {
        padding: 5px 5px 21px;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: auto;
        max-width: 320px;
        flex: 0 0 320px;

        .infoBox {
            min-height: 130px;
            padding: 5px;
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .features {
        padding: 0 14px 0 10px;

        ul {
            margin: 0;
            padding: 17px 0 12px;
            list-style-type: none;

            li {
                font-size: 14px;
                font-weight: normal;
                line-height: normal;
                color: #435b75;
                position: relative;
                padding-left: 24px;
                margin-bottom: 6px;

                &:before {
                    content: "\e963";
                    color: #00b7a6;
                    font-family: "icomoon" !important;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 14px;
                    height: 11px;
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    font-weight: bold;
                    font-size: 12px;

                }
            }
        }

        p {
            font-size: 14px;
            font-weight: normal;
            color: rgba(67, 91, 117, 0.6);
        }

        .priceInfo {
            border-top: 1px solid rgba(67, 91, 117, 0.2);
            text-align: center;
            max-width: 270px;
            margin: 0 auto;
            margin-top: 15px;
            padding-top: 11px;

            h6 {
                font-size: 16px;
                color: #435b75;
                font-weight: 400;

                strong {
                    color: #435b75;
                    font-weight: 500;
                    margin-left: 15px;
                    font-size: 22px;
                }
            }
        }
    }

    .billingInfo {
        .sectionTitle {
            margin-bottom: 19px;
        }

        .checkBoxContent {
            .customCheckbox {
                p {
                    padding-left: 35px;
                    padding-right: 30px;
                }
            }
        }
    }



    .checkBoxWrap {
        .checkBoxContent {
            .checkBoxContentWrap {
                p {
                    padding-left: 35px;
                    margin-top: 3px;
                    padding-right: 30px;
                }
            }
        }
    }
}

.MembershipCheckoutPageWrap {
    .fixedHeader {
        .header {
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
            background-color: #eff0f5;
            padding-top: 9.5px;

            .logo-sticky {
                display: block;
            }

            .logo1 {
                display: none;
            }
        }
    }

}

.freemiumsignupPageWrap.header-sticky {
    .header {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        background-color: #eff0f5;
        padding-top: 9.71px;
    }

    .logoWrap {
        .logo1 {
            display: none;
        }

        .logo-sticky {
            display: block;
        }
    }
}

// Freemium-signup-page-start
.freemiumsignupPageWrap {
    .header {
        background-color: transparent;
        box-shadow: none;
        padding-top: 30px;
    }

    .footer {
        position: relative;
        background-color: #d1ebff;

        .snsIcons {
            svg {
                filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.3));

                * {
                    fill: rgba(255, 255, 255, 0.6049) !important;
                }
            }
        }
    }

    .pageInternalScroll {
        z-index: 2;

        >div {
            height: 100%;
            overflow-x: hidden !important;

            @media (max-width: 1199px) {
                margin: 0 !important;
            }
        }
    }

    .skew-background {
        width: 100%;
        height: 260px;
        position: relative;
        opacity: 0.8;
        background-color: transparent;
        background-image: linear-gradient(-5deg,
                #eaebf2 0,
                #eaebf2 61%,
                rgba(234, 235, 242, 0) 62%,
                rgba(234, 235, 242, 0) 60%);
        position: fixed;
        bottom: 0;
        z-index: 0;
    }

    .freemium-getstarted-btn {
        display: none;
    }

    .mainContent {
        padding-top: 3px;
    }

    .signupWrapper {
        position: relative;
        z-index: 2;
        padding: 50px 0 50px;
    }

    .page-logo {
        padding-bottom: 15px;
    }

    .left-content {
        max-width: 62.25%;
        flex: 0 0 62.25%;
    }

    .left-content-wrapper {
        max-width: 575px;
        padding-top: 35px;

        h1 {
            @include ubuntu(300);
            font-size: 30px;
            line-height: normal;
            color: #fff;
            margin-bottom: 14px;
        }

        h2 {
            font-size: 26px;
            @include ubuntu(300);
            line-height: normal;
            color: #fff;
            margin-bottom: 18px;

            strong {
                font-weight: 500;
            }

            @media (max-width: 1199px) {
                font-size: 25px;
            }
        }

        .way-wrapper {
            padding: 13px 10px 7px 19px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.1);
            margin-bottom: 20px;
            max-width: 530px;

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                list-style-type: none;
                margin: 0;
                padding-left: 30px;
                width: 100%;
            }

            li {
                margin-bottom: 10px;
                min-width: 135px;
                padding: 0 5px;

                h6 {
                    @include ubuntu(300);
                    font-size: 17px;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #fff;
                    position: relative;

                    &:before {
                        content: "";
                        @include background("../img/signup/close-circle.png");
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        left: -30px;
                        top: 0;

                        @media(max-width:374px) {
                            height: 16px;
                            width: 16px;
                        }
                    }

                    @media(max-width:374px) {
                        font-size: 12px !important;
                    }
                }
            }

            h5 {
                @include ubuntu(300);
                font-size: 21px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-bottom: 16px;

                strong {
                    font-weight: 500;
                }
            }
        }

        h3 {
            font-size: 21px;
            @include ubuntu(300);
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 15px;

            strong {
                font-weight: 500;
            }
        }

        .list-content {
            ul {
                list-style-type: none;
            }

            li {
                @include ubuntu(300);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-bottom: 6px;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .infoBox {
        width: 100%;
        min-height: 130px;
        padding: 5px;
        background-color: transparent;
        border: none;
        box-shadow: none;

        .membership-content {
            @include background("../img/signup/info-box.jpg");
            border-radius: 6px;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
        }

        .planName {
            @include ubuntu(400);
            font-size: 19px;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            padding-top: 12px;
        }
    }

    .right-box-content {
        max-width: 400px;
        width: 100%;
        padding: 4px 5px 31px;
        border-radius: 10px;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #d2dae6;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    }

    .form-wrapper {
        padding: 15px 22.5px 2px;

        .form-group-wrap {
            margin-bottom: 15px;
        }

        .form-group {
            width: 100%;
            height: 47px;
            border-radius: 10px;
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            margin-bottom: 0;
            display: flex;

            +.error-messages {
                margin-left: 43px;
                // margin-top: -6px;
            }

            .icon {
                max-width: 41px;
                flex: 0 0 41px;
                border-right: solid 1px #d2dae6;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .form-control {
                border: none;
                background-color: transparent;
                width: 100%;
                @include ubuntu(400);
                font-size: 17px;
                line-height: normal;
                letter-spacing: normal;
                color: #1d6ec9;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #fff;
                padding: 0 16px;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
                -webkit-appearance: none;

                @include placeholder-text {
                    color: #1d6ec9;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus {
                transition: font-size #d2dae6 0s !important;
                -webkit-animation: 0s;
                font-size: 17px;
                -webkit-text-fill-color: #1d6ec9 !important;
                -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #fff inset !important;
                -webkit-background-color: #d2dae6 !important;
            }


            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                border: none !important;
                -webkit-text-fill-color: #1d6ec9 !important;
                -webkit-box-shadow: none !important;
                -webkit-transition: "color 0s ease-out, background-color  font-size 0s ease-out";
                -webkit-transition-delay: 0s;
                font-size: 17px;
                background: transparent;
            }


            .removeSelectPadding {
                .custom__control {
                    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #fff;
                    cursor: pointer;
                }
            }
        }

        .checkBoxContent {
            margin-top: 18px;

            p {
                padding-left: 35px;
                color: #435b75;
                font-size: 14px;
                @include ubuntu(400);
            }

            a {
                color: #1d6ec9;
            }

            input:checked+span:after {
                left: 6px;
                top: 5px;
            }

            .customCheckbox>span:before {
                width: 22px;
                height: 22px;
            }
        }

        .buttonWrap {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            flex-wrap: wrap;

            .content {
                padding-right: 5px;
            }

            h4 {
                @include ubuntu(500);
                font-size: 21px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 5px;
            }

            h6 {
                @include ubuntu(400);
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            .btn {
                min-width: 170px;
                min-height: 45px;
                background-color: transparent;
                @include background("../img/signup/blue-btn-bg.png");
                display: block;

                &::before {
                    display: none;
                }
            }
        }
    }
}

// Freemium-signup-page-End
.MembershipCheckoutPlusPageWrap {
    .infoBox .membership-content {
        @include background("../img/planBg3.jpg");
    }
}

// Newfreemium-signup-page-start
.newfreemiumsignupPageWrap {
    .signupWrapper {

        padding: 70px 0 50px;
    }

    .left-content-wrapper {
        h3 {
            font-size: 29px;
        }
    }

    .freemiumsignupBanner {
        min-height: 100vh;
    }

    .scrollbtn-wrap {
        display: block;
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .scrollBtm {
        position: absolute;
        bottom: 23px;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        z-index: 1;
        cursor: pointer;

        .mousey-wrap {
            height: 80px;
            width: 80px;
            border: 1px solid #ffffff;
            margin: 0 auto;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            svg {
                max-width: 10px;
                margin-top: 3px;

                * {
                    fill: #00e0e5;
                    stroke-width: 2px;
                }
            }
        }

        .mousey {
            width: 3px;
            padding: 8px 7px;
            height: 12px;
            border: 2px solid #00e0e5;
            border-radius: 25px;
            opacity: 0.75;
            margin: 0 auto;
            box-sizing: content-box;
        }

        .scroller {
            width: 3px;
            height: 6px;
            border-radius: 25%;
            background-color: #00e0e5;
            animation-name: scroll;
            animation-duration: 2.2s;
            animation-timing-function: cubic-bezier(.15, .41, .69, .94);
            animation-iteration-count: infinite;
        }

        @keyframes scroll {
            0% {
                opacity: 0;
            }

            10% {
                transform: translateY(0);
                opacity: 1;
            }

            100% {
                transform: translateY(15px);
                opacity: 0;
            }
        }
    }

    .planCont {
        .container {
            max-width: 730px;
            padding: 0px;
        }

        .choosePlan {

            flex-wrap: wrap;


            .freemium-member-col {
                max-width: 100%;
                flex: 0 0 100%;

                .planImgInner {
                    height: 180px;


                }

                .planDetails {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        max-width: 50%;
                        flex: 0 0 50%;
                        font-size: 16px;
                        position: relative;
                        margin-bottom: 15px;
                        padding-left: 40px;

                        .icon-tick {
                            font-size: 18px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                        }
                    }
                }

            }
        }

        .choosePlanOrTitle {
            text-align: center;
            padding: 30px 0;

            h6 {
                color: #ffffff;
                font-size: 24px;
                font-weight: normal;
            }
        }

        .twoColchoosePlan {
            .col {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }
    }
}

// Newfreemium-signup-page-end


// Premium-plus-signup-page start

.premiumPlusSignUpPageWrap {
    .featured-on-icons {
        padding: 30px 0;
        text-align: center;
    }

    .full-video-main-wrapper {

        .video-box-bottom-content {
            background-color: #d3effe;
            margin: 10px 0;
            border-radius: 8px;
            text-align: center;
            padding: 10px 12px;

            h3 {
                font-size: 15px;
                font-family: "Ubuntu", sans-serif;
                font-weight: 500;
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 0;

            }
        }

        .full-video-wrapper {
            .video-box {
                padding-bottom: 0px;

                .videoPremiumPlus {
                    border-radius: 10px;
                    overflow: hidden;
                    min-height: 520px;
                    padding-bottom: 56.25%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url('../../../img/membership-upgrade/member-signup-videoBox.png');
                }


            }


        }

        .video-play-btn {

            z-index: 0;

        }
    }

    .full-video-wrapper {
        .gradiantBg {
            margin-top: 0px;
            padding: 0px;
        }

    }

    .container {
        .upgrade-now-wrapper {
            background-image: url("../../../img/premium-sign-up/upgrade-now-wrapper-bg.png ");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
            padding: 50px 54px 47px;
            margin: 0 -15px;


            strong {
                font-weight: 500;
            }

        }

    }

}



// affiliate-signup-landing-page end
.premiumPlusSignUpPageWrap {
    .upgrade-now-wrapper {
        @include background("../img/premium-sign-up/upgrade-now-wrapper-bg.png");
        background-size: 100% 100%;
        padding: 50px 54px 47px;
        margin: 0 -15px;

        h2 {
            @include ubuntu(300);
            font-size: 35px;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 11px;

            strong {
                font-weight: 500;
            }
        }

        p {
            @include ubuntu(300);
            font-size: 19px;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 7px;
        }

        .right-content {
            max-width: 34%;
            flex: 0 0 34%;

            .right-content-wrap {
                max-width: 280px;
                text-align: center;
                margin: 0 0 0 auto;
                padding-right: 10px;
            }

            .page-logo,
            .button-wrap {
                margin: 0 auto;
            }

            h1 {
                @include ubuntu(300);
                font-size: 21px;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                margin: 13px 0 26px;
            }
        }
    }

    .start-saving-wrapper {
        p {
            font-size: 21px;
        }

        .left-content-wrapper {
            max-width: 505px;
        }
    }

    .grid-layout-wrap {
        .section-title {
            h3 {
                font-family: "Ubuntu", sans-serif;
                font-size: 35px;
                font-weight: 300;
                margin-bottom: 13px;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                strong {
                    font-weight: 500;
                }
            }
        }
    }
}

.affiliateUpgradeCheckoutPageWrap {
    .promocode-row {
        .promoInputCol1 {
            width: 100%;
        }

        .promoInputCol2 {
            max-width: 175px;
            flex: 0 0 175px;
            width: 100%;
            margin-top: 20px;
            padding-left: 7px;

            @media (max-width:767px) {
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0 0 12.5px;
            }
        }
    }

    .billingInfo {
        .checkBoxWrap {
            .checkBoxContent {
                margin-bottom: 15px;

                p {
                    // margin-top: 0 !important;
                    line-height: 14px;
                }
            }
        }
    }

    @media (max-width:767px) {
        .billingInfo {
            .buttonWrap {
                text-align: center;
            }
        }
    }

}

@media (max-width:1200px) {
    .siteBg.MembershipCheckoutPageWrap {
        .row {
            margin: 0px 0px;
            flex-wrap: nowrap;
        }


        .rightContent {
            margin-right: -2px;
        }
    }
}

@media (min-width:1024px) {

    .indexwrap-freemium {
        .triptek-inventory-wrapper {
            .colCard {
                margin: 0 -10px;

                .col {

                    padding: 0 9px 15px;
                }
            }
        }
    }
}


@media(max-width:992px) {

    .newfreemiumsignupPageWrap {
        .freemiumsignupBanner {
            min-height: 90vh;
        }

        .scrollBtm {

            .mousey {
                width: 2px;
                padding: 9px 9px;
                height: 12px;

            }

            .scroller {
                width: 2px;
                height: 6px;
            }
        }
    }
}

@media (max-width:992px) {
    .checkoutPageWrap {
        .card2 {
            .promoInputCol2 {
                width: 25%;
            }
        }
    }
}

// checkoutpage internalscroll bar 
@media(min-width:992px) {
    .siteBg.MembershipCheckoutPageWrap {
        .mainContent {
            padding-bottom: 0px;
            padding-top: 55px;

            .pageInternalScroll {

                height: calc(100vh - 120px) !important;

            }
        }

    }


}


@media (max-width:991px) {
    .header-sticky {

        .freemiumsignupPageWrap {
            .header {
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
                background-color: #eff0f5;
                padding-top: 9.71px;
            }

            .logoWrap {
                .logo1 {
                    display: none
                }

                .logo-sticky {
                    display: block;
                }
            }
        }

    }

    .MembershipCheckoutPageWrap {
        .pageInternalScrollTopBottomPadding {
            // padding-top: 50px !important;
        }
    }

    .siteBg.freemiumsignupPageWrap {
        .mainContent {
            min-height: calc(100vh - 65px);
        }
    }

    .premiumPlusSignUpPageWrap {
        .container {
            .upgrade-now-wrapper {
                padding: 40px 30px;
                margin: 0 -7px;
            }
        }

        .mainContent {
            padding-bottom: 30px;
        }

        .full-video-main-wrapper {
            .full-video-wrapper {
                .sideSpace {
                    padding: 0px;
                }

                .video-box {
                    min-height: 350px;

                    .videoPremiumPlus {
                        height: 350px;
                        min-height: 350px;

                    }
                }

                .video-box {
                    .video-play-btn {
                        img {
                            max-width: 70px;
                        }
                    }
                }
            }
        }
    }

    .checkoutPageWrap {
        .card2 {
            .promoInputCol1 {
                width: 100%;
            }

            .promoInputCol2 {
                width: 20%;
            }
        }
    }

    // newfreemiumsignupPageWrap start

    .newfreemiumsignupPageWrap {
        .freemiumsignupBanner {
            .left-content-wrapper {
                h3 {
                    font-size: 18px;
                }
            }
        }

        .planCont {
            .container {

                padding: 0px 10px;
            }
        }
    }

    .indexwrap-freemium {
        .triptek-inventory-wrapper {
            .colCard {
                margin: 0;
                padding: 0px 10px;

                .col {
                    padding: 0px 9px 15px;
                }
            }
        }

    }

    // newfreemiumsignupPageWrap End
}

@media (min-width: 768px) and (max-width: 991px) {
    .siteBg.MembershipCheckoutPageWrap {
        .row {
            flex-wrap: wrap-reverse;
            display: flex;
            margin: 20px 0px;
        }

        .promocode-row {
            flex-wrap: nowrap;
        }

        .leftContent {
            max-width: 100%;
            flex: 0 0 100%;
            padding-right: 0px;
            padding-left: 0px;
        }

        .rightContent {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 16px;
            order: 1;

        }

        .features {
            ul {
                li {
                    font-size: 13px;
                }
            }

            .priceInfo {
                max-width: 100%;
            }
        }
    }
}


@media(max-width:768px) {
    .premiumPlusSignUpPageWrap {
        .mainContent {
            .container {
                .grid-layout-wrap {
                    .row {
                        .col {
                            padding-bottom: 10px;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                    }

                    .infobox {
                        max-width: 355px;
                        display: flex;
                        margin: 0 auto;
                    }
                }
            }
        }
    }


}

@media (max-width:767px) {
    .siteBg.MembershipCheckoutPageWrap {
        .membership-content {
            border-radius: 6px 6px 0 0;
        }

        .pageInternalScrollTopPadding {
            padding: 0px 10px 0 !important;
            margin: 0;
        }

        .row {
            flex-wrap: wrap;
        }

        .rightContent {
            margin-bottom: 10px;

            .infoBox {
                display: block;
                display: block;
                background: none;
                padding: 0;
                border: none;
                box-shadow: none;

                img {
                    border-radius: 6px;
                }
            }
        }

        .features {
            padding-right: 25px;
            display: none;

            &.open {
                display: block;
            }

            .priceInfo {
                display: none;
            }
        }

        .card2 {
            margin-bottom: 10px;
            padding-top: 10px;
        }

        .membership-content {
            // border-radius: 0px;
        }

        .leftContent {
            .card2 {
                .email-section {
                    .formGroup {
                        width: 100%;
                        flex: 100%;
                    }
                }

                .row {
                    margin: 0px;
                    flex-direction: column;
                }

                .btn {
                    margin: 0px auto;
                }


            }
        }
    }

    .freemiumsignupPageWrap {
        .skew-background {
            bottom: 100px;

        }

    }

    // premium Plus Sign Up PageWrap 

    .premiumPlusSignUpPageWrap {
        .mainContent {
            .pageInternalScrollTopPadding {

                padding-bottom: 0px;
            }

            .container {
                .upgrade-now-wrapper {
                    padding: 40px 20px 50px;
                    margin: 0 -13px;
                    text-align: center;
                    background-image: url("../../../img/premium-sign-up/upgrade-now-wrapper-mobilebg.png");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-size: 100% 100%;
                }
            }

        }

        .full-video-main-wrapper {
            .full-video-wrapper {
                .video-box {
                    min-height: 250px;

                    .videoPremiumPlus {
                        height: 230px;
                        min-height: 230px;
                        padding-bottom: 0px;
                    }
                }

                .video-box {
                    .video-play-btn {
                        img {
                            max-width: 70px;
                        }
                    }
                }
            }
        }


    }

    // newfreemiumsignupPageWrap start
    .newfreemiumsignupPageWrap {
        .planCont {
            .choosePlan {
                align-items: center;

                .freemium-member-col {
                    .planDetails {
                        li {
                            font-size: 14px;
                            padding-left: 0px;
                            max-width: 100%;
                            flex: 0 0 100%;
                            padding-bottom: 0;
                            margin-bottom: 4px;

                            .icon-tick {
                                font-size: 11px;
                                position: static;
                                top: 0;
                                transform: none;
                            }
                        }
                    }
                }

            }

            .twoColchoosePlan {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
        }
        .scrollBtm {
            bottom: 12px;

            .mousey-wrap {
                height: 60px;
                width: 60px;
            }

            .mousey {
                width: 2px;
                padding: 6px;
                height: 9px;
            }

            svg,
            img {
                max-width: 28px;
            }
        }
    }

    // newfreemiumsignupPageWrap End
}



@media (max-width: 480px) {
    .premiumPlusSignUpPageWrap {
        .full-video-main-wrapper {
            .full-video-wrapper {
                .video-box {
                    min-height: 180px;

                    .videoPremiumPlus {
                        height: 180px;
                        min-height: 180px;

                    }
                }


            }
        }
    }
}