:root {
  --nav-hamberger-menu: #1e6dc9;
}

.landingPage {
  --nav-hamberger-menu: #fff;
}

/*START: Common CSS*/

@import "fonts.scss";
@import "../scss/components/icomoon/icomoon.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @include ubuntu(400);
  box-sizing: border-box;
  line-height: normal;
  overflow-x: hidden;
  min-height: 100%;
  padding-bottom: 0px;
}

button {
  @include ubuntu(400);
  cursor: pointer;
}

.body-padding-0 {
  padding-bottom: 0;
}

html {
  min-height: 100%;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

.closeBtn {
  opacity: 0.5;
  font-size: 0.8rem;
}

.linkColor {
  color: #1d6ec9;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font17 {
  font-size: 17px;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}

.container {
  max-width: 1110px;
  margin-right: auto;
  margin-left: auto;
}

.titleStyle {
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 43px;
  color: #fff;
  font-weight: normal;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 43px;
  color: #fff;
  font-weight: 300;
}

.navigation {
  margin-left: auto;

  ul {
    font-size: 14px;
    list-style: none;
    display: flex;
    margin-bottom: 0px;

    a {
      color: #fff;
      display: block;
      padding: 8px 20px;

      .fa-angle-down {
        font-size: 1.1rem;
        margin-left: 3px;
        margin-right: -1px;
      }
    }

    li:last-child {
      a {
        padding-right: 0;
      }
    }
  }

  [class^="icon-"] {
    margin-right: 3px;

    &:before {
      color: #1d6ec9;
    }
  }

  margin-left: auto;

  ul {
    font-size: 14px;
    list-style: none;
    display: flex;

    a {
      color: #fff;
      display: block;
      padding: 8px 21px 8px 17px;

      .fa-angle-down {
        font-size: 1.1rem;
        margin-left: 4px;
      }
    }

    li:last-child {
      a {
        padding-right: 2px;
      }
    }
  }

  [class^="icon-"] {
    margin-right: 0px;
    font-size: 17px;

    &:before {
      color: #1d6ec9;
    }
  }
}

.landingPage,
.thank-you-wrapper .siteBg,
.siteBg {
  background: url(../img/widget-bg.jpg) 0 0 no-repeat;
  background-size: 100% 100vh;
  background-attachment: fixed;
}

.grayBg {
  background-color: #e6e7ee;
}

.pageBg {
  /*background: rgb(0,202,180);*/
  /*background: linear-gradient(180deg, rgba(0,202,180,1) 0%, rgba(0,144,173,1) 99%, rgba(0,0,0,1) 99%, rgba(0,0,0,1) 100%);*/
  background: url(../img/mobileBg.png) 0 0 no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
}

.dflex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .alignFlexStart {
    align-items: flex-start;
  }

  &.justifyStart {
    justify-content: flex-start;
  }

  &.flexColmun {
    flex-direction: column;
  }

  &.stretch {
    align-items: stretch;
  }
}

.dflexColm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexStart {
  justify-content: flex-start;
}

.linkStyle {
  color: #1d6ec9;
  font-size: 17px;

  i {
    margin-right: 8px;
  }

  &:hover {
    color: #000;
  }
}

.btn {
  min-width: 160px;
  font-size: 17px;
  padding: 12px;
  color: #fff;
  border: 0;
  border-radius: 22.5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 2px rgba(255, 255, 255, 0.35);
  // background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4);
  background-image: linear-gradient(to right, #1377c4, #00ceda);
  position: relative;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-image: linear-gradient(to right, #00ceda -12%, #1377c4);
    color: #ffff;
  }

  &:before {
    content: "";
    display: inline-block;
    background: url(../img/plane.png) 0 0 no-repeat;
    height: 27px;
    width: 33px;
    position: absolute;
    left: 8px;
    top: 12px;
  }
}

.btnBorder {
  border-radius: 10px;
  border: solid 1px #1d6ec9;
  background-color: rgba(239, 240, 245, 0.4);
  color: #1d6ec9;
  min-width: 100px;
  padding: 9px 10px;
  cursor: pointer;

  &:hover {
    background-color: #1d6ec9;
    color: #ffff;
  }
}

.btnBorder2 {
  border-radius: 17.5px;
  border: solid 1px #00c5c7;
  color: #00c5c7;
  background-color: #e6fffd;
  min-width: 108px;
  padding: 8.5px 10px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  display: inline-block;

  .fa {
    margin-left: 5px;
  }

  &:hover {
    background-color: #fff;
  }
}

.btnBorder3 {
  border-radius: 10px;
  border: solid 1px #fff;
  background-color: transparent;
  color: #fff;
  min-width: 80px;
  padding: 9px 10px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;

  &:hover {
    background-color: #fff;
    color: #00b7a6;
  }

  border-radius: 10px;
  border: solid 1px #fff;
  background-color: transparent;
  color: #fff;
  min-width: 80px;
  padding: 9px 10px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  /*&:hover {
    background-color: #fff;
    color: #00b7a6;
  }*/
}

.whiteBtn {
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 14px;
  color: #1d6ec9;
  padding: 6px 14px;

  &:hover {
    background-color: #1d6ec9;
    color: #fff;
  }
}

.blueBtn {
  border-radius: 6px;
  border: solid 1px #1d6ec9;
  background-color: rgba(239, 240, 245, 0.4);
  color: #1d6ec9;
  padding: 9px 10px;
  cursor: pointer;
  font-size: 14px;
  min-width: 100px;

  &:hover {
    background-color: #1d6ec9;
    color: #ffff;
  }
}

.genderBtnGroup {
  border-radius: 10px;

  .left {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-right: 0;
  }

  .right {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: 0;
  }

  .genderBtn {
    background-color: #e6fffd;
    min-width: 80px;
    padding: 8.5px 10px;
    cursor: pointer;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.719);
    border: 1px solid #dae2ee;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    padding: 9px 15px 10px;
    display: inline-block;
    overflow: visible;
    box-sizing: border-box;
    color: #435b759d;
    @include ubuntu(400);
    font-size: 17px;

    @include placeholder-text {
      font-weight: 400;
      color: rgba(67, 91, 117, 0.6);
    }
  }

  .active {
    color: #2f5175d7;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff59;
    padding: 10px 16px 11px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #dae2eec0;
  }
}


.btnSml {
  color: #1d6ec9;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  padding: 6px 30px 6px 33px;
  border-radius: 8px;
  box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
    2px 2px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #d2dae6;
  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  &.active {
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
  }
}

.btnGroup {
  margin-right: 20px;
}

.orange-btn {
  min-width: 180px;
  margin: 0 auto;
  height: 45px;
  border-radius: 22.5px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent;
  @include background("../img/signup/orange-btn-bg.png");
  display: block;

  &:hover,
  &:focus {
    @include background("../img/signup/orange-btn-bg.png");
  }

  &::before {
    display: none;
  }
}

.read-more-btn-wrap {
  text-align: right;
  padding-top: 8px;
}

.read-more-btn-wrap .read-less-btn {
  display: none;
}

.read-more-btn-wrap button {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: normal;
  letter-spacing: normal;
  color: #435b75;
  cursor: pointer;
}

.selectWrap {
  margin-right: 15px;
  position: relative;

  select {
    color: #1d6ec9;
    border-radius: 8px;
    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
      2px 2px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d2dae6;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    min-width: 180px;
    padding: 5px 10px;
    -webkit-appearance: none;
  }

  .fa {
    position: absolute;
    right: 7px;
    top: 5px;
    pointer-events: none;
    color: #1d6ec9;
  }
}

.pageWrap {
  min-height: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  // overflow: auto;
  /*&:after {
    content: "";
    height: 20px;
    background-image: linear-gradient(to bottom, #5492da, rgba(78, 146, 220, 0));
    width: 100%;
    display: block;
    top: 110px;
    left: 0;
    position: fixed;*/
}

/*START: Footer*/

.footer {
  padding: 14.5px 15px;
  box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #d2dae6;

  .footCopy {
    color: rgba(67, 91, 117, 0.6);
    font-size: 14px;
    margin: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bbb-logo-wrap {
    max-width: 90px;
  }
}

.snsIcons {
  font-size: 21px;

  >a {
    padding: 0 6px 0 7px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 2px;

    &:last-child {
      padding-right: 0;
    }


    .fb-size {
      width: 12px;
      height: 21px;

    }

    .tiwt-size {
      width: 24px;
      height: 21px;

    }

    .insta-size {
      width: 22px;
      height: 21px;

    }

    .linkdn-size {
      width: 23px;
      height: 21px;

    }

    .youtube-size {
      width: 27px;
      height: 21px;

    }

    .pinterest-size {
      width: 21px;
      height: 21px;

    }

    .tiktok-size {
      width: 21px;
      height: 21px;

    }

    .icon-align {
      display: inline-block;
      vertical-align: top !important;
    }
  }


  [class^="icon-"] {
    position: relative;

    .path2:before {
      position: absolute;
      margin-left: 0;
      left: 0;
    }
  }

  img {
    max-height: 18px;
  }
}

.siteBg {
  .footer {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: none;
    bottom: 0;
    width: 100%;
    min-height: 65px;

    .white-icon {
      svg {
        filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 30%));

        * {
          fill: rgba($color: #ffffff, $alpha: 0.6049) !important;
        }
      }
    }
  }

  .snsIcons {
    .path2:before {
      color: #fff;
      opacity: 1;
    }

    .path1:before {
      opacity: 1;
    }
  }
}

/*END: Footer*/

@media (max-width:992px) {
  .footer {
    position: relative;
    min-height: 45px;
  }
}

/*Login Page*/

.bgVideo {
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
}

.pageCenterCnt {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 50px;
    margin-top: auto;
    padding-top: 20px;
    display: block;
  }

  .icon-help,
  .icon-account-circle {
    opacity: 0.5;
  }

  .footCopy {
    margin-top: auto;
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    padding: 20px 0 12px;
  }
}

.tabsStyle {
  list-style: none;
  font-size: 19px;
  background-color: #edeef6;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #cfd1e1;
  display: flex;

  [class^="icon-"] {
    font-size: 32px;
    vertical-align: middle;
  }

  .icon-fun,
  .icon-building,
  .icon-flight,
  .icon-apartments,
  .icon-shape {
    *:before {
      text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
      color: #cfd1e1;
    }
  }

  .icon-fun {
    width: 30px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }

  li {
    padding-left: 10px;
  }

  a {
    color: #8696bc;
    border-bottom: 3px solid transparent;
    padding: 14px;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }

  .active {
    a {
      color: #1d6ec9;
      border-color: #1d6ec9;

      [class^="icon-"] {
        *:before {
          color: #1d6ec9;
          text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
        }
      }
    }
  }

  list-style: none;
  font-size: 19px;
  background-color: #edeef6;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #cfd1e1;
  display: flex;

  [class^="icon-"] {
    font-size: 32px;
    vertical-align: middle;
  }

  .icon-fun,
  .icon-building,
  .icon-flight,
  .icon-apartments,
  .icon-shape {
    *:before {
      text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
      color: #e6e7ee;
    }
  }

  .icon-fun {
    width: 30px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }

  li {
    padding-left: 10px;
  }

  a {
    color: #8696bc;
    border-bottom: 3px solid transparent;
    padding: 14px;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }

  .active {
    a {
      color: #1d6ec9;
      border-color: #1d6ec9;

      [class^="icon-"] {
        *:before {
          color: #1d6ec9;
          text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
        }
      }
    }
  }
}

.tabContent {
  padding: 20px 30px 31px;
}

.tabContentWrap {
  .tabContent {
    display: none;
  }

  .activeTab {
    display: block;
  }
}

.boxStyle {
  background-color: #e6e7ee;
  border-radius: 10px;
}

.inputStyle {
  position: relative;
  color: #1d6ec9;

  .icon-location-on {
    position: absolute;
    left: 15px;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }

  input {
    padding: 12px 15px 10px 40px;
    font-size: 17px;
    color: #1d6ec9;
    border-radius: 10px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    min-width: 243px;
    border-color: transparent;
  }

  input[type="text"],
  input[type="email"] {
    -webkit-appearance: none;
  }
}

.customCheckbox,
.customRadio {
  color: #435b75;
  font-size: 14px;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
  }

  >span {
    position: relative;
    padding: 1px 0 1px 28px;
    margin-top: 10px;
    display: block;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    }
  }

  input:checked+span {
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #1d6ec9;
      position: absolute;
      left: 4px;
      top: 4px;
      border-radius: 50%;
    }
  }
}

.customCheckbox {
  >span {
    &:before {
      border-radius: 4px;
    }
  }

  input:checked+span {
    &:after {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f00c";
      background-color: transparent;
      left: 2px;
      top: 2px;
      color: #1d6ec9;
    }
  }
}


/*END: Common CSS*/

/*START: Login*/

.pageBoxWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  .logo {
    margin-bottom: 50px;

    img {
      max-height: 34px;
    }
  }
}

.boxBg {
  max-width: 480px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;

  .textCnt {
    text-align: center;
    color: #435b75;

    h6 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    p {
      font-size: 17px;
      margin-bottom: 28px;
    }
  }
}

.boxBtmLink {
  margin-top: 36px;
  display: flex;

  a {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 17px;
    padding: 0 20px;

    &:hover {
      color: #000;
    }

    [class^="icon-"] {
      margin-right: 8px;
    }
  }
}

.inputWrap {
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  display: flex;
  margin-bottom: 25px;

  .iconWrap {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1d6ec9;
  }

  input {
    // box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    //   inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.25);
    border: 0;
    font-size: 17px;
    color: #1d6ec9;
    padding: 15px;
    flex-grow: 1;
    border-radius: 0 10px 10px 0;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    color: rgba(67, 91, 117, 0.6) !important;
    -webkit-appearance: none;
    border-style: none;
    border: none;
    background-color: transparent;
    height: 100%;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-size: 17px;
    // line-height: normal;
    // letter-spacing: normal;
    // box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #fff;
    box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
    border-radius: 0 10px 10px 0;

    * {
      color: #1d6ec9 !important;
      background: transparent !important;
    }
  }

  .icon-globe:before {
    content: "\e933";
    color: #1D6EC9;
  }

  .removeSelectPadding {
    padding: 0 !important;
    color: black !important;
    box-shadow: none !important;

    input {
      box-shadow: none !important;
    }
  }
}

.pageCenterCnt {
  .dflex {
    flex-direction: column;

    .linkStyle {
      color: #fff;
      margin-bottom: 37px;
    }
  }

  .boxBtmLink {
    flex-direction: column;
    align-items: center;
    margin-top: 46px;

    a:first-child {
      margin-bottom: 34px;
    }
  }

  .inputWrap .iconWrap {
    color: #fff;
  }

  .inputWrap input {
    color: #fff;

    &::-webkit-input-placeholder {
      /* Edge */
      color: #fff;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }

    &::placeholder {
      color: #fff;
    }
  }

  .boxBg .textCnt {
    h6 {
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .flexColReverse {
    padding-top: 5px;
    flex-direction: column-reverse;

    .linkStyle {
      margin-bottom: 0;
      margin-top: 36px;
    }
  }
}

/*END: Login*/

/*START: Landing*/

.header {
  padding: 9.71px 0;
  min-height: 55px;

  .logoWrap {
    img {
      height: 30px;
      display: block;
    }
  }
}

.menuBtn {
  display: none;
  border: none;
  padding: 0;
  box-shadow: none;
}

.navbar-toggler-icon {
  display: none;
  position: relative;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  /*border: 1px solid #fff;*/
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;


  span {
    margin-top: -1px;
    left: 7px;

    &,
    &:before,
    &:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 19px;
      display: block;
      background: #fff;
      top: 50%;
      border-radius: 1px;
      transition: all 0.5s;
    }
  }

  span:before {
    top: -7px;
  }

  span:after {
    top: 7px;
  }
}

.checkBoxContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.centerCnt {
  /*min-height: calc(100vh - 62px);*/
  display: flex;
  flex-direction: column;
  justify-content: center;

  .boxStyle {
    min-height: 242px;
  }
}

.startEndLocation {
  display: flex;
  align-items: center;

  .changeBtn {
    border-radius: 50%;
    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
      2px 2px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d2dae6;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    height: 35px;
    width: 35px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
}

.startEndDate {
  font-size: 17px;
  color: #1d6ec9;
  border-radius: 10px;
  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
  -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
  padding: 11px 17px;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 264px;
  border: solid 1px #d2dae6;

  .icon-arrow {
    color: rgb(134 150 188);
    padding: 0 5px;
    display: inline-block;
    font-size: 0.7rem;
  }

  .icon-invitation {
    margin-right: 5px;
    vertical-align: text-bottom;
  }

  input {
    border: 0;
    background: transparent;
    font-size: 17px;
    color: #1d6ec9;
    width: 90px;
  }
}

#condosTab {
  .endDate {
    padding-left: 15px;
  }
}

.filterSecondRow {
  padding-top: 50px;
  display: flex;

  .grow1 {
    flex-grow: 1;

    input {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .inputStyle {
    input {
      &::-webkit-input-placeholder {
        /* Edge */
        color: #1d6ec9;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #1d6ec9;
      }

      &::placeholder {
        color: #1d6ec9;
      }
    }
  }
}

.landingPage {


  .navigation {
    [class^="icon-"]:before {
      color: #fff;
    }
  }

  .container {
    max-width: 1200px;
  }

  .footCopy {
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    // padding: 20px 0 12px;
  }

  .mobileLogo {
    display: none;
  }

  .dropdownWrap {
    position: relative;

    &.flatDropdown {
      .dropdownInWrap {
        padding: 0;
        width: 300px;

        .dropDownSubWrap {
          margin-bottom: 0;
          padding-top: 0;
          box-shadow: none;
          border: 0;
        }
      }
    }

    &.listDropdown {
      .dropdownInWrap {
        padding: 6px;
        width: 180px;
      }

      ul {
        list-style: none;

        a {
          display: block;
          border: solid 1px transparent;
          border-radius: 6px;
          color: #435b75;
          padding: 10px;

          &:hover {
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
              inset -2px -2px 6px 0 #ffffff;
            border-color: #d2dae6;
          }
        }
      }
    }

    .btnSml {
      display: flex;
      justify-content: space-between;
      width: 240px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;

      &.width180 {
        width: 180px;
      }
    }

    &.openDropdown {
      .dropdownInWrap {
        display: flex;
        flex-direction: column;
      }

      .btnSml {
        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
          inset -2px -2px 6px 0 #ffffff;
      }
    }

    .dropdownInWrap {
      box-sizing: border-box;
      width: 320px;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2d9e7;
      background-color: #e6e7ee;
      position: absolute;
      top: auto;
      bottom: 30px;
      z-index: 4;
      display: none;

      .inWrap {
        padding: 15px 0 0;
        color: #435b75;

        .noteText {
          font-size: 12px;
          color: #7d92a9;
        }
      }

      .addMinusWrap {
        input {
          background: transparent;
          border: none;
          width: 40px;
          text-align: center;
          color: #333333;
          font-size: 17px;
        }

        .minus {
          position: relative;
          display: block;

          &:before {
            background: #1d6ec9;
            width: 12px;
            height: 2px;
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        button {
          width: 30px;
          height: 30px;
          box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px #d2dae6;
          border-radius: 50%;
          background-color: transparent;

          *:before {
            color: #1d6ec9;
          }

          &.disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      .roomHead {
        color: rgba(67, 91, 117, 0.6);
        font-size: 14px;

        .icon-close:before {
          font-size: 12px;
          color: rgba(67, 91, 117, 0.6);
        }
      }

      .adultstitle {
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #435b75;
      }


      .addRoom {
        padding: 6px 6px;

        button {
          border: none;
          background: none;
          color: #1d6ec9;
        }

        a {
          color: #1d6ec9;
          font-size: 14px;
          display: inline-block;
          padding-left: 7px;
        }

        .icon-add {
          vertical-align: top;
        }

        .icon-add:before {
          color: #1d6ec9;
          margin-right: 4px;
          font-size: 10px;
        }
      }
    }
  }

  .tabContent {
    .btn {
      box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2), -3px -3px 6px 2px #ffffff;
    }
  }

  .icon-arrow {
    color: rgb(134 150 188);
    padding: 0 5px;
    display: inline-block;
    font-size: 11px;
  }

  .icon-invitation {
    margin-right: 5px;
    vertical-align: text-bottom;
  }

  input {
    border: 0;
    padding: 0px;
    background: transparent;
    font-size: 17px;
    color: #1d6ec9;
    width: 90px;

    @include placeholder-text {
      color: #1d6ec9;
    }
  }
}

.filterSecondRow {
  padding-top: 50px;
  display: flex;

  .grow1 {
    flex-grow: 1;

    input {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .inputStyle {
    input {
      &::-webkit-input-placeholder {
        /* Edge */
        color: #1d6ec9;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #1d6ec9;
      }

      &::placeholder {
        color: #1d6ec9;
      }
    }
  }
}

.landingPage {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;

  .navigation {
    [class^="icon-"]:before {
      color: #fff;
    }
  }

  .container {
    max-width: 1200px;
  }

  .footCopy {
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    // padding: 20px 0 12px;
  }
}

.Terms-wrapper {
  .card2 {
    background-color: hsla(0,0%,100%,.8);
    border-radius: 8px;
    box-shadow: 0 0 15px 0rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding: 20px;
  }
  .content-wrapper {
    h1, h4 {
      margin-bottom: 12px;
    }

    h2 {
      margin-bottom: 10px;
    }

    h3 {
      margin: 10px 0 0;
    }

    h2, h3 {
      font-size: 17px;
      line-height: 21px;
    }

    p {
      line-height: 17px;
      margin-bottom: 8px;
    }

    ul, ol {
      margin-bottom: 8px;
      padding-left: 17px;

      li {
        margin-bottom: 8px;
      }
    }
  }
}

.card2 {
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  // border: solid 1px #e4e9f1;
  background-color: rgba(243, 244, 248, 0.8);
  padding: 10px;
  margin-bottom: 16px;

  .btn {
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #ffffff;
    top: 2px;
    left: 0px;
  }

  .add-traveller-button {
    color: #0078d9;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    cursor: pointer;

    .add-traveller-icon {
      &::before {
        color: #0078d9;
        font-size: 14px;
        margin-right: 6px;
      }
    }
  }

  .mobileLogo {
    display: none;
  }

  .dropdownWrap {
    position: relative;

    &.flatDropdown {
      .dropdownInWrap {
        padding: 0;
        width: 300px;

        .dropDownSubWrap {
          margin-bottom: 0;
          padding-top: 0;
          box-shadow: none;
          border: 0;
        }
      }
    }

    &.listDropdown {
      .dropdownInWrap {
        padding: 6px;
        width: 180px;
      }

      ul {
        list-style: none;

        a {
          display: block;
          border: solid 1px transparent;
          border-radius: 6px;
          color: #435b75;
          padding: 10px;

          &:hover {
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
              inset -2px -2px 6px 0 #ffffff;
            border-color: #d2dae6;
          }
        }
      }
    }

    .btnSml {
      display: flex;
      justify-content: space-between;
      width: 240px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;

      &.width180 {
        width: 180px;
      }
    }

    &.openDropdown {
      .dropdownInWrap {
        display: flex;
        flex-direction: column;
        z-index: 999;
      }

      .btnSml {
        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
          inset -2px -2px 6px 0 #ffffff;
      }
    }

    .dropdownInWrap {
      box-sizing: border-box;
      width: 320px;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2d9e7;
      background-color: #e6e7ee;
      position: absolute;
      top: 35px;
      z-index: 1;
      display: none;

      .inWrap {
        padding: 15px 0 0;
        color: #435b75;

        .noteText {
          font-size: 12px;
          color: #7d92a9;
        }

        .add-child-age {
          width: 100%;

          .add-age {
            display: flex;
            flex: 0 0 100%;

            >div {
              display: flex;
              flex: 0 0 100%;
              width: 100%;

              .css-b62m3t-container {
                margin-left: auto;
                font-size: 14px;
                width: 150px;
                padding: 2px;
                border-radius: 8px;
                box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                border: solid 1px #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                margin-right: 0px;
                cursor: pointer;

                .custom-select__control {
                  background-color: transparent;
                  padding: 0px;
                  min-height: auto;

                  .css-319lph-ValueContainer {
                    padding: 2px 7px;
                  }
                }
              }
            }
          }
        }

      }

      .addMinusWrap {
        input {
          background: transparent;
          border: none;
          width: 40px;
          text-align: center;
          color: #333333;
          font-size: 17px;
        }

        .minus {
          position: relative;

          &:before {
            background: #1d6ec9;
            width: 12px;
            height: 2px;
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        button {
          width: 30px;
          height: 30px;
          box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px #d2dae6;
          border-radius: 50%;

          *:before {
            color: #1d6ec9;
          }

          &.disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      .roomHead {
        color: rgba(67, 91, 117, 0.6);
        font-size: 14px;

        .icon-close:before {
          font-size: 12px;
          color: rgba(67, 91, 117, 0.6);
        }
      }



      .addRoom {
        a {
          color: #1d6ec9;
          font-size: 14px;
          display: inline-block;
          padding-left: 7px;
        }

        .icon-add {
          vertical-align: top;
        }

        .icon-add:before {
          color: #1d6ec9;
          margin-right: 7px;
          font-size: 10px;
        }
      }
    }
  }

  .tabContent {
    .btn {
      box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2), -3px -3px 6px 2px #ffffff;
    }
  }

  .tabsStyle {
    a:hover [class^="icon-"] *:before {
      color: #1d6ec9;
      text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
    }

    a {
      &:hover {
        color: #1d6ec9;
        border-color: #1d6ec9;
      }
    }
  }
}


/*END: Landing*/

/*START: Listing*/

.siteBg,
.listingPage {
  .header {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #eff0f5;

    .navigation ul a {
      color: #1d6ec9;
      line-height: 1;
    }
  }

  .mainContent {
    padding-top: 20px;
    min-height: 100%;
  }

  .container {
    max-width: 1110px;
    padding: 0;
  }
}

.listingPage.siteBg {
  .mainContent {
    margin-top: 110px;
  }
}

.filterCnt {
  background: rgba(255, 255, 255, 0.3);
  padding: 10px 0;

  .inputStyle {
    .icon-location-on {
      top: 7px;
    }

    input {
      border: none;
      background: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      padding: 9.5px 15px 9.5px 40px;
      box-shadow: none;
      min-width: 215px;
    }
  }

  .startEndDate {
    border: none;
    background: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    padding: 9.5px 15px;
    box-shadow: none;
    min-width: 220px;
    margin: 0;
  }

  .selectWrap {
    margin: 0;

    select {
      background: rgba(255, 255, 255, 0.4);
      box-shadow: none;
      font-size: 14px;
      border: none;
      padding: 8.5px 10px;
    }
  }

  &.hotelFilter {
    .inputStyle {
      input {
        min-width: 365px;
      }
    }

    .selectWrap select {
      min-width: 280px;
    }

    .startEndDate {
      min-width: 250px;
    }
  }
}


.container.dflex {
  align-items: flex-start;
}

.mainWrap {
  width: 860px;
}

.card2 {
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  // border: solid 1px #e4e9f1;
  background-color: rgba(243, 244, 248, 0.8);
  padding: 10px;
  margin-bottom: 16px;

  .btn {
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #ffffff;
  }

  .add-traveller-button {
    color: #0078d9;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    cursor: pointer;

    .add-traveller-icon {
      &::before {
        color: #0078d9;
        font-size: 14px;
        margin-right: 6px;
      }
    }
  }
}


.noteCard {
  color: #00b7a6;
  font-size: 19px;

  .btnBorder2 {
    margin-left: auto;
    background-image: linear-gradient(to right, #1377c4, #00ceda);
    color: #ffffff;
    min-width: 140px;

    &:hover {
      background-image: linear-gradient(to right, #00ceda -12%, #1377c4);
    }
  }

  .closeBtn {
    margin-left: 30px;
    margin-right: 5px;
    color: rgba(67, 91, 117, 0.5);

    .icon-close {
      &:before {
        color: rgba(67, 91, 117, 0.5);
      }
    }
  }

  .icon-border-plain {
    font-size: 1.7rem;
    margin-right: 10px;
  }
}

.imgWrap {
  width: 210px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #dae2ee;
  background-color: rgba(255, 255, 255, 0.35);

  img {
    display: block;
  }
}

.cardContent {
  flex-grow: 1;
  padding-left: 20px;
  color: #435b75;

  .cartTitle {
    color: #1d6ec9;
    font-size: 21px;
    font-weight: normal;
    margin-bottom: 7px;
    margin-top: 5px;
    display: block;
  }

  .innerCnt {
    flex-grow: 1;
  }

  .cartTitle {
    color: #1d6ec9;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 5px;
    display: block;
  }

  .innerCnt {
    flex-grow: 1;
  }
}



.bulletList {
  list-style: none;
  color: #435b75;
  font-size: 14px;

  li {
    display: inline;
    padding: 0px 2px 0px 0px;

    &+li {
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        display: inline-block;
        vertical-align: middle;
        background-color: #435b75;
        border-radius: 50%;
        margin: 0 7px 0 5px;
      }
    }
  }
}




.rating {
  display: flex;
  width: 130px;
  font-size: 1.2rem;

  img {
    width: 38px;
    height: 26px;
  }
}

.ratingComment {
  color: #00b7a6;
}

.commentCount {
  display: inline-block;
  background-color: #00b7a6;
  color: #fff;
  padding: 1px 4px;
  border-radius: 3px;
  position: relative;
  margin-right: 8px;

  &:before {
    border-bottom: 7px solid transparent;
    border-left: 7px solid #00b7a6;
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -5px;
  }
}

.location {
  color: #435b75;
  margin-bottom: 5px;

  .icon-location-on {
    font-size: 0.8rem;
    margin-right: 3px;
    margin-left: 2px;
  }
}

/*END: Listing*/

/*Home page*/

.bannerSec {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bannerImg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    padding-bottom: 10px;
  }

  p {
    font-size: 17px;
    padding-bottom: 45px;
  }

  .bannerCont,
  .header {
    position: relative;
    z-index: 1;
  }

  .bannerCont {
    color: #fff;
  }

  .scrollBtm {
    background-image: linear-gradient(92deg, #0072d1, #00dcdf);
    border: 0;
    display: block;
    text-align: center;
    width: 100vw;
    color: #fff;
    padding: 9px;
    font-size: 28px;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(92deg, #00dcdf, #0072d1);
    }

  }

  .header {
    font-size: 14px;
    padding: 18px 15px;

    .navigation {
      margin-right: 15px;

      ul a {
        padding: 8px 0;
        font-size: 19px;
      }
    }

    .btnBorder3 {
      font-size: 19px;
      padding: 7.5px 10px;

      &:hover {
        background-color: inherit;
        color: #fff;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sctionTitle {
  font-size: 30px;
  font-weight: normal;
  color: #435b75;
  padding-bottom: 25px;
}

.sectionSpace2,
.sectionSpace {
  padding-top: 45px;
}

.cardStyle {
  border-radius: 10px;
  box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
    2px 2px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #d2dae6;
}

.cardBox {
  padding: 36px 24px;
  max-width: 640px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;

  p {
    color: #435b75;
    font-size: 17px;
    font-weight: 300;
  }

  p+p {
    margin-top: 16px;
  }
}

.sideSpace {
  padding-right: 15px;
  padding-left: 15px;
}

.colCard {
  .cardStyle {
    display: flex;
    padding: 5px;
    align-items: flex-start;
    background: #ececf3;

    .cardImg {
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      margin-right: 10px;
      padding: 9px;
      border-radius: 8px;

      img {
        width: 31.91vw;
        height: 31.91vw;
        object-fit: cover;
        display: block;
        border-radius: 6px;
      }
    }

    h5 {
      font-size: 17px;
      color: #435b75;
      padding-bottom: 8px;
      padding-top: 8px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      color: #748499;
      font-weight: 300;
    }
  }
}

.home-faq {
  .accordion {
    max-width: 720px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
  }
}

.gradiantBg {
  margin-top: 80px;
  background: url(../img/videoBackground.png) center center no-repeat;
  background-size: cover;

  .sctionTitle {
    color: #fff;
    padding-bottom: 8px;
  }

  p {
    color: #fff;
    margin-bottom: 38px;
    font-weight: 300;
  }
}

.videoSpace .cardStyle {
  max-width: 640px;
  padding: 10px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
}

.videoBox {
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #d2dae6;
  background-image: linear-gradient(to right, #ececf3, #e6e7ee);

  .videoInBox {
    overflow: hidden;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url(../img/videoIcon.png) 0 0 no-repeat;
      width: 97px;
      height: 97px;
    }

    img {
      height: 280px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }
}

.videoSpace {
  padding-top: 74px;
  padding-bottom: 86px;
}

.planCont {
  padding-top: 72px;
}

.choosePlan {
  display: flex;
  justify-content: center;
  width: 100%;

  .col {
    flex-grow: 1;
    padding: 0 18px;
    max-width: 412px;
    width: 50%;
  }

  .cardStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    height: 100%;
    text-align: left;
  }

  .planImgBox {
    padding: 10px 10px 8px;
    border-radius: 6px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    background-color: rgba(255, 255, 255, 0.35);
  }

  .planImgInner {
    background: url(../img/planBg.jpg) center center no-repeat;
    background-size: cover;
    border-radius: 6px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .planName {
      margin-top: 10px;
      color: $white;
      font-size: 17px;
    }

    p {
      color: $white;
      font-size: 14px;
      font-weight: normal;
      margin-top: 2px;
    }

    .plus-img {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }

  .planNote {
    color: rgba(67, 91, 117, 0.6);
    margin-bottom: 17px;
    font-size: 14px;
  }

  .planDetails {
    margin-bottom: auto;
    margin-left: 0;
    padding: 17px 20px 2px;
    list-style: none;
    color: #435b75;
    font-size: 14px;

    li {
      margin-bottom: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      svg {
        max-width: 14px;
        flex: 0 0 14px;
        margin-right: 9px;
        margin-top: -2px;
      }

    }
  }




}

.planCont {
  background: url(../img/choose-plan-bg.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: 55px;

  .sctionTitle {
    color: $white;
  }

  .cardStyle {
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
  }

  .choosePlan {
    .col {
      max-width: 35.7%;
      flex: 0 0 35.7%;
      width: 100%;
      padding: 0 22.7px;

      .planImgInner {
        background-size: cover;
        background-position: center center;
      }

      &:first-child {
        .planImgInner {
          background-image: url("../img/planBg4.jpg");
        }
      }

      &:nth-child(2) {
        .planImgInner {
          background-image: url("../img/planBg2.jpg");
        }
      }

      &:nth-child(3) {
        .planImgInner {
          background-image: url("../img/planBg3.jpg");
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .planCont {
    .choosePlan {
      .col {
        max-width: 33.33%;
        flex: 0 0 33.33%;
        width: 100%;
        padding: 0 15px;
      }
    }
  }
}

/*START: Profile Page*/

.siteBg {

  .leftSideBar {
    width: 320px;
  }

  .mainWrap {
    width: 760px;
    padding-right: 15px;
  }
}

.profileCnt {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;

  p {
    font-size: 14px;
    color: #435b75;
    opacity: 0.6;
    line-height: normal;
  }

  .profileImage {
    padding: 9px 10px;
    border-radius: 50%;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
      inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    background-color: rgba(255, 255, 255, 0.35);
    max-width: 180px;
    margin: 0 auto 12px;
    box-sizing: border-box;
    position: relative;

    .custom-file-upload {
      position: absolute;
      bottom: 18px;
      right: 12px;
      left: auto;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 1;
      background-color: #1e6dc9;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;

      img,
      svg {
        max-width: 18px;
        height: auto;

        * {
          fill: #fff;
        }
      }

      input {
        opacity: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        left: auto;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 2;
      }
    }

    img {
      max-width: 160px;
      display: block;
      border-radius: 100px;
      height: 160px;
      width: 160px;
      object-fit: cover;
    }
  }

  .profileName {
    font-size: 21px;
    color: #1d6ec9;
    font-weight: normal;
    margin-bottom: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .profileLink {
    color: #7d92a9;
    display: inline-block;
    margin-bottom: 4px;

    [class^="icon-"]:before {
      color: #7d92a9;
      vertical-align: middle;
      margin-right: 8px;
    }

    .icon-mail {
      font-size: 12px;
    }
  }
}

.labelStyle {
  font-size: 14px;
  color: #7d92a9;
  display: block;
  margin-bottom: 5px;
}

.inputStyle2 {

  /*border-radius: 10px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    background-color: rgba(255, 255, 255, 0.35);
    box-sizing: border-box;
    width: 100%;
    padding: 9px;
    font-size: 17px;*/
  input {
    padding: 10px 10px 8px 13px;
    width: 100%;
    box-sizing: border-box;
    color: #435b75;
    background: rgba(255, 255, 255, 0.35);
    min-width: auto;
    text-overflow: ellipsis;

    &::-webkit-input-placeholder {
      /* Edge */
      color: #7d92a9;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #7d92a9;
    }

    &::placeholder {
      color: #7d92a9;
    }
  }
}

.selectStyle {
  .selectInWrap {
    position: relative;

    .fa {
      color: #1d6ec9;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 20px;
    }
  }

  select {
    border-radius: 10px;
    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
      2px 2px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px rgba(210, 218, 230, 0.6);
    background-color: rgba(255, 255, 255, 0.35);
    box-sizing: border-box;
    width: 100%;
    padding: 9px;
    font-size: 17px;
    color: #435b75;
    -webkit-appearance: none;

    option:first-child {
      color: #7d92a9;
    }
  }
}

.btnWrap {
  padding-top: 7px;
}

.btn2 {
  font-size: 14px;
}

.halfCol {
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
  margin-right: -8px;

  .col {
    width: 50%;
    margin-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
}

.col3 {
  display: flex;
  justify-content: space-between;

  .col {
    margin-bottom: 13px;
    width: 32%;
  }
}

.cardPad2 {
  padding: 14px 19px 22px;
}

.boxTitle {
  font-size: 17px;
  font-weight: 500;
  color: #435b75;
  margin-bottom: 19px;
}

/*END: Profile Page*/

/*START: Booking*/

.listPage {
  .mobileShow {
    display: none;
  }

  .leftSideBar {
    width: 220px;
  }

  .mainWrap {
    width: 860px;

    .cartTitle {
      font-weight: 400;
    }
  }

  .infoBox {
    justify-content: flex-start;
    width: 210px;
    padding: 14px;

    .darkText {
      font-weight: 500;
    }

    .btn {
      min-width: 140px;
      padding: 9.5px;
    }
  }

  .cardContent .colorText {
    color: #00b7a6;
    margin-top: 20px;
    padding-bottom: 2px;
    font-weight: 500;

    .icon-speedometer {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .cartTitle {
    .arrow {
      font-size: 30px;
      line-height: 21px;
    }
  }

  // .mainContent {
  //     margin-top: 51px;
  // }
}

.sideBarTitie {
  font-size: 23px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: normal;
}

.mobileActiveMenu {
  display: none;
}

.mobileActiveMenu,
.sidebarTabs {
  list-style: none;
  font-size: 17px;

  li {
    margin-bottom: 5px;
  }

  //

  .active {
    display: block;
    padding: 11px 0 10px 20px;
    border-radius: 23px;
    transition: all 0.3s;
  }

  a {
    color: #fff;
    display: block;
    padding: 11px 0 10px 20px;
    border-radius: 23px;
    transition: all 0.3s;

    [class^="icon-"] {
      color: #fff;
    }

    i {
      margin-right: 13px;
    }
  }

  a:hover,
  .active {
    background-color: rgba(255, 255, 255, 0.6);
    color: #1d6ec9;
  }



}



.refundable {
  padding: 2px 8px 3px;
  border-radius: 6px;
  border: solid 1px #00b7a6;
  margin-left: 5px;
  font-size: 12px;
  color: #00b7a6;
}

.cardContent {
  .copyText {
    font-size: 14px;
  }

  .cardLinkStyle {
    margin-top: 20px;

    button {
      color: #1d6ec9;
      font-size: 14px;
      border: none;
      background-color: transparent;

      i {
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .smlText {
    font-size: 14px;
    color: #7d92a9;
    margin-top: 7px;

    i:before {
      color: #7d92a9;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .cardCol {
    display: flex;
    margin-bottom: 17px;
    margin-top: 15px;

    .col {
      width: 180px;

      +.col {
        margin-left: 15px;
      }

      label {
        display: block;
        margin-bottom: 4px;
      }

      i:before {
        font-size: 16px;
        margin-right: 5px;
      }

      label,
      span {
        color: #7d92a9;
        font-size: 14px;
      }

      .dateInWrap {
        font-weight: 500;
        color: #435b75;
        font-size: 17px;
        margin-right: 5px;
      }
    }
  }
}

.listPage .iconWrap {
  min-width: 80px;
  height: 80px;
  border-radius: 70px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #dae2ee;
  background-color: rgba(255, 255, 255, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 0 7px;

  [class^="icon-"] {
    position: relative;
    font-size: 30px;

    .path2:before {
      color: #7e93a8;
      position: absolute;
    }
  }
}

.locationWrap {
  position: relative;
  padding-left: 3px;

  .startPoint,
  .endPoint {
    display: flex;
  }

  .icon-location {
    margin-right: 9px;
  }

  .endPoint {
    margin-top: 9px;
  }

  .endPoint:before {
    content: "";
    position: absolute;
    left: 7px;
    border-left: 2px dotted #7d92a9;
    top: 17px;
    bottom: 15px;
    display: block;
  }
}

.flightStart {
  padding-top: 12px;
}

.flightEnd,
.flightStart {
  .cardCol {
    margin-top: 8px;
    position: relative;
    margin-bottom: 20px;

    .col {
      width: 195px;
    }
  }

  .icon-flight {
    position: absolute;
    left: 35%;
    transform: translateX(-35%);
    bottom: -6px;
    font-size: 27px;

    .path1:before {
      color: #7e93a8;
    }
  }
}

/*END: Booking*/

/*END: Booking*/

.InfoMobileBarView {
  display: none;
}

.infoWrapper {
  color: $blueText-1;
  margin: 0 -10px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px 0;
  position: relative;
  margin-top: 57px;

  .row {
    margin: 0 -5px;
  }

  .buttonWrapp {
    h4 {
      display: none;
    }
  }

  img {
    margin: 0 8px;
  }

  .countryBox {
    max-width: 38.393%;
    flex: 0 0 38.393%;
    min-height: 35px;
    position: relative;

    &:before {
      content: "";
      @include background("../img/location.png");
      background-size: contain;
      width: 12px;
      height: 16px;
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    p {
      padding-left: 35px;
    }
  }

  .lightBlueBg {
    align-items: center;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .dateWrapper {
    .lightBlueBg {
      position: relative;

      &:after {
        content: "";
        @include background("../img/right-arrow.png");
        width: 15px;
        height: 10px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }

  .dateWrapper,
  .dropDownWrap {
    max-width: 25.804%;
    flex: 0 0 25.804%;
    min-height: 35px;
  }

  .dateWrapper,
  .dropDownWrap {
    .lightBlueBg {
      justify-content: space-between;
    }

    .toDate,
    .fromDate {
      padding: 0 10px;
      position: relative;

      &:before {
        content: "";
        @include background("../img/date.png");
        background-size: contain;
        width: 14px;
        height: 16px;
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }

      p {
        padding-left: 25px;
      }
    }

    img {
      height: auto;
      margin-left: 0;
    }
  }

  .dropDownWrap {
    .lightBlueBg {
      padding: 0 14px;
    }
  }
}








/*hotel detail end*/

.checkoutPageWrap {
  @include background("../img/widget-bg.jpg");
  background-repeat: repeat-y;

  &.popupOpned .pageWrap {
    filter: none;
  }


  .pageWrap {
    .mainContent {
      >.container {
        padding-top: 10px;
      }
    }
  }

  .sectionTitle {
    h2 {
      font-size: 17px;
      font-weight: 500;
      color: #435b75;
      line-height: normal;
      margin-bottom: 15px;
    }
  }

  .leftContent {
    max-width: 69.3%;
    flex: 0 0 69.3%;
  }

  .rightContent,
  .popupStyle {
    .infoBox {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 5px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    }

    .imgWrap {
      width: auto;
      height: 160px;
      padding: 0;
    }

    .Infocontent {
      padding: 10px;
    }

    .contentGroup {
      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
      padding-bottom: 12px;

      h2 {
        font-size: 21px;
        font-weight: 400;
        color: #1d6ec9;
        line-height: 1.2;
        margin-bottom: 12px;
      }

      h3 {
        font-size: 14px;
        font-weight: 500;
        color: #435b75;
        line-height: 1.4;
        margin-bottom: 13px;

        a {
          padding: 2px 6.8px 3px;
          border-radius: 6px;
          border: solid 1px #00b7a6;
          color: #00b7a6;
          font-size: 12px;
          margin-top: 2px;
          display: inline-block;
          margin-left: 3px;
        }
      }

      .contactInfo {
        padding-bottom: 6px;

        .location,
        .phone {
          font-size: 14px;
          color: rgba(67, 91, 117, 0.6);

          img {
            margin-right: 4px;
          }

          .icon-phone {
            width: 18px;

            &:before {
              color: rgba(67, 91, 117, 0.6);
            }
          }
        }

        .location {
          margin-bottom: 7px;
          display: flex;

          img {
            margin-bottom: auto;
          }
        }
      }
    }

    .roomInfo {
      padding-top: 15px;
      padding-bottom: 6px;
      border-bottom: none;

      .btn-non-refundable {
        border: 1px solid red;
        color: red;
      }

      .btn-refundable {
        border: 1px solid green;
        color: green;
      }

      h5 {
        font-size: 14px;
        font-weight: 400;
        color: rgba(67, 91, 117, 0.6);
        margin-bottom: 6px;

        strong {
          font-weight: 500;
          color: #435b75;
        }
      }
    }



    .timeWrapper {
      padding: 8px 0 9px;

      .d-flex {
        align-items: center;
        margin-bottom: 6px;
      }

      img {
        margin-bottom: auto;
        max-width: 16px;
        margin-bottom: 0;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #435b75;
        padding: 0 7px;
      }
    }

    .amountCalculation {
      padding: 12px 14px 5px 15px;
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #dae2ee;
      background-color: rgba(255, 255, 255, 0.35);
      margin: 0 -7px;

      p,
      h5 {
        font-size: 14px;
        color: rgba(67, 91, 117, 0.6);
        font-weight: 400;
        margin-bottom: 8px;
      }

      h4 {
        color: #435b75;
        font-size: 17px;
        font-weight: 400;
      }

      .amountInfo {
        padding-bottom: 6px;
      }

      .payWrapper {
        padding: 8px 0;
        border-top: 1px solid rgba(67, 91, 117, 0.2);

        .savings {
          h4 {
            font-size: 21px;
            font-weight: 500;

            span {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .savings {
        align-items: center;

        h4 {
          text-align: right;
        }

        h5 {
          display: flex;
          align-items: center;

          img {
            margin-left: 4px;
          }
        }

        span {
          display: block;
        }
      }
    }
  }

  .card2 {
    padding: 15px 20px;

    .sectionTitle {
      margin-bottom: 10px;
    }

    .customCheckbox {
      >span {
        &:before {
          height: 22px;
          width: 22px;
        }
      }

      input:checked {
        +span {
          &:after {
            left: 7px;
            top: 6px;
          }
        }
      }
    }

    .btn {
      &:before {
        display: none;
      }
    }

    .row {
      margin: 0 -7.5px 13px;
    }

    .col {
      padding: 0 7.5px;
    }

    .promoInputCol1 {
      width: 80%
    }

    .promoInputCol2 {
      width: 23%;
      margin-top: 16px;
    }

    h5 {
      color: rgba(67, 91, 117, 0.6);
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
    }

    .formControl {
      background-color: rgba(255, 255, 255, 0.35);
      border-radius: 10px;
      border: 1px solid #dae2ee;
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #ffffff;
      width: 100%;
      padding: 9px 15px 10px;
      display: block;
      overflow: visible;
      box-sizing: border-box;
      color: #435b75;
      @include ubuntu(400);
      font-size: 17px;

      @include placeholder-text {
        font-weight: 400;
        color: rgba(67, 91, 117, 0.6);
      }
    }

    .primaryInfo {
      .dropdown {
        max-width: 125px;
        flex: 0 0 125px;
      }
    }

    .dropdown {
      .selectWrap {
        position: relative;
        margin-right: 0;

        &:after {
          content: "";
          @include background("../img/dropdown-arrow.png");
          background-size: contain;
          height: 14px;
          width: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;

        }

        select {
          min-width: 100%;
          border-radius: 10px;
          box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
          -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px rgba(210, 218, 230, 0.6);
          background-image: none;
          background-color: rgba(255, 255, 255, 0.35);
          min-height: 40px;
          font-size: 17px;
          color: rgba(67, 91, 117, 0.6);
          padding-right: 28px;
        }
      }
    }
  }

  .billingInfo {
    padding-bottom: 20px;

    .sectionTitle {
      margin-bottom: 19px;
    }

    .cardHolder {
      max-width: 245px;
    }

    .checkBoxWrap {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 14px;
        color: #435b75;
        font-weight: 400;
        padding: 0 40px 0 5px;

        a {
          color: #1d6ec9;
        }
      }

      .btn {
        font-size: 14px;
        padding: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15px;
          height: 15px;
          margin-right: 7px;
        }
      }
    }

    .checkBoxContent {
      display: flex;
      align-items: flex-start;
    }

    .customCheckbox {
      >span {
        margin-top: -5px;
      }
    }
  }

  .cardDetails {
    .cardWrapper {
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.35);
      border-radius: 10px;
      border: 1px solid #dae2ee;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #ffffff;
      width: 100%;
      padding: 8.5px 30px 8.5px 10px;
      box-sizing: border-box;

      .formControl {
        background-color: transparent;
        border-radius: 0;
        border: none;
        box-shadow: none;
        width: 100%;
        padding: 0 10px 0 0;
        text-overflow: ellipsis;

        &.mm-yy {
          max-width: 74px;
          padding-right: 16px;
        }

        &.cvv {
          max-width: 35px;
          padding-right: 0;
        }
      }
    }

    .cardImage {
      min-width: 35px;
    }
  }

  .add-wallet-balance {
    .add-wallet-balance-row {
      display: none;

      .first-col,
      .second-col {
        max-width: 30%;
        flex: 0 0 30%;
      }
    }

    .AddwalletWrap {
      max-width: 55%;
      flex: 0 0 55%;
      justify-content: flex-end;
    }

    .sectionTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      h2 {
        margin-bottom: 0;
      }

      .checkbox-label small {
        font-size: 14px;
        color: #435b75;
        font-weight: 400;
        padding-left: 35px;
        margin-top: 2px;
        display: block;
        cursor: pointer;
      }
    }

    .leftContent {
      max-width: 69.3%;
      flex: 0 0 69.3%;
    }

    .rightContent,
    .popupStyle,
    .popup-content {
      .infoBox {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 5px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .checkOutPage {
      .col {
        padding: inherit;
        flex-basis: unset;
        flex-grow: unset;
        max-width: 100%;
        padding-right: 15px;
      }

      .card2 {
        background-color: rgba(255, 255, 255, 0.8);
      }

      .primaryInfo,
      .travelerBox {

        .halfCol,
        .col3 {
          margin: 0;

          .col {
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .billingDetails {
        .cardDetails {
          padding-left: 0;
        }
      }
    }
  }

}

/*Checkout page end*/


/*Member checkout page end*/



.tabsStyle {
  .active {
    a {
      color: #1d6ec9;
      border-color: #1d6ec9;
    }
  }
}

.sectionSpace2 {
  .colCard {
    .col {
      padding: 0 9px 15px;
    }

    .cardStyle .cardText {
      padding: 11px 8px 10px;
    }
  }
}

.faqCnt {
  .accordWrap {
    .accordHead {
      font-weight: 500;
    }
  }
}

.home-faq {
  .accordWrap {
    .accordHead {
      justify-content: flex-start;
      align-items: flex-start;

      img {
        max-width: 26px;
        margin-right: 12px;
      }
    }
  }

  .toggle-more-accordions {
    // display: none;
  }

  .openfaqHeader {
    // border-bottom: 1px solid rgba(67, 91, 117, 0.2)
  }

  .cardBodyOPen {
    .toggle-more-accordions {
      // display: block;
    }
  }
}

.landingPageWrap {
  @include background("../img/widget-bg.jpg");
  background-repeat: repeat-y;

  .menuBtn {
    margin-left: 20px;

    .navbar-toggler-icon {
      display: block;
      background-color: transparent;
      border: 1px solid $white;

      span {
        width: 16px;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;

        &:after,
        &:before {
          transition: width 2s;
          background-color: $white !important;
          width: 16px;
        }

        &:before {
          top: -5px;
        }

        &:after {
          top: 5px;
        }
      }
    }
  }

  #apartmentsTab {
    .endDate {
      padding-left: 15px;
    }

    .startEndDate {
      max-width: 300px;
      padding: 11px 12px;
    }
  }
}

.mobileSearchFilterButtons {
  display: none;
}



.pageForgotPassword {
  min-height: 100%;
}



.loginPageWrap,
.forgotPasswordWrap {
  background: none;
  position: relative;

  .pageLoginWrap,
  .pageForgotPassword {
    background-image: url(../img/Login-Background-screen.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.forgotPasswordWrap {

  .forgot-password-confirmation-message,
  .reset-password-confirmation-message {
    display: none;
  }

  p {
    strong {
      font-weight: 600;
    }
  }
}

.indexPageWrap {
  padding-bottom: 0;
}

.triptek-difference-wrapper {
  padding: 0 0 50px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;

  p {
    margin-bottom: 21px;
    font-weight: 300;
    font-size: 19px;

    strong {
      font-weight: 500;
    }
  }

  .sctionTitle {
    color: #fff;
  }
}

.tripello-way-wrapper {
  .cardBox {
    max-width: 800px;

    p {
      line-height: 1.29;

      strong {
        font-weight: 500;
      }
    }
  }
}

.home-banner {
  position: relative;

  .scrollBtm {
    position: absolute;
    background: transparent;
    font-size: 44px;
    line-height: 0;
    bottom: 30px;
    padding: 5px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    img {
      max-width: 29px;
    }

    a {
      color: #ffffff;
    }

    &:hover {
      background: transparent;
    }
  }

  .mobile-video {
    display: none;
  }
}

.tripello-magic-wrapper {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0px;

  .cardStyle {
    max-width: 1110px;
    padding: 0;
    background: none;
    box-shadow: none;
    border: none;
  }

  .videoBox {
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    border: none;
    background: none;

    .videoInBox {
      border-radius: 10px;
      overflow: hidden;
      min-height: 520px;
      padding-bottom: 56.25%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('.././img/network/video-section-bg.png');

      &::after {
        display: none;
      }
    }

    .VideoInBoxBGforMemberUpgrade {
      background-image: url('../img/membership-upgrade//member-upgrade-videoBox.png');
    }

    .mobile-video {
      display: none;
    }

    video {
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }

    .video-play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;

      img {
        height: inherit;
        width: inherit;
        object-fit: contain;
      }
    }
  }

  p,
  .sctionTitle {
    color: #435b75;
  }

  p {
    font-size: 17px;
  }

  .tripello-magic-content {
    margin-top: 20px;
    max-width: 1110px;
    margin: 20px auto 0;

    strong {
      font-weight: 500;
    }
  }

  h6 {
    @include ubuntu(300);
    font-size: 19px;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #435b75;
    margin-bottom: 0;
    margin-left: 5px;

    strong {
      font-weight: 500;
    }

    a {
      font-weight: normal;
      color: #435b75;
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .featured-on-icons {
    padding: 30px 0;
  }
}

.membersRewardsPageWrap {
  padding: 0;

  .container {
    max-width: 1110px;
  }

  .tab-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 27px;
    padding-top: 20px;

    ul {
      margin: 0 -24px;
      padding: 0;
      list-style: none;
      display: flex;

      li {
        padding: 0 24px;

        a {
          color: $white;
          font-size: 21px;
          font-weight: normal;
          position: relative;
          transition: 0.3s;

          &:hover {
            &:after {
              content: "";
              display: block;
              background-color: $white;
              height: 1px;
              width: 100%;
              position: absolute;
              bottom: -3px;
            }
          }
        }

        .active {
          &:after {
            content: "";
            display: block;
            background-color: $white;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -3px;
          }
        }
      }
    }

    .details-btn {
      font-size: 14px;
      font-weight: normal;
      min-width: auto;
      background: none;
      box-shadow: none;
      padding: 0;
      display: flex;
      align-items: center;
      margin-right: 24px;

      img {
        margin-right: 8px;
      }

      &::before {
        display: none;
      }
    }

    .mobile-details-btn {
      display: none;
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgba(67, 91, 117, 0.2);
  }

  .border-left {
    border-left: 1px solid rgba(67, 91, 117, 0.2);
  }

  .section-title {
    padding: 21px 0;

    h2 {
      font-size: 21px;
      font-weight: normal;
      color: $white;
    }
  }

  .commission-content-wrapper {
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.8);

    .content {
      padding: 20px 16px;

      h2 {
        font-size: 45px;
        font-weight: 300;
        color: #1d6ec9;
      }

      h6 {
        font-size: 14px;
        font-weight: normal;
        color: rgba(67, 91, 117, 0.6);
        margin-bottom: 4px;
      }

      h5 {
        font-size: 25px;
        font-weight: normal;
        color: #435b75;
      }
    }

    .col {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }

}

.membersRewardsPageWrap {
  padding: 0;

  .container {
    max-width: 1110px;
  }

  .tab-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 27px;
    padding-top: 20px;

    ul {
      margin: 0 -24px;
      padding: 0;
      list-style: none;
      display: flex;

      li {
        padding: 0 24px;

        a {
          color: $white;
          font-size: 21px;
          font-weight: normal;
          position: relative;
          transition: 0.3s;

          &:hover {
            &:after {
              content: "";
              display: block;
              background-color: $white;
              height: 1px;
              width: 100%;
              position: absolute;
              bottom: -3px;
            }
          }
        }

        .active {
          &:after {
            content: "";
            display: block;
            background-color: $white;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -3px;
          }
        }
      }
    }

    .details-btn {
      font-size: 14px;
      font-weight: normal;
      min-width: auto;
      background: none;
      box-shadow: none;
      padding: 0;
      display: flex;
      align-items: center;
      margin-right: 24px;

      img {
        margin-right: 8px;
      }

      &::before {
        display: none;
      }
    }

    .mobile-details-btn {
      display: none;
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgba(67, 91, 117, 0.2);
  }

  .border-left {
    border-left: 1px solid rgba(67, 91, 117, 0.2);
  }

  .section-title {
    padding: 21px 0;

    h2 {
      font-size: 21px;
      font-weight: normal;
      color: $white;
    }
  }

  .commission-content-wrapper {
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.8);

    .content {
      padding: 20px 16px;

      h2 {
        font-size: 45px;
        font-weight: 300;
        color: #1d6ec9;
      }

      h6 {
        font-size: 14px;
        font-weight: normal;
        color: rgba(67, 91, 117, 0.6);
        margin-bottom: 4px;
      }

      h5 {
        font-size: 25px;
        font-weight: normal;
        color: #435b75;
      }
    }

    .col {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }

  .commission-content-tabs {
    padding-bottom: 30px;

    // overflow-x: auto;
    // overflow-y: hidden;
    .active-tab {
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 23px solid transparent;
        border-right: 23px solid transparent;
        border-bottom: 23px solid rgba(255, 255, 255, 0.8);
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .row {
      margin: 0 -7.5px 10px;
      flex-wrap: initial;

      .col {
        padding: 0 7.5px;
        max-width: 20%;
        flex: 0 0 20%;
        // min-width: 220px;
      }
    }

    .content {
      padding: 10px 13px 10px 10px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 0.8);
      position: relative;

      .anchor-tab {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      h5 {
        text-align: right;
        font-size: 19px;
        font-weight: normal;
        color: #00b7a6;
      }
    }

    .image {
      width: 45px;
      height: 45px;
      flex: 0 0 45px;
      max-width: 45px;
      border-radius: 25px;
      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
        inset -2px -2px 6px 0 #fff;
      border: solid 1px #dae2ee;
      background-color: rgba(255, 255, 255, 0.35);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .heading {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      h6 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.21;
        color: #435b75;
        margin-left: 10px;
        min-height: 34px;
      }
    }
  }



}


// Membership Upgrade PageWrap end
.EZView {
  background-color: rgba(0, 0, 0, 0.6);

  iframe {
    width: 1300px !important;
    max-width: 1300px !important;
  }
}


.affiliate-signup-landing-page {
  .network-banner {
    h3 {
      @include ubuntu(400);
      font-size: 25px;
      line-height: 1.4;
      letter-spacing: normal;
      color: #fff;
      margin-bottom: 10px;
    }
  }
}


// premium Plus SignUp PageWrap end
.flight-faq-dropdown-section {
  padding: 10px 20px;
  margin-bottom: 80px;

  .row {
    padding: 8px 0;
    border-bottom: solid rgba(67, 91, 117, 0.2);
    align-items: center;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }

  .dropdown {
    padding: 10px 0 15px;
    max-width: 45%;
    flex: 0 0 45%;
  }

  .section-title {
    max-width: 24%;
    flex: 0 0 24%;
  }

  h3 {
    font-size: 17px;
    font-weight: 500;
    color: #435b75;
    line-height: 1.3;
  }

  .selectWrap {
    position: relative;
    margin-right: 0;

    &:after {
      content: "";
      background-image: url("../img/dropdown-arrow.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: contain;
      height: 14px;
      width: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;

    }



    select {
      min-width: 100%;
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%),
        2px 2px 3px 0 rgb(0 0 0 / 15%);
      -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%),
        2px 2px 3px 0 rgb(0 0 0 / 15%);
      -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px rgba(210, 218, 230, 0.6);
      background-image: none;
      background-color: rgba(255, 255, 255, 0.35);
      min-height: 40px;
      font-size: 17px;
      color: rgba(67, 91, 117, 0.6);
      padding-right: 28px;
    }
  }

  .button-wrap {
    text-align: center;

    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &::before {
        display: none;
      }
    }
  }
}


.premium-plus-member-rewards-pageWrap {
  padding-bottom: 10px;

  .rewardsSection {
    padding-bottom: 25px;
  }

  .sectionTitle {
    padding: 27px 0 28px;
  }

  .searchWrapper {
    padding: 25px 0 26px;
  }

  .sharingWrapper {
    .linkIcon {
      margin-bottom: 6px;
    }
  }

  .walletWrapper {
    .leftContent {
      .dropdownWrapper {
        .dropdownBox {
          padding-right: 0;
        }
      }
    }
  }
}

// premium plus member rewards pageWrap end
.widget-page-wrap {
  .titleStyle {
    line-height: normal;
  }

  .inputStyle {
    input {
      min-height: 43px;
      @include ubuntu(400);
    }
  }

  .searchBtnWrap {
    .btn {
      min-height: 45px;
      max-width: 160px;
      line-height: 1.1;
    }
  }

  #condosTab {
    .startEndDate {
      max-width: 300px;
      width: 100%;

      input {
        width: 80px;
      }
    }
  }
}

// widget page wrap end
.listingFlight.listingPage {
  .paginationWrapper {
    padding-top: 50px;
  }

  .filterCnt {
    .inputStyle {
      .icon-location-on {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .cabin-class-wrapper {
    .popupCloseBtn {
      display: none;
    }
  }
}

.carcheckOutPage {


  .labelStyle {
    margin-bottom: 6px;
    color: rgba(67, 91, 117, 0.6);
  }
}

.gift-card-row {
  display: none;

  .formGroup {
    max-width: 360px;
  }
}

.read-more-btn-wrap {
  text-align: right;
  padding-top: 8px;

  button {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: normal;
    letter-spacing: normal;
    color: #435b75;
    cursor: pointer;
  }

  .read-less-btn {
    display: none;
  }
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(67, 91, 117, 0.6);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;

  &.open-popup {
    display: flex;
  }

  .popup-content {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #cfd1e1;
    background-color: #eff0f5;
    padding: 15px 21px;
  }

  .popup-title {
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 0;
      color: #435b75;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }

    .close {
      margin-top: -4px;
      padding: 4px;
      font-weight: 400;
      font-size: 18px;
      position: absolute;
      top: 10px;
      right: 8px;
      line-height: 1;
      min-width: auto;
      box-shadow: none;
      background: none;
      border-radius: 0;

      &:before {
        display: none;
      }
    }
  }

  .box-content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #1d6ec9;
      margin-bottom: 1px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 20px;
    }

    h3 {
      font-size: 17px;
      font-weight: 500;
      color: #435b75;
      margin-bottom: 20px;
    }
  }
}

.home-video {
  .popup-title {
    .close {
      top: -18px;
      right: -22px;
      padding: 0;
    }
  }
}

// CSS add for wallet table
.amount-info {

  text-align: right;

  .btn {
    background: none;
    box-shadow: none;
    min-width: auto;
    min-height: auto;
    color: inherit;
    padding: 0;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font: inherit;

    &:before {
      display: none;
    }

    img {
      max-width: 16px;
      margin-left: 18px;
    }
  }


  .btn-amount {
    background: none;
    box-shadow: none;
    min-width: auto;
    min-height: auto;
    color: inherit;
    padding: 0;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font: inherit;
    text-align: right;

    img {
      max-width: 16px;
      margin-left: 18px;
    }
  }
}

// css add for wallet modal
.wallet-info-popup {
  &.openpopup {
    display: flex;
  }

  .popup-content {
    background-color: #d2ebff;
    padding-bottom: 50px;
  }

  .box-content {
    .row {
      &:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.5);
      }

      margin:5px 0;
      min-height: 40px;
      align-items: center;
      border-radius: 10px;
    }

    .right-content {
      text-align: right;
    }

    h5 {
      font-size: 14px;
      font-weight: normal;
      color: #435b75;
      font-family: 'ubuntu';
    }

    .green-text {
      color: #00b7a6;
    }

    .total-amount {
      text-align: right;
      margin-top: 20px;

      h5 {
        border: 1px solid #00b7a6;
        border-radius: 6px;
        max-width: 220px;
        margin: 0 0 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 6px 12px;
      }

      strong {
        font-weight: 500;
      }
    }
  }
}






// wallet info popup end
.affiliate-invites-wrapper {
  .container {
    margin-left: 0;
  }

  .premium-members-invites {
    // padding-top: 30px;
    // padding-bottom: 17px;
  }
}

.marketing-page-wrapper {
  padding-bottom: 20px;

  .premium-members-invites {
    // padding-top: 30px;
    // padding-bottom: 17px;
  }

  .overview-content-wrapper {
    .overview-content {
      li {
        padding: 9px 10px;
      }
    }
  }


}


// css for wallet filter by date and datepicker

.dateWrapper {
  // padding-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h6 {
    font-size: 14px;
    font-weight: normal;
    color: rgba(67, 91, 117, 0.6);
    margin-bottom: 0;
    margin-right: 25px;
  }

  .dateBox {
    position: relative;
    border-radius: 10px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    min-height: 40px;
    background-color: rgba(255, 255, 255, 0.35);
    padding: 0 5px;
    width: 280px;
  }

  .toDate,
  y .fromDate {
    padding: 0 10px;
    position: relative;

    &:before {
      content: "";
      @include background("../img/date.png");
      background-size: contain;
      width: 14px;
      height: 16px;
      display: block;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }

    p {
      padding-left: 25px;
      color: #1d6ec9;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .toDate {
    position: relative;

    &:after {
      content: "";
      @include background("../img/right-arrow.png");
      width: 15px;
      height: 10px;
      display: block;
      position: absolute;
      top: 50%;
      left: 115%;
      transform: translateY(-50%) translateX(-115%);
    }
  }

  .fromDate {
    margin-left: 20px;
  }

  img {
    height: auto;
    margin-left: 0;
  }
}


.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}


@media (max-width: 767px) {
  .GlobalPaymentIntegrationWrap .walletOptionWrap ul li {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .GlobalPaymentIntegrationWrap .walletOptionWrap ul li {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.GlobalPaymentIntegrationWrap .walletOptionWrap ul li .customCheckbox>span:before {
  top: 4px;
}

.GlobalPaymentIntegrationWrap .walletOptionWrap ul li .customCheckbox input:checked+span:after {
  top: 9px;
}


.ReactModal__Content--after-open {
  z-index: 9999;
}

.qrCodeWrapper {
  max-width: 210px;
  max-height: 210px;
  width: 100%;
  margin: 36px auto 15px;
  padding: 9px;
  border-radius: 10px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    inset -2px -2px 6px 0 #ffffff;
  -webkit-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
    inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #dae2ee;
  background-color: rgba(255, 255, 255, 0.35);
}


.booking-status {
  color: var(--blackShade1);
  font-size: 12px;
  line-height: normal;
  display: flex;
  margin-top: 106px;
  margin-left: -69px;
}


.fileDownload {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  cursor: pointer;

  .mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }

  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1d6ec9;
    margin-left: 4px;
  }
}

.flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.justify-space-between {
  display: flex;
  justify-content: space-between !important;
}

// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

.departureDateAdjustment {
  color: red;
}


.not-found-booking {
  display: flex;
  vertical-align: middle;
  align-items: center;
  min-height: 390px;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-color: rgba(243, 244, 248, 0.8);

  h2,
  p {
    color: #435b75;
  }
}

p {
  margin-bottom: 0;
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "styleNew.scss";
@import "popup.scss";
@import "common.scss";
@import "../scss/components/widgets/widgets.scss";
@import "./components/calender/calender.scss";
@import "../scss/components/select-field/select-filed.scss";
@import "style-latest.scss";
@import "../scss/components/home/index.scss";
@import "../scss/components/header/navigation.scss";
@import "../scss/components/map-view/map.scss";
@import "../scss/components/pagination-style/pagination.scss";
@import "../scss/components/login-pages/login.scss";
@import "../scss/components/listing-pages/hotel-detail.scss";
@import "../scss/components/listing-pages/hotel-search-result.scss";
@import "../scss/components/listing-pages/car-listing.scss";
@import "../scss/components/checkout-pages/checkout-page.scss";
@import "../scss/components/checkout-pages/hotel-checkout.scss";
@import "../scss/components/checkout-pages/membership-pages.scss";
@import "../scss/components/ErrorModal/ErrorModal.scss";
@import "../scss/components/slick-slider/slick-slider.scss";
@import "../scss/membership-pages/membership-pages.scss";
@import "../scss/components/checkout-pages/cars-checkout.scss";
@import "../scss/components/dashboard/affiliate-dashboard.scss";
@import "../scss/components/dashboard/affiliate-invites.scss";
@import "../scss/components/dashboard/member-invites.scss";
@import "../scss/components/dashboard/wallet.scss";
@import "../scss/components/dashboard/membership-rewards.scss";
@import "../scss/components/checkout-pages/flight-checkout.scss";
@import "../scss/profile-page.scss";
@import "../scss/components/confirmation/confirmation-pages.scss";
@import "../scss/components/faq-section/faq.scss";
@import "../scss/components/TermsAndConditions/Terms.scss";
@import "../scss/components/Academy-pages/academy.scss";

@import "react-input-range/lib/css/index.css";
@import "responsive.scss";
@import "tablet-responsive.scss";
@import "mobile-responsive.scss";
@import "react-image-lightbox/style.css";
@import "./thankyoupage.scss";
@import "./components/affiliate-pages/affiliate-home.scss";
@import "./components/affiliate-pages/affiliate-checkout.scss";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "../scss/components/flights-tab.scss";
@import "../scss/components/listing-pages/flight-listing.scss";
@import "../scss/components/booking/booking.scss";