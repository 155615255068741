  // Car-checkout- inputfeild style

  .siteBg.carcheckOutPage,
  .siteBg.confirmationPage {
      .mainContent {
          padding-bottom: 0px;
          padding-top: 0px;

      }

      .mainWrap {
          padding-right: 0px;

          .card2 {
              padding: 17px 20px;
          }
      }

      .inputStyle2 {
          input {
              padding: 8px 10px 8px;
              width: 100%;
              font-family: "Ubuntu", sans-serif;
              box-sizing: border-box;
              color: #435b75;
              background: rgba(255, 255, 255, 0.35);
              min-width: auto;
              text-overflow: ellipsis;
              min-height: 40px;
          }
      }

      .primaryInfo,
      .flight-info-card {
          .copyStyle {
              margin-bottom: 24px;

              button {
                  border: none;
                  background-color: transparent;
                  color: var(--text-color3);
              }
          }

          .selectWrap {
              margin: 0 auto;
              width: calc(100% - 4px);

              [class*="control"] {
                  min-width: 100%;
                  border-radius: 10px;
                  -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                  -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                  border: solid 1px rgba(210, 218, 230, 0.6);
                  background-image: none;
                  background-color: rgba(255, 255, 255, 0.35);
                  min-height: 38px;
                  z-index: 1;
                  font-size: 17px;
                  color: rgba(67, 91, 117, 0.6);
                  padding-right: 28px;
                  cursor: pointer;

                  [class*="indicatorSeparator"],
                  [class*="indicatorContainer"] {
                      display: none;
                  }

                  [class*="ValueContainer"] {
                      padding: 0px 8px;
                      margin-top: -4px;
                      color: #7d91a9;
                  }



                  [class*="placeholder"] {
                      color: #7d91a9;
                  }

                  [class*="singleValue"] {
                      color: #435b75;
                  }
              }
          }

          .col-6 {
              .dropdown-bottom {
                  margin-bottom: 10px;
                  font-size: 14px;
                  font-weight: normal;
                  color: rgba(67, 91, 117, 0.6);
              }
          }

      }

      .impInfoBox {
          .show-modal {
              font-size: 14px;
              border: none;
              background-color: transparent;
              color: var(--text-color3);

          }
      }

      .lightBgLabel {
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.5);
          display: block;
          padding: 5px 21px 12px 21px;



      }

      .card2 {
          .customCheckbox {
              >span:before {
                  height: 22px;
                  width: 22px;
              }

              input:checked+span:after {
                  left: 6px;
                  top: 6px;
              }
          }
      }

  }

  .carcheckOutPage {

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
          -webkit-text-fill-color: #435b75;
          -webkit-box-shadow: 0 0 0px 0px inherit inset;
          transition: background-color 0s ease-in-out 0s;

          font-size: 17px !important;
      }

      .mainContent {

          .card2 {
              .col {
                  padding: 0px 15px 0px 0px;
                  flex-basis: 0;
                  flex-grow: 1;
                  max-width: 100%;
              }

              .dropdown {
                  width: 50%;
                  padding-left: 10px;
                  padding-right: 10px;

                  h5 {
                      margin-bottom: 6px;
                      font-size: 14px;
                      font-weight: 400;
                      color: rgba(67, 91, 117, 0.6)
                  }


              }


          }
      }

      .impInfoBox {
          p {
              strong {
                  display: block;
              }
          }
      }

      .selectWrap {
          .css-26l3qy-menu {
              z-index: 2 !important;
          }

      }
  }

  .carCheckout {
      .cardDetailsBox {
          .cardDetails {
              padding: 0;
              //   max-width: 475px;
              width: 100%;
          }

          .selectStyle {
              width: 33.33%;

              select {
                  min-height: 40px;
              }
          }
      }

      .inputStyle2 {
          input {
              padding: 8px 10px 8px;
          }
      }
  }

  .carCheckoutSidebar {
      .sideBarInSpace {
          .mobileHide {
              [class*="icon"] {
                  margin-right: 6px;

                  &:before {
                      color: #435b75;
                  }
              }

              .lightText {
                  display: flex;
              }

              .mb-5 {
                  margin-bottom: 7px;
              }
          }
      }
  }

  .carCheckout {
      @media (max-width: 991px) {
          .container.dflex {
              flex-direction: column;
              padding-top: 55px;
          }

          .mainWrap {
              width: auto;

              .impInfoBox.mobileHide {
                  display: block;

              }

              .card2 {
                  .col {
                      padding: 0;
                  }
              }

              .flight-info-card {
                  .cardDetailsBox {
                      margin: 0 -7.5px;

                      .col {
                          padding: 0 7.5px;
                      }
                  }
              }
          }

          .sideBar {
              display: block;
              margin-left: 0;
              max-width: none;
              width: 100%;
              min-width: auto;

              .mobileHide {
                  display: block;
              }
          }

          .primaryInfo {

              .halfcol,
              .mdTitleNname {
                  flex-wrap: wrap;

                  .col,
                  .firstNameCol {
                      width: 64%;
                      padding-left: 3px;
                  }
              }
          }

          .cardDetailsBox {
              .col {
                  width: 100%;
                  max-width: 100%;
              }
          }


          .container.dflex {
              flex-direction: column-reverse;
          }

          .cardDetailsBox {
              flex-direction: row;

              .cardDetails {
                  padding: 0;
                  max-width: 100%;
                  width: 100%;
              }
          }

      }

      @media (max-width:768px) {

          .container.dflex {
              flex-direction: column;
          }



          .mainWrap {
              width: auto;

              .impInfoBox.mobileHide {
                  display: none;

              }

              .cardDetailsBox {
                  flex-direction: row;
              }

          }

          .sideBar {
              .mobileHide {
                  display: none;
              }
          }

          .mainContent {
              .card2 {
                  .col.cardDetails {
                      padding: 0px 0px 0px 0px;
                  }
              }
          }

          .cardDetailsBox {
              .cardDetails {
                  padding: 0 5px;
                  max-width: auto;
                  width: auto;
                  min-width: 44.71%;
              }
          }
      }

      @media(max-width:374px) {
          .collision-card {
              .sheildTitle {
                  font-size: 15px;
              }
          }
      }
  }


  @media(max-width:768px) {
      .siteBg.carcheckOutPage {

          .mainWrap {
              .card2 {
                  padding: 7px 10px;

                  .boxTitle {
                      margin-bottom: 9px;
                  }
              }
          }

          .primaryInfo {
              .copyStyle {
                  margin-bottom: 14px;
              }

              .profileTitle {
                  //   max-width: 120px;
              }

              select {
                  min-height: 40px;
              }

              .halfCol {
                  margin: 0 -5px;

                  .col {
                      padding: 0 5px;
                      margin-bottom: 12px;
                  }

                  .lastNameCol.col {
                      padding: 0;
                      padding: 0 5px;
                  }
              }

              .col3 .col {
                  margin-bottom: 12px;
              }

              .lightBgLabel {
                  padding: 9px 10px 11px 10px;
              }

              .labelSpace {
                  padding-left: 10px;
                  padding-top: 15px;
              }

              .customCheckbox>span {
                  padding-left: 33px;
                  margin-top: 0;
              }

              .customCheckbox>span:before,
              .customRadio>span:before {
                  height: 22px;
                  width: 22px;
              }

              .gutter15 {
                  .col-6 {
                      margin-bottom: 13px;
                  }
              }
          }

          .mainWrap {
              .freeCancellationBox {
                  .btn {
                      min-width: auto;
                  }
              }

              .collision-card {
                  padding-bottom: 12px;

                  .boxTitle {
                      margin-bottom: 12px;
                  }

                  .listStyleBg {
                      @include background("../img/car-checkout-collison-card.png");
                      padding: 24px 20px 14px;
                  }

                  .copyMB {
                      color: rgba(67, 91, 117, 0.6);

                      a {
                          display: block;
                          color: #1d6ec9;
                      }
                  }

                  .borderTopDivider {
                      color: rgba(67, 91, 117, 0.6);
                      font-size: 14px;
                      margin-bottom: 6px;

                      .icon-info:before {
                          color: rgba(67, 91, 117, 0.6);
                      }
                  }
              }

              .flight-info-card {
                  padding: 9px 10px;
                  margin-bottom: 10px;

                  .cardDetailsBox {
                      margin: 0;
                  }

                  .col {
                      padding: 0;
                  }

                  .selectStyle {
                      min-width: auto;
                      max-width: 140px;
                      flex: 0 0 140px;
                      margin-right: 10px;
                  }

                  .cardDetailsBox {
                      margin-left: 0;
                      justify-content: flex-start;

                      .cardDetails {
                          width: 100%;
                          padding: 0px;
                      }
                  }

                  .boxTitle {
                      margin-bottom: 19px;
                  }
              }
          }

          .mobileCarBtmBox {
              .boxLightText {
                  padding-bottom: 13px;
              }
          }

          .carCheckoutSidebar {
              padding-bottom: 10px;
          }
      }
  }


  @media (min-width: 992px) {

      .siteBg.carcheckOutPage,
      .siteBg.confirmationPage {
          .mainContent {
              .pageInternalScroll {
                  padding-top: 55px;
                  height: calc(100vh - 65px) !important;
                  overflow-y: auto;
                  padding-bottom: 0;
                  overflow-x: hidden;
              }
          }
      }
  }

  @media(max-width:991px) {
      .carcheckOutPage {
          .mainContent {
              .card2 {
                  .dropdown {
                      width: 100%;
                      padding: 0px 10px;
                      margin: 0px 0px 14px 0px;
                  }
              }
          }
      }

      .confirmationPage .carConfirmation {
          .pageInternalScrollTopPadding {
              padding-top: 55px;
          }
      }
  }